const translation_map = new Map([
  ['view', 'Sicht'],
  ['views', 'Sichten'],
  ['module', 'Modul'],
  ['modules', 'Module'],
  ['component', 'Komponente'],
  ['components', 'Komponenten'],
  ['input_type', 'Inputtyp'],
  ['output_type', 'Outputtyp'],
  ['internal_type', 'Internaltyp'],
  ['input_types', 'Inputtypen'],
  ['output_types', 'Outputtypen'],
  ['internal_types', 'Internaltypen'],
  ['attribute_type', 'Attributtyp'],
  ['user', 'Nutzer'],
  ['users', 'Nutzer'],
  ['attribute', 'Attribut'],
  ['dynamic-quality', 'Qualitätsmetriken'],
  ['dynamic-payload', 'Nutzdaten'],
  ['description', 'Beschreibung'],
  ['categorization', 'Kategorisierung'],
  ['metric', 'Bewertungsmetrik'],
  ['degradation_rule', 'Degradations-Regel'],
  ['degradation_rules', 'Degradations-Regeln'],
  ['trigger_conditions', 'Auslösebedingungen'],
  ['connection', 'Verbindung'],
  ['create', 'Neu'],
  ['rename', 'Umbenennen'],
  ['delete', 'Löschen'],
  ['internal_connection', 'Interne Verbindung']
])

export default function translate(word) {
  if (word) {
    return translation_map.get(word) || word[0].toUpperCase() + word.slice(1)
  } else {
    return ''
  }
}