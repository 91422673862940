import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import Rev_ConnectionTableWithSubRev_DataTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTableWithSubRev_DataTable.js'
import { fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api.js'

class Rev_Output_Card extends Component {
  static contextType = DeviceContext

  state = {
    outputs: [],
    attributes: [],
    rev_attributes: [],
    outputs_attribute: []
  }

  getItems() {
    fetchGetData(this.context.device, 'outputs')
    .then(outputs => {
      if (outputs.dataExists != 'false') {
        this.setState({ outputs })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, "attributes")
    .then(attributes => {
      if (attributes.dataExists != 'false') {
        this.setState({ attributes })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'attributes_revisions')
    .then(rev_attributes => {
      if (rev_attributes.dataExists != 'false') {
        this.setState({ rev_attributes })
      }
    })
    .catch(err => console.log(err))

    
    fetchGetData(this.context.device, 'outputs_attribute')
    .then(outputs_attribute => {
      if (outputs_attribute.dataExists != 'false') {
        this.setState({ outputs_attribute })
      }
    })
    .catch(err => console.log(err))

  }

  componentDidMount() {
    this.setState({ component_id: this.props.component_id })
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">output Revisions</CardTitle>
        </CardHeader>
        <CardBody>
          <Rev_ConnectionTableWithSubRev_DataTable
            item_type={'component'}
            child_items={this.state.outputs}
            child_type={'output'}
            parent_child_items={this.state.outputs.filter(item => item.component_id == this.props.component_id)}
            columns={['Name']}
            columns_DB={['name']}
            refresh={this.refresh}
            sub_items={this.state.attributes}
            sub_rev_items={this.state.rev_attributes}
            sub_parent_child_items={this.state.outputs_attribute}
            sub_item_type='attribute'
            sub_columns={['Attribute Type ID', 'Content']}
            sub_columns_DB={['attribute_type_name', 'content']}
            revision_columns={['content']}
          />
        </CardBody>
      </Card>
    )
  }
}

export default Rev_Output_Card