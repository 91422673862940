import React from "react"

import Header from "./Header"
import Navigation_Doc from "./Navigation_Doc"
import Devices_Doc from "./Devices_Doc"
import User_Dashboard_Doc from "./User_Dashboard_Doc"
import Architecture_Management_Doc from "./Architecture_Management_Doc"
import View_Doc from "./View_Doc"
import Component_Doc from "./Component_Doc"
import Module_Doc from "./Module_Doc"
import Degradation_Rule_Doc from "./Degradation_Rule_Doc"

class Documentation extends React.Component {

  componentDidMount() {
    let scroll_to = document.getElementById(window.location.hash.substring(1))
    if (scroll_to) scroll_to.scrollIntoView()
  }

  render() {
    return (
      <>
        <div className='content'>
          <Header />
          <Navigation_Doc />
          <Devices_Doc />
          <User_Dashboard_Doc />
          <Architecture_Management_Doc />
          <View_Doc />
          <Component_Doc />
          <Module_Doc />
          <Degradation_Rule_Doc />
        </div>
      </>
    )
  }
}

export default Documentation