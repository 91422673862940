import React, { Component } from 'react'
import { Table, Input } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPen, faCheck, faX, faPlus } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext'
import { fetchRemove, fetchRemoveFrom, fetchEdit, fetchAddNew, fetchAddTo } from 'helpers/api.js'
import translate from 'helpers/translations'

class DataTableAttributes extends Component {
  static contextType = DeviceContext
  
  constructor(props) {
    super(props)
    this.state = {
      items: {'static-single': [], 'static-table': [], 'dynamic-quality': [], 'dynamic-payload': [], 'parameter': []},
      initialized: false,
      edit: {}
    }
  }

  componentDidMount() {
    if (this.props.items.length > 0) {
      this.initiateState()
    }
  }

  componentDidUpdate(prev_props) {
    if (!this.state.initialized && prev_props.items !== this.props.items) {
      this.initiateState()
    }
  }

  initiateState() {
    // sort the attributes according to the attribute_type
    let items = this.state.items
    if (this.props.parent_type === 'component' && this.props.items) {
      items['parameter'] = this.props.items
    } else if (this.props.items) {
      this.props.items.forEach(item => {
        // ensure attribute_type and content are valid (backwards compatibilty with data from old tool)
        if (!items[item.attribute_type]) {
          item.attribute_type = 'static-single'
          if (!item.content || typeof item.content === 'string') {
            item.content = {'Name': item.attribute_type_name, 'Inhalt': item.content}
          } else if (Object.keys(item.content).length === 2 && item.content['Name'] && item.content['Value']) {
            item.content = {'Name': item.content['Name'], 'Inhalt': item.content['Value']}
          }
        }
        items[item.attribute_type].push(item)
      })
    }
    this.setState({ initialized: true, items })
  }

  // updates the state after an attribute is edited
  updateState = (type, row_index, edited_item) => {
    let items = this.state.items
    items[type][row_index].content = edited_item.content
    items[type][row_index].attribute_type_name = edited_item.content['Name']
    this.setState({ items, edit: {} })
    this.props.updateState(items[type][row_index])
  }

  // updates the state after an attribute is added
  addItemToState = (type, new_item) => {
    new_item['attribute_type'] = type
    let items = this.state.items
    items[type].unshift(new_item)
    this.setState({ items, edit: {} })
    this.props.addItemToState(new_item)
  }

  // deletes an attribute from the DB and the state
  deleteItem = (type, item_to_delete) => {
    let confirm_delete = window.confirm('Attribut "' + item_to_delete.content['Name'] + '" löschen?')
    if (confirm_delete) {
      try {
        if (type !== 'parameter') {
          fetchRemoveFrom(this.props.parent_type + 's_attribute', item_to_delete[this.props.parent_type + 's_attribute_id'])
        }
        fetchRemove('attributes', item_to_delete['attribute_id'])
        // update state
        let items = this.state.items
        items[type] = items[type].filter(item => item['attribute_id'] !== item_to_delete['attribute_id'])
        this.setState({ items })
        this.props.deleteItemFromState(item_to_delete['attribute_id'])
      } catch (err) {
        console.log(err)
      }
    }
  }

  // handles the change of the input fields
  onChange = (e) => {
    const { name, value } = e.target
    let new_content = this.state.edit.content
    new_content[name] = value
    let edit_item = this.state.edit
    edit_item.content = new_content
    if (edit_item.content['Name']) edit_item.error = false
    this.setState({ edit: edit_item })
  }

  // creates all needed entries for a new attribute in the DB
  submitNewRow = async (type) => {
    const attribute_name = this.state.edit.content['Name']?.trim()
    if (attribute_name) {
      try {
        let content = {}
        Object.entries(this.state.edit.content).forEach(([key, value]) => {
          content[key] = value.trim()
        })
        if (type === 'parameter') {
          // create new attribute
          const attribute = await fetchAddNew(this.context.device, 'attributes', {component_id: this.props.component_id, content: content})
          // update state
          this.addItemToState(type, attribute[0])
        } else {
          // create new attribute type
          const attribute_type = await fetchAddNew(this.context.device, 'attribute_types', {name: attribute_name, type: type})
          const attribute_type_id = attribute_type[0]['attribute_type_id']
          // add attribute type to io type
          let table_name = this.props.parent_type + '_types_attribute_type'
          await fetchAddTo(this.context.device, this.props.io_type_id, this.props.parent_type + '_type', attribute_type_id, 'attribute_type', table_name)
          // create new attribute
          const attribute = await fetchAddNew(this.context.device, 'attributes', {attribute_type_id: attribute_type_id, content: content})
          const attribute_id = attribute[0]['attribute_id']
          // add attribute to io
          table_name = this.props.parent_type + 's_attribute'
          const io_attribute = await fetchAddTo(this.context.device, this.props.io_id, this.props.parent_type, attribute_id, 'attribute', table_name)
          const io_attribute_id = io_attribute[0][this.props.parent_type + 's_attribute_id']
          // update state
          let new_item = {
            attribute_id: attribute_id,
            attribute_type_id: attribute_type_id,
            attribute_type_name: attribute_name,
            [this.props.parent_type + 's_attribute_id']: io_attribute_id,
            content: content
          }
          this.addItemToState(type, new_item)
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      // if no name is set show an error
      let edit_state = this.state.edit
      edit_state.error = true
      this.setState({ edit: edit_state })
    }
  }

  // edits attribute and attribute_type in the DB
  submitEditRow = (type, row_index) => {
    const attribute_type_name = this.state.edit.content['Name']?.trim()
    if (attribute_type_name) {
      try {
        if (type !== 'parameter' && attribute_type_name !== this.state.items[type][row_index]['attribute_type_name']) {
          const attribute_type_id = this.state.items[type][row_index]['attribute_type_id']
          fetchEdit('attribute_types', 'attribute_type_id', attribute_type_id, {name: attribute_type_name})
        }
        let content = {}
        Object.entries(this.state.edit.content).forEach(([key, value]) => {
          content[key] = value.trim()
        })
        fetchEdit('attributes', undefined, this.state.edit.id, {content: content})
        .then(item => {
          this.updateState(type, row_index, item[0])
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      // if no name is set show an error
      let edit_state = this.state.edit
      edit_state.error = true
      this.setState({ edit: edit_state })
    }
  }

  // renders the table for a specific attribute type
  renderTable(attribute_types) {
    const edit_state = this.state.edit
    const columns = {
      'static-single': ['Name', 'Inhalt'],
      'static-table': ['Name', 'Beschreibung', 'Einheit', 'Performance', 'Norm', 'MSG Definition'],
      'dynamic-quality': ['Name', 'Beschreibung', 'Wertebereich', 'Normalwert', 'Standard', 'MSG Definition'],
      'dynamic-payload': ['Name', 'Beschreibung', 'Wertebereich', 'Normalwert', 'Standard', 'MSG Definition'],
      'parameter': ['Name', 'Beschreibung', 'Wertebereich', 'Normalwert']
    }
    const input_style = edit_state.error ? {borderColor: 'red', boxShadow: '0 0 5px red'} : {} // error style of input field
    let rows = []
    attribute_types.forEach(type => {
      // header row for different dynamic attribute types
      if (type.split('-')[0] === 'dynamic') {
        rows.push(<tr key={type}><th colSpan={columns[type].length+1} style={{padding: '4px 0px'}}>{translate(type)}</th></tr>)
      }
      // case for adding a new row
      if (edit_state.new && type === edit_state.type) {
        let entry = columns[type].map((column, index) => {
          return (
            <td scope="row" key={'new' + column}>
              <Input
                type="textarea"
                name={column}
                autoFocus={index===0}
                onChange={this.onChange}
                value={edit_state.content[column] || ''}
                placeholder={column}
                style={{...{padding: '5px'}, ...index===0 ? input_style : {}}}
              />
            </td>
          )
        })
        entry.push(
          <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
            <button onClick={() => this.submitNewRow(type)} className='btn-minimal'><FontAwesomeIcon icon={faCheck} /></button>
            <button onClick={() => this.setState({ edit: {new: this.state.edit.new} })} className='btn-minimal'><FontAwesomeIcon icon={faX} /></button>
          </td>
        )
        rows.push(<tr key={'empty_row'}>{entry}</tr>)
      // button row to add a new row
      } else if (this.props.user_type_id !== 0 && type !== 'parameter') {
        rows.push(
          <tr key={type + 'new_row'}><td colSpan={columns[type].length+1} style={{padding: '4px 0px'}}>
            <button onClick={() => this.setState({ edit: {new: type.split('-')[0], type: type, content: {}} })} className='btn-minimal' style={{fontSize: '15px'}}>
              <FontAwesomeIcon icon={faPlus} style={{height: '15px'}} /> Neu
            </button>
          </td></tr>
        )
      }

      // actual content of the table
      this.state.items[type].forEach((item, row_index) => {
        let entry = []
        let counter = 0
        entry = columns[type].map((column, col_index) => {
          // case for editing an entry / a row
          if (type === edit_state.type && row_index === edit_state.row) {
            counter++
            return (
              <td scope="row" key={String(item['attribute_id']) + column}>
                <Input
                  type="textarea"
                  name={column}
                  autoFocus={counter===1}
                  onFocus={(e) => {e.target.value = ''; if (edit_state.content[column]) {e.target.value = edit_state.content[column]}}} // workaround to have the autofocus at the end of the text 
                  onChange={this.onChange}
                  value={edit_state.content[column] || ''}
                  placeholder={column}
                  style={{...{padding: '5px'}, ...col_index===0 ? input_style : {}}}
                />
              </td>
            )
          } else if (column === 'Name' && type === 'static-single') {
            return <td scope="row" key={col_index}><b>{item.attribute_type_name}</b></td>
          } else {
            return <td scope="row" key={col_index} style={{whiteSpace: "pre-line"}}>{item.content[column]}</td>
          }
        })
        // buttons for editing
        if (this.props.withEdit && this.props.user_type_id !== 0) {
          if (type === edit_state.type && row_index === edit_state.row) {
            entry.push(
              <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
                <button onClick={() => this.submitEditRow(type, row_index)} className='btn-minimal'><FontAwesomeIcon icon={faCheck} /></button>
                <button onClick={() => this.setState({ edit: {} })} className='btn-minimal'><FontAwesomeIcon icon={faX} /></button>
              </td>
            )
          } else {
            let edit_item = { ...item.content } // create copy of item to avoid changing it in the props array
            entry.push(
              <td key={'edit_btn'} style={{width: '92px', textAlign: 'center'}}>
                <button onClick={() => this.setState({ edit: {type: type, row: row_index, id: item['attribute_id'], content: edit_item} })} className='btn-minimal btn-edit'>
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <button onClick={() => this.deleteItem(type, item)} className="btn-minimal btn-trash"><FontAwesomeIcon icon={faTrash} /></button>
              </td>
            )
          }
        }
        rows.push(<tr key={item['attribute_id']}>{entry}</tr>)
      })
    })

    // table header
    let header = []
    if (attribute_types[0] === 'static-single') {
      header.push(<th key={'empty_header'}></th>)
    } else {
      header = columns[attribute_types[0]].map((column, index) => <th key={index}>{column}</th>)
      if (this.props.withEdit && (!edit_state.new || (edit_state.type && edit_state.type !== 'static-single'))) {
        header.push(<th key={'edit'} style={{width: '80px'}}>Bearbeiten</th>)
      }
    }

    if (rows.length > 0) {
      return (
        <Table className="dataTableDeleteAndEdit">
          <thead className="text-primary"><tr>{header}</tr></thead>
          <tbody>{rows}</tbody>
        </Table>
      )
    }
  } 

  render() {
    const items = this.state.items
    return (
      <>
      {this.props.parent_type === 'component' ?
        <>
          {/* table for parameters */}
          <div style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              <button onClick={() => this.setState({ edit: {new: 'parameter', type: 'parameter', content: {}} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                <FontAwesomeIcon icon={faPlus}/>
              </button>
              <h4 style={{display: 'inline-block'}}>Parameter</h4>
              {this.props.items.length > 0 || this.state.edit.type === 'parameter' ? this.renderTable(['parameter']) : null}
            </div>
          </div>
        </> :
        <>
          {/* table for static attributes */}
          <div style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              {items['static-single'].length > 0 || items['static-table'].length > 0 || this.state.edit.new === 'static' ?
                <>
                  Statische Attribute
                  {this.renderTable(['static-single'])}
                  {this.renderTable(['static-table'])}
                </> :
                <>
                  <button onClick={() => this.setState({ edit: {new: 'static'} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </button>
                  Statische Attribute
                  <br />
                  <br />
                </>
              }
            </div>
          </div>
          {/* table for dynamic attributes */}
          <div  style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              {items['dynamic-quality'].length > 0 || items['dynamic-payload'].length > 0 || this.state.edit.new === 'dynamic' ?
                <>
                  Dynamische Attribute
                  {this.renderTable(['dynamic-quality', 'dynamic-payload'])}
                </> :
                <>
                  <button onClick={() => this.setState({ edit: {new: 'dynamic'} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </button> 
                  Dynamische Attribute
                  <br />
                </>
              }
            </div>
          </div>
        </>}
      </>
    )
  }
}
export default DataTableAttributes