/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"

import Header_Card from "./Header_Card"
//import Export_Card from "./Export_Card"
import Components_Card from "./Components_Card"
import IO_Card from "./IO_Card"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'


class Module extends React.Component {
  static contextType = DeviceContext

  state = {
    modules_component: [],
    components: []
  }

  getItems() {
    fetchGetData(this.context.device, 'modules_component')
    .then(items => {
      if (items.dataExists !== 'false') {
        let modules_component = items.filter(item => item.module_id === this.props.module.module_id)
        this.setState({ modules_component })
      }
    })
    .catch(err => console.log(err))

    fetchGetData(this.context.device, 'components')
    .then(components => {
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (module_component) => {
    let modules_component = this.state.modules_component
    modules_component.push(module_component)
    this.setState({ modules_component })
    this.props.refreshRoutes()
  }

  updateState = (updated_item) => {
    this.props.updateRoute('module', updated_item['module_id'], updated_item['name'])
  }

  deleteItemFromState = (modules_component_id) => {
    const modules_component = this.state.modules_component.filter(item => item.modules_component_id !== modules_component_id)
    this.setState({ modules_component })
    this.props.refreshRoutes()
  }

  componentDidMount() {
    this.getItems()
  }


  render() {
    const component_ids = this.state.modules_component.map(item => item.component_id)
    return (
      <div className="content">
        <Header_Card module={this.props.module} updateState={this.updateState} user_type_id={this.props.user_type_id}/>
        <Components_Card module_id={this.props.module.module_id} modules_component={this.state.modules_component} user_type_id={this.props.user_type_id} addItemToState={this.addItemToState} deleteItemFromState={this.deleteItemFromState}/>
        <IO_Card item_type={'input'} components={this.state.components} component_ids={component_ids}/>
        <IO_Card item_type={'output'} components={this.state.components} component_ids={component_ids}/>
      </div>
    )
  }
}

export default Module
