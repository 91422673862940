import { fetchGetData, fetchAddNew } from 'helpers/api'
import { fetchAddInternalConnection } from "helpers/api"

export async function createComponentFromTemplate(device, template_id, component_id, view_id) {
  console.log('Copy internal structure of termplate ' + template_id + ' to component ' + component_id)

  try {
    // get all inputs, outputs and internals
    const io_tables = ['inputs', 'internals', 'outputs']
    let io_items = await Promise.all(io_tables.map(table => fetchGetData(device, table)))
    io_items = io_items.map(items => {
      if (items.dataExists === 'false') return []
      else return items
    })
    // keep only inputs, outputs and internals that belong to the template
    io_items = io_items.map(items => items.filter(item => item.component_id === template_id))

    // get all view_input_types, view_internal_types and view_output_types
    const view_type_tables = ['views_input_type', 'views_internal_type', 'views_output_type']
    let view_type_items = await Promise.all(view_type_tables.map(table => fetchGetData(device, table)))
    view_type_items = view_type_items.map(items => {
      if (items.dataExists === 'false') return []
      else return items
    })
    // keep only the ids of the types of the current view and with that
    // filter inputs, internals and outputs again, keeping only the ones which types are assigned to the current view
    let old_lengths = [io_items[0].length, io_items[1].length, io_items[2].length]
    io_tables.forEach((table, index) => {
      view_type_items[index] = view_type_items[index].filter(item => item.view_id === view_id).map(item => item[table.slice(0, -1) + '_type_id'])
      io_items[index] = io_items[index].filter(item => {
        return view_type_items[index].includes(item[table.slice(0, -1) + '_type_id'])
      })
    })
    let new_lengths = [io_items[0].length, io_items[1].length, io_items[2].length]
    // if some inputs, internals or outputs are not copied display a warning
    if (old_lengths.toString() !== new_lengths.toString()) {
      window.confirm("The template contains types of elements that are not part of this view. These elements will not be copied!")
    }

    // create the new inputs, internals and outputs
    const add_responses = await Promise.all(io_tables.map((table, index) => {
      return Promise.all(io_items[index].map(async item => {
        let attribute_values = Object.assign({}, item)
        delete attribute_values[table.slice(0, -1) + '_id']
        delete attribute_values.created_at
        delete attribute_values.updated_at
        attribute_values.component_id = component_id
        // return tuples containing the response and the id of the original item
        // the id is later used to map the new inputs, internals and outputs to the old ones to create the new internal connections
        return [await fetchAddNew(device, table, attribute_values), item[table.slice(0, -1) + '_id']]
      }))
    }))

    // save the new inputs, internals and outputs together with the id of the corresponding element of the template
    const new_inputs = add_responses[0].map(tuple => [tuple[0][0], tuple[1]])
    const new_internals = add_responses[1].map(tuple => [tuple[0][0], tuple[1]])
    const new_outputs = add_responses[2].map(tuple => [tuple[0][0], tuple[1]])
    const new_items = {inputs: new_inputs, internals: new_internals, outputs: new_outputs}

    // get all internal_connections of the template
    let internal_connections = await fetchGetData(device, 'internal_connections')
    internal_connections = internal_connections.dataExists === 'false' ? [] : internal_connections
    internal_connections = internal_connections.filter(item => item.component_id === template_id)

    // save only the ids of the template's inputs, internals and outputs in arrays for easier access
    let template_ids = { inputs: [], internals: [], outputs: [] }
    io_tables.forEach((table, index) => {
      template_ids[table] = io_items[index].map(item => {
        return item[table.slice(0, -1) + '_id']
      })
    })
    // keep only the actually needed connections,
    // not the ones connecting inputs, internals and outputs which types are not part of the view of the new component
    internal_connections = internal_connections.filter(item =>
      (template_ids[item.origin_type + 's'].includes(item['origin_' + item.origin_type + '_id'])
      && template_ids[item.destination_type + 's'].includes(item['destination_' + item.destination_type + '_id']))
    )

    // create the new internal connections
    internal_connections.forEach(connection => {
      let origin_type = connection.origin_type
      let destination_type = connection.destination_type
      // here the mapping in new_items is used, item[1] is the second part of the tuple, containing the id of the original element
      let origin = (new_items[origin_type + 's'].find(item => item[1] === connection['origin_' + origin_type + '_id']))[0]
      let destination = (new_items[destination_type + 's'].find(item => item[1] === connection['destination_' + destination_type + '_id']))[0]
      fetchAddInternalConnection(
        device, 
        connection.origin_type, 
        origin[origin_type + '_id'], 
        connection.destination_type, 
        destination[destination_type + '_id'], 
        component_id
      )
    })

    // get all attributes and their mappings with inputs, internals and outputs
    const attribute_tables = ['inputs_attribute', 'internals_attribute', 'outputs_attribute', 'attributes']
    let attribute_items = await Promise.all(attribute_tables.map(table => fetchGetData(device, table)))
    attribute_items = attribute_items.map(items => {
      if (items.dataExists === 'false') return []
      else return items
    })

    // create the new attributes and the entries in inputs_attribute, internals_attribute and outputs_attribute
    io_tables.forEach((table, index) => {
      let table_id_string = table.slice(0, -1) + '_id'
      template_ids[table].forEach(old_item_id => {
        let new_item = (new_items[table].find(tuple => tuple[1] === old_item_id))[0]
        let item_attributes = attribute_items[index].filter(item => item[table_id_string] === old_item_id)
        item_attributes.forEach(async item_attribute => {
          let old_attribute = attribute_items[3].find(attr => attr.attribute_id === item_attribute.attribute_id)
          const new_attribute = await fetchAddNew(device, "attributes", { attribute_type_id: old_attribute.attribute_type_id, content: old_attribute.content })
          fetchAddNew(device, table + '_attribute', { [table_id_string]: new_item[table_id_string], attribute_id: new_attribute[0].attribute_id })
        })
      })
    })

    // copy the parameters of the template component
    attribute_items[3].filter(attribute => attribute.component_id === template_id).map(attribute => {
      fetchAddNew(device, 'attributes', { attribute_type_id: attribute.attribute_type_id, content: attribute.content, component_id: component_id })
    })
  } catch (err) {
    console.log(err)
  }
}