/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

/*eslint-disable*/
import React from "react"
import { NavLink, Link } from "react-router-dom"
// nodejs library to set properties for components
import { PropTypes } from "prop-types"

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"

// reactstrap components
import { Collapse, Nav, /*NavLink as ReactstrapNavLink */} from "reactstrap"

import I11Logo from "assets/img/i11_Raster_1499x387_RGB.png"
import AutotechLogo from "assets/img/autotech_logo.png"
import "./Sidebar.css"
import { fetchGetData } from "helpers/api"
import { DeviceContext } from 'context/DeviceContext'

/*let ps, ps_submenu

const scrollbar_options = {
  suppressScrollX: true, 
  suppressScrollY: false,
  wheelPropagation: false,
  wheelSpeed: 0.4
}*/

class Sidebar extends React.Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.activeRoute.bind(this)
    this.state = {
      modules_component: [],
      devices: [],
      open_submenu: {devices: {}, views: {}, modules: {}, test_architectures: {}}
    }
    this.abortController = new AbortController()
    this.sidebarRef = React.createRef()
    this.viewsRef = React.createRef()
    this.modulesRef = React.createRef()
    this.test_architecturesRef = React.createRef()
    this.logoRef = React.createRef()
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }

  componentDidMount() {
    this.getItems()
    document.addEventListener('mouseover', (event) => {
      if (event.target.closest('.content, .category, .logo, #main-entries')) {
        this.toggleSubmenu(null, null)
      }
    })
    //ps = new PerfectScrollbar(this.sidebarRef.current, scrollbar_options)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.components !== prevProps.components || this.props.component_routes !== prevProps.component_routes) {
      this.getItems()
    }
  }

  getItems() {
    fetchGetData(this.context.device, "devices")
    .then(devices => {
      if (devices && devices.dataExists !== 'false') {
        this.setState({ devices })
      }
    })
    .catch(err => console.log(err))
    
    fetchGetData(this.context.device, "modules_component")
    .then(modules_component => {
      if (modules_component && modules_component.dataExists !== 'false') {
        this.setState({ modules_component })
      }
    })
    .catch(err => console.log(err))
  }

  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open")
  }

  submenuOverflow(type, key, submenu_length) {
    try {
      const submenu_height = Math.min(((submenu_length * 29.36666) + ((submenu_length - 1) * 10) + 20), 500)
      const entry_offsetTop = this[type + 'Ref'].current.children[key].offsetTop
      const logo_height = this.logoRef.current.clientHeight
      const sidebar_height = this.sidebarRef.current.clientHeight
      const sidebar_scroll = this.sidebarRef.current.scrollTop
      return (sidebar_height - entry_offsetTop + logo_height + sidebar_scroll - submenu_height) > 0
    } catch(err) {
      return false
    }
  }

  renderDevices() {
    if (this.state.open_submenu && this.state.devices.length > 0) {
      const devices = this.state.devices.filter(device => device.device_id !== this.context.device.device_id).map((device, key) => {
        return (
          <li key={key}>
            <p onClick={async () => {this.toggleSubmenu(null, null); await this.context.updateDevice(device); this.props.refreshRoutes()}}>
              {device.name}
            </p>
          </li>
        )
      })
      return (
        <div
          className="all-devices submenu"
          hidden={!this.state.open_submenu['devices'][0]}
        >
          <Nav>
            {devices}
          </Nav>
        </div>
      )
    }
  }

  renderEntries(viewRoutes, rtlActive, type) {
    if(viewRoutes == undefined) return null
    viewRoutes.sort((route_a, route_b) => {
      return route_a.id > route_b.id
    })
    return viewRoutes.map((prop, key) => {
      if (prop.redirect) return null
      let children = null
      // get the component ids of the respective view / module
      let component_ids = []
      if (prop.id && type === 'test_architectures' && prop.test_architecture) {
        component_ids = this.state['modules_component'].filter(item => item['module_id'] === prop.id).map(item => item.component_id)
      } else if (prop.id && type === 'modules' && !prop.test_architecture) {
        component_ids = this.state['modules_component'].filter(item => item['module_id'] === prop.id).map(item => item.component_id)
      } else if (prop.id && type === 'views') {
        component_ids = this.props.components.filter(item => item['view_id'] === prop.id).map(item => item.component_id)
      }
      // render the component routes of the respective view / module in a submenu
      const submenu_entries = this.props.component_routes.filter(comp => component_ids.includes(comp.id))
      const submenu_length = submenu_entries.length
      if (type && submenu_length > 0) {
        children =
          <div
            id={'submenu_' + type + '_' + prop.id}
            className="submenu"
            hidden={!this.state.open_submenu[type][prop.id]}
            style={{bottom: this.submenuOverflow(type, key, submenu_length) ? '' : 0,
                    marginTop: -(this.sidebarRef.current.scrollTop)}}
          >
            <Nav>
              {this.renderEntries(submenu_entries, rtlActive)}
            </Nav>
          </div>
      }
      // render the sidebar entry of the view / module
      return (
        <li
          className="navlink-list"
          key={key}
        >
          {children}
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
            onClick={() => {this.toggleSubmenu(null, null); this.props.toggleSidebar()}}
            onMouseEnter={() => type ? this.toggleSubmenu(type, prop.id) : null}
            style={{backgroundColor: type && this.state.open_submenu[type][prop.id] ? 'whitesmoke' : ''}}
          >
            <p style={{ fontSize: "100%", fontWeight: this.props.location.pathname === prop.layout + prop.path ? 'bold' : 'normal' }}>
              {rtlActive ? prop.rtlName : prop.name}
            </p>
          </NavLink>
          
        </li>
      )
    })
  }

  // open / close the subemnu defined by type and id
  // if both are null close all submenus
  toggleSubmenu = (type, id) => {
    let open_submenu = this.state.open_submenu
      Object.keys(open_submenu).forEach(type_key => {
        Object.keys(open_submenu[type_key]).forEach(key => {
          open_submenu[type_key][key] = false
        })
      })
    if (type !== null && id !== null) {
      open_submenu[type][id] = !open_submenu[type][id]
      /*let submenu = document.getElementById('submenu_' + type + '_' + id)
      if (submenu && open_submenu[type][id]) {
        ps_submenu = new PerfectScrollbar(submenu, scrollbar_options)
      }*/
    }
    this.setState({ open_submenu })
  }

  render() {
    const { bgColor, routes, view_routes, module_routes, degradation_rule_routes, userDashboard_route, viewManagement_route, rtlActive, logo } = this.props
    // conditionally render the main entries userDashboard and viewManagement
    let mainEntries = null
    if(userDashboard_route && viewManagement_route) {
      mainEntries =
      <div id="main-entries">
        {[userDashboard_route, viewManagement_route].map((route, index) => {
          return (
            <li className="navlink-list" key={routes.length + index + 1}>
              <NavLink
                to={route.layout + route.path}
                className="nav-link"
                activeClassName="active"
                onClick={this.props.toggleSidebar}
                onMouseEnter={() => this.toggleSubmenu(null, null)}
              >
                <p style={{ fontSize: "100%", fontWeight: this.props.location.pathname === route.layout + route.path ? 'bold' : 'normal' }}>
                  {rtlActive ? route.rtlName : route.name}
                </p>
              </NavLink>
            </li>
          )
        })}
      </div>
    }

    const current_device = this.state.devices.find(device => device['device_id'] === this.context.device['device_id'])
    //if (ps) ps.update()
    //if (ps_submenu) window.requestAnimationFrame(() => ps_submenu.update())
    return (
      <div className="sidebar" data={bgColor}>
        <div className="logo" id="autotech-logo" ref={this.logoRef}>
          <img src={AutotechLogo} alt="autotech-logo" />
        </div>
        <div className="sidebar-wrapper-wrapper">
          <div className="sidebar-wrapper" ref={this.sidebarRef}>
            <Nav id="sidebar-nav">
              {this.renderDevices()}
              <li id="current-device" className="nav-link">
                <NavLink
                  to={'its_view'}
                  onClick={() => {this.toggleSubmenu(null, null); this.props.toggleSidebar()}}
                  onMouseEnter={() => this.toggleSubmenu('devices', 0)}
                >
                  <p>{current_device ? current_device['name'] : ''}</p>
                </NavLink>
              </li>
              {/* User dashboard | Architecture Management */}
              {mainEntries}
              {/* draw views */}
              <li className="category nav-link">
                <p style={{ fontSize: "100%" }}>Sichten:</p>
              </li>
              <div className="category-div" ref={this.viewsRef}>
                {this.renderEntries(view_routes, rtlActive, 'views')}
              </div>
              {/* draw modules */}
              <li className="category nav-link">
                <p style={{ fontSize: "100%" }}>Module:</p>
              </li>
              <div className="category-div" ref={this.modulesRef}>
                {this.renderEntries(module_routes.filter(module => !module.test_architecture), rtlActive, 'modules')}
              </div>
              {/* draw modules */}
              <li className="category nav-link">
                <p style={{ fontSize: "100%" }}>Testarchitekturen:</p>
              </li>
              <div className="category-div" ref={this.test_architecturesRef}>
                {this.renderEntries(module_routes.filter(module => module.test_architecture), rtlActive, 'test_architectures')}
              </div>
              {/* draw degradation rules */}
              <li className="category nav-link">
                <p style={{ fontSize: "100%" }}>Degradationsregeln:</p>
              </li>
              <div className="category-div" ref={this.degradationrulesRef}>
                {this.renderEntries(degradation_rule_routes, rtlActive)}
              </div>
              {/* draw components */}
              {/*<li className="category nav-link">
                <p style={{ fontSize: "100%" }}>Components:</p>
              </li>
            {this.renderEntries(component_routes, rtlActive, false)}*/}
            </Nav>
          </div>
        </div>
        <div className="logo" id="i11-logo">
          <a href="https://embedded.rwth-aachen.de/doku.php?id=lehrstuhl:impressum" target="_blank"><img src={I11Logo} alt="i11-logo" /></a>
        </div>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green", "white"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
}

export default Sidebar