/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Route, Switch } from "react-router-dom"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"
import ScrollToTop from "react-scroll-to-top"

import { ReactComponent as ArrowUP_SVG } from "assets/fontawesome_svgs/arrow-up.svg"

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js"
import Footer from "components/Footer/Footer.js"
import Sidebar from "components/Sidebar/Sidebar.js"
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js"
import View_Management from "../views/View_Management/View_Management"
import View from "../views/View/View"
import ComponentP from "../views/Component/ComponentP"
import Rev_ComponentP from "../views/Revisions/Rev_Component/Rev_ComponentP"
import Rev_View_Management from "../views/Revisions/Rev_View_Management/Rev_View_Management"
import Rev_View from "../views/Revisions/Rev_View/Rev_View"
import User_Dashboard from "views/User_Dashboard/User_Dashboard"
import Module from "../views/Module/Module"
import Rev_Module from "../views/Revisions/Rev_Module/Rev_Module"
import User from "views/User/User"
import ITS_View from "views/ITS_View/ITS_View"
import Degradation_Rule from "views/Degradation_Rule/Degradation_Rule"
import License from "views/License"
import Documentation from "views/Documentation/Documentation"


import SimpleNavbar from "components/Navbars/SimpleNavbar"

// Device Context
import { DeviceContext } from "context/DeviceContext.js"
import { fetchGetData } from "helpers/api"
import { fetchGetUserData } from "helpers/api"


var ps

class Admin extends React.Component {
  static contextType = DeviceContext

  constructor(props) {
    super(props)
    this.state = {
      backgroundColor: "white",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1,
      routes: [],
      routes_with_rev: [],
      components: [],
      views: [],
      view_routes: [],
      modules: [],
      module_routes: [],
      component_routes: [],
      users: [],
      viewManagement_route: null,
      userDashboard_route: null,
      user_type_id: 0,
      degradation_rules: [],
      degradation_rule_routes: []
    }
    this.abortController = new AbortController()
    document.body.classList.add("white-content")
    this.mainPanelRef = React.createRef()
  }

  componentDidMount() {
    this.getItems(true)
    /*if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on"
      document.documentElement.classList.remove("perfect-scrollbar-off")
      ps = new PerfectScrollbar(this.mainPanelRef.current, { suppressScrollX: true })
      let tables = document.querySelectorAll(".table-responsive")
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i])
      }
    }*/
  }

  componentWillUnmount() {
    /*if (navigator.platform.indexOf("Win") > -1) {
      console.log(ps)
      console.log(ps.context)
      // fix for error shown up on windows systems
      if (typeof ps.context !== 'undefined'){
        ps.destroy()
      }
      document.documentElement.className += " perfect-scrollbar-off"
      document.documentElement.classList.remove("perfect-scrollbar-on")
    }*/

    // abort outstanding fetch requests to prevent memory leak
    this.abortController.abort()
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      /*if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive")
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i])
        }
      }*/
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.mainPanelRef.current.scrollTop = 0
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open")
    this.setState({ sidebarOpened: !this.state.sidebarOpened })
  }

  getItems(initial) {
    Promise.all([
      fetchGetData(this.context.device, "devices")
      .then(devices => {
        if (devices && devices.dataExists !== 'false') {
          if (initial && devices[0]) this.context.updateDevice(devices[0])
        }
      })
      .catch(err => console.log(err)),

      fetchGetData({}, 'components', this.abortController.signal, this.props.history)
      .then(components => {
        if (components && components.dataExists !== 'false') {
          this.setState({ components })
        }
      })
      .catch(err => console.log(err)),

      fetchGetData(this.context.device, 'views')
      .then(views => {
        if (views && views.dataExists !== 'false') {
          this.setState({ views })
        }
      })
      .catch(err => console.log(err)),

      fetchGetData(this.context.device, 'modules')
      .then(modules => {
        if (modules && modules.dataExists !== 'false') {
          this.setState({ modules })
        }
      })
      .catch(err => console.log(err)),

      fetchGetData(this.context.device, 'users')
      .then(users => {
        if (users && users.dataExists !== 'false') {
          this.setState({ users })
        }
      })
      .catch(err => console.log(err)),

      fetchGetData(this.context.device, 'degradation_rules')
      .then(degradation_rules => {
        if(degradation_rules.dataExists !== 'false') {
          this.setState({ degradation_rules })
        }
      })
      .catch(err => console.log(err)),

      fetchGetUserData(this.props.history)
      .then(user => {
        if (user && user.type) this.setState({ user_type_id: user.type })
      })
      .catch(err => console.log(err))
    ])
    .then(() => this.createRoutes())
    .catch(err => console.log(err))
  }

  // adds a new route and refreshs the sidebar and navbar to include that route
  addRoute = (item_type, new_item) => {
    let react_component
    switch (item_type) {
      case 'component':
        react_component = <ComponentP updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} component={new_item} user_type_id={this.state.user_type_id}/>
        break;
      case 'view':
        react_component = <View addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} view={new_item} user_type_id={this.state.user_type_id}/>
        break;
      case 'module':
        react_component = <Module updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} module={new_item} user_type_id={this.state.user_type_id}/>
        break;
      case 'degradation_rule':
        react_component = <Degradation_Rule degradation_rule={new_item} user_type_id={this.state.user_type_id}/>
        break;
    }
    let new_route = {
      path: '/' + item_type + '_' + new_item[item_type + '_id'],
      name: new_item['name'],
      component: () => react_component,
      layout: "/admin",
      id: new_item[item_type + '_id']
    }
    if (item_type === 'module') {
      new_route.test_architecture = new_item.test_architecture
    }

    this.setState({ routes: this.state.routes.concat([new_route]),
                    routes_with_rev: this.state.routes_with_rev.concat([new_route]),
                    [item_type + '_routes']: this.state[item_type + '_routes'].concat([new_route]),
                    [item_type + 's']: this.state[item_type + 's'].concat([new_item]) })
  }

  // forces a refresh of the sidebar and navbar
  refreshRoutes = () => {
    this.setState({ routes: [...this.state.routes] })
  }

  // removes a route and refreshs the sidebar and navbar to exclude that route
  removeRoute = (item_type, id) => {
    this.setState({ routes: this.state.routes.filter(route => route.path !== '/' + item_type + '_' + id),
                    routes_with_rev: this.state.routes_with_rev.filter(route => route.path !== '/' + item_type + '_' + id),
                    [item_type + '_routes']: this.state[item_type + '_routes'].filter(route => route.id !== id),
                    [item_type + 's']: this.state[item_type + 's'].filter(route => route.id !== id) })
  }

  // updates the name of a route and refreshs the sidebar and navbar to display the new name
  updateRoute = (item_type, id, new_name) => {
    let routes =  [...this.state.routes]
    const routes_index = routes.findIndex(route => route.path === '/' + item_type + '_' + id)
    routes[routes_index].name = new_name

    let routes_with_rev = [...this.state.routes_with_rev]
    const routes_with_rev_index = routes_with_rev.findIndex(route => route.path === '/' + item_type + '_' + id)
    routes_with_rev[routes_with_rev_index].name = new_name

    let item_routes = [...this.state[item_type + '_routes']]
    const item_routes_index = item_routes.findIndex(route => route.id === id)
    item_routes[item_routes_index].name = new_name

    let items = [...this.state[item_type + 's']]
    const item_index = items.findIndex(route => route.id !== id)
    items[item_index].name = new_name

    this.setState({ routes: routes, routes_with_rev: routes_with_rev, [item_type + '_routes']: item_routes, [item_type + 's']: items })
  }

  createRoutes() {
    let component_route = this.state.components.map(component => {
      let local_component_route =
      {
        path: "/component_" + component.component_id,
        name: component.name,
        icon: "tim-icons icon-app",
        component: () => <ComponentP updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} component={component} user_type_id={this.state.user_type_id}/>,
        layout: "/admin",
        id: component.component_id
      }
      return local_component_route
    })
    let rev_component_route = this.state.components.map(component => {
      let local_rev_component_route =
      {
      path: "/rev/rev_component_" + component.component_id,
      name: "Revision " + component.name,
      icon: "tim-icons icon-app",
      component: Rev_ComponentP,
      layout: "/admin",
      id: component.component_id
      }
      return local_rev_component_route
    })
    
    let module_route = []
    let rev_module_route = []
    let modules = false
    let test_architectures = false
    this.state.modules.forEach(module => {
      if (module.test_architecture) test_architectures = true
      else modules = true
      module_route.push(
        {
          path: "/module_" + module.module_id,
          name: module.name,
          icon: "tim-icons icon-molecule-40",
          component: () => <Module updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} module={module} user_type_id={this.state.user_type_id}/>,
          layout: "/admin",
          id: module.module_id,
          test_architecture: module.test_architecture
        }
      )
      rev_module_route.push(
        {
          path: "/rev/rev_module_" + module.module_id,
          name: module.name,
          icon: "tim-icons icon-molecule-40",
          component: Rev_Module,
          layout: "/admin",
          id: module.module_id,
          test_architecture: module.test_architecture
        }
      )
    })
    if (!modules) {
      module_route.push(
        {
          path: "/view_management",
          name: "Keine Module definiert",
          icon: "tim-icons icon-molecule-40",
          component: () => <View_Management addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} user_type_id={this.state.user_type_id}/>,
          layout: "/admin"
        }
      )
    }
    if (!test_architectures) {
      module_route.push(
        {
          path: "/view_management",
          name: "Keine Testarchitekturen definiert",
          icon: "tim-icons icon-molecule-40",
          component: () => <View_Management addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} user_type_id={this.state.user_type_id}/>,
          layout: "/admin",
          test_architecture: true
        }
      )
    }

    if (this.state.views.length > 0) {
      var view_route = this.state.views.map(view => {
        var local_view_route =
        {
          path: "/view_" + view.view_id,
          name: view.name,
          icon: "tim-icons icon-components",
          component: () => <View addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} refreshRoutes={this.refreshRoutes} view={view} user_type_id={this.state.user_type_id}/>,
          layout: "/admin",
          id: view.view_id
        }
        return local_view_route
      })
      var rev_view_route = this.state.views.map(view => {
        var local_rev_view_route =
        {
          path: "/rev/rev_view_" + view.view_id,
          name: view.name,
          icon: "tim-icons icon-components",
          component: Rev_View,
          layout: "/admin",
          id: view.view_id
        }
        return local_rev_view_route
      })
    } else {
      var view_route = [
        {
          path: "/view_management",
          name: "Keine Sichten definiert",
          icon: "tim-icons icon-components",
          component: () => <View_Management addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} user_type_id={this.state.user_type_id}/>,
          layout: "/admin"
        }
      ]
      var rev_view_route = []
    }

    let user_route = this.state.users.map(user => {
      let local_user_route = {
        path: "/user_" + user.user_id,
        name: user.name,
        component: () => <User user={user}/>,
        layout: "/admin",
        id: user.user_id
      }
      return local_user_route
    })

    let degradation_rule_route
    if (this.state.degradation_rules.length > 0) {
      degradation_rule_route = this.state.degradation_rules.map(rule => {
        let local_rule_route = {
          path: "/degradation_rule_" + rule.degradation_rule_id,
          name: rule.name,
          component: () => <Degradation_Rule degradation_rule={rule} user_type_id={this.state.user_type_id}/>,
          layout: "/admin",
          id: rule.degradation_rule_id
        }
        return local_rule_route
      })
    } else {
      degradation_rule_route = [
        {
          path: "/view_management",
          name: "Keine Degradations-Regeln definiert",
          icon: "tim-icons icon-components",
          component: () => <View_Management addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} user_type_id={this.state.user_type_id}/>,
          layout: "/admin"
        }
      ]
    }

    var user_dashboard = [
      {
        path: "/user_dashboard",
        name: "User Dashboard",
        icon: "tim-icons icon-bullet-list-67",
        component: User_Dashboard,
        layout: "/admin"
      }
    ]

    var view_management = [
      {
        path: "/view_management",
        name: "Architektur Management",
        icon: "tim-icons icon-bullet-list-67",
        component: () => <View_Management addRoute={this.addRoute} removeRoute={this.removeRoute} updateRoute={this.updateRoute} user_type_id={this.state.user_type_id}/>,
        layout: "/admin"
      }
    ]
    var rev_view_management = [
        {
        path: "/rev/rev_view_management",
        name: "Revision Architecture Management",
        icon: "tim-icons icon-bullet-list-67",
        component: Rev_View_Management,
        layout: "/admin"
      }
    ]

    let its_view = [
      {
        path: "/its_view",
        name: "ITS View",
        component: () => <ITS_View refreshRoutes={this.refreshRoutes} user_type_id={this.state.user_type_id}/>,
        layout: "/admin"
      }
    ]

    let documentation = [
      {
        path: "/documentation",
        name: "Dokumentation",
        component: () => <Documentation user_type_id={this.state.user_type_id}/>,
        layout: "/admin"
      }
    ]

    var license = [
      {
        path: "/license",
        name: "License",
        icon: "tim-icons icon-single-copy-04",
        component: () => <License/>,
        layout: "/admin"
      }
    ]

    var dummy_view = [
      {
        name: "Views:"
      }
    ]

    var dummy_component = [
      {
        name: "Components:"
      }
    ]

    var dummy_module = [
      {
        name: "Modules:"
      }
    ]

    var dummy_empty = [
      {
        name: " "
      }
    ]

    let route = user_dashboard.concat(view_management).concat(its_view).concat(documentation).concat(dummy_view).concat(view_route).concat(dummy_module).concat(module_route).concat(dummy_component).concat(component_route).concat(user_route).concat(degradation_rule_route).concat(dummy_empty).concat(license)
    this.setState({ 
      routes: route, 
      view_routes: view_route,
      module_routes: module_route,
      component_routes: component_route,
      degradation_rule_routes: degradation_rule_route,
      viewManagement_route: view_management[0],
      userDashboard_route: user_dashboard[0]
    })
    route = route.concat(rev_view_management).concat(rev_component_route).concat(rev_view_route).concat(rev_module_route)
    this.setState({ routes_with_rev : route})
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component id={prop.id} />}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  handleBgClick = color => {
    this.setState({ backgroundColor: color })
  }
  getBrandText = path => {
    for (let i = 0; i < this.state.routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          this.state.routes[i].layout + this.state.routes[i].path
        ) !== -1
      ) {
        return this.state.routes[i].name
      }
    }
    return "Revision"
  }

  render() {
    const current_components = this.state.components.filter(comp => comp.devices && comp.devices.map(device => device.device_id).includes(this.context.device.device_id))
    return (
      <>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            view_routes={this.state.view_routes}
            module_routes={this.state.module_routes}
            component_routes={this.state.component_routes}
            degradation_rule_routes={this.state.degradation_rule_routes}
            userDashboard_route={this.state.userDashboard_route}
            viewManagement_route={this.state.viewManagement_route}
            routes={this.state.routes}
            refreshRoutes={this.refreshRoutes}
            components={this.context.device.device_id ? current_components : this.state.components}
            bgColor={this.state.backgroundColor}
            logo={{
              outterLink: "http://embedded.rwth-aachen.de/"
            }}
            toggleSidebar={this.toggleSidebar}
          >
          </Sidebar>
          <div
            className="main-panel"
            ref={this.mainPanelRef}
            data={this.state.backgroundColor}
          >
            {/*<SimpleNavbar {...this.props} 
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
              viewRoutes={this.state.view_routes}
              views={this.state.views}/>*/}
            <AdminNavbar
              {...this.props}
              routes={this.state.routes}
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
              user_type_id={this.state.user_type_id}
            />
            <Switch>
              {this.getRoutes(this.state.routes_with_rev)}
              {/* I had to uncomment this line to avoid the component from being remounted before data could be fetched */}
              {/* <Redirect from="*" to="/admin/user_dashboard" /> */}
            </Switch>
            {// we don't want the Footer to be rendered on map page
              this.props.location.pathname.indexOf("maps") !== -1 ? null : (
                <Footer fluid />
              )}
          </div>
        </div>
        <ScrollToTop
          smooth
          top={100}
          color='#1d253b'
          style={{border: '1px solid #1d253b', marginBottom: 40}}
          component={<ArrowUP_SVG style={{width: 24, height: 24}} />}
        />
        {/*<FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />*/}
      </>
    )
  }
}

export default Admin
