import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import 'react-medium-image-zoom/dist/styles.css'
import Cytoscape from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCheck, faUndo, faRedo, faQuestion, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faMaximize, faMinimize, faCircleNodes } from '@fortawesome/free-solid-svg-icons'
import { DeviceContext } from 'context/DeviceContext'
import { fetchEdit } from 'helpers/api.js'
import contextMenus from 'cytoscape-context-menus'
import 'cytoscape-context-menus/cytoscape-context-menus.css'
import cytoscapeEdgehandles from 'cytoscape-edgehandles'
import translate from 'helpers/translations'
import Angle_SVG from 'assets/fontawesome_svgs/angle-right.svg'

import Info_Bar from './Info_Bar'
import './Cytoscape_Card.css'

Cytoscape.use(contextMenus)
Cytoscape.use(cytoscapeEdgehandles)

class Cytoscape_Card extends Component {
  static contextType = DeviceContext

  state = {
    edit: null,
    undo_actions: [],
    redo_actions: [],
    selected: [],
    edgehandle_instance: null,
    zoom_level: 1,
    resize: false,
    event_listener_controller: undefined,
    max_height: document.body.clientHeight-100,
    min_height: 400,
    fullscreen: {enabled: false, height: this.props.default_height}
  }

  componentDidMount() {
    const controller = new AbortController()
  
    document.addEventListener('keydown', this.keyHandler, {signal: controller.signal})
    document.addEventListener('keyup', this.keyHandler, {signal: controller.signal})
    document.addEventListener('wheel', this.wheelHandler, {signal: controller.signal})
    document.addEventListener('mousedown', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('mouseup', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('mousemove', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('dblclick', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('fullscreenchange', this.fullScreenHandler, {signal: controller.signal})

    this.setState({ event_listener_controller: controller })
  }

  componentWillUnmount() {
    this.state.event_listener_controller.abort()
  }

  keyHandler = (event) => {
    if (document.activeElement === document.body || document.activeElement === document.getElementById('dummy-input')) {
      if (event.type === 'keydown' && this.cy) {
        if (event.ctrlKey && event.key === 'z') {
          this.undoRedo('undo')
        } else if (event.ctrlKey && event.key === 'y') {
          this.undoRedo('redo')
        } else if (event.key === 'Control' && this.state.edgehandle_instance) {
          this.state.edgehandle_instance.enableDrawMode()
        } else if (event.key === 'Delete' || event.key === 'Backspace') {
          if (!this.state.edit) {
            this.deleteSelectedElements()
          }
        }
      } else if (event.type === 'keyup' && this.cy) {
        if (event.key === 'Control' && this.state.edgehandle_instance) {
          this.state.edgehandle_instance.disableDrawMode()
        }
      }
    }
  }

  wheelHandler = (event) => {
    if (event.shiftKey && event.target.closest('#cy-div')) {
      event.preventDefault()
      let amount = event.deltaY > 0 ? -0.2 : 0.2
      const cy_div_pos = document.getElementById('cy-div').getBoundingClientRect()
      const cy_mouse_pos = {x: event.clientX - cy_div_pos.left, y: event.clientY - cy_div_pos.top}
      this.zoomGraph(amount, cy_mouse_pos)
    }
  }
  
  resizeHandler = (event) => {
    if (event.type === 'mousedown' && event.target.matches('#cy-resize') && event.button === 0) {
      this.setState({ resize: true })
      document.body.style.cursor = 'row-resize'
    } else if (this.state.resize && event.type === 'mouseup') {
      this.setState({ resize: false })
      document.body.style.cursor = 'default'
    } else if (this.state.resize && event.type === 'mousemove') {
      const cy_div = document.getElementById('cy-div')
      const new_height = Math.min(Math.max(event.clientY - cy_div.getBoundingClientRect().y - 15, 400), this.state.max_height)
      cy_div.style.height = String(new_height) + 'px'
    } else if (event.type === 'dblclick' && event.target.matches('#cy-resize')) {
      document.getElementById('cy-div').style.height = String(Math.min(Math.max(this.props.default_height, this.state.min_height), this.state.max_height)) + 'px'
    }
  }

  fullScreenHandler = (event) => {
    const cy_div = document.getElementById('cy-div')
    if (document.fullscreenElement) {
      this.setState({ fullscreen: {enabled: true, height: cy_div.getBoundingClientRect().height } })
      cy_div.style.height = String(document.body.clientHeight) + 'px'
      this.cy.center()
    } else {
      cy_div.style.height = String(this.state.fullscreen.height) + 'px'
      this.setState({ fullscreen: {enabled: false, height: this.props.default_height },zoom_level: 1 })
      this.cy.fit(10)
    }
  }

  componentDidUpdate(prev_props) {
    if (this.props.initialized && !prev_props.initialized) {
      this.buildCy()
    } else {
      if (this.state.new_element) {
        let prev_ids = prev_props.elements.map(elem => elem.data.id)
        const new_element = this.props.elements.find(elem => !prev_ids.includes(elem.data.id))
        if (new_element) {
          const item_type = new_element.data.id.split('-')[0]
          const new_id = Number(new_element.data.id.split('-')[1])
          this.saveUndoAction({ action: 'create', item_type: item_type, id: new_id})
          this.setState({ new_element: false })
        }
      }
      if (this.props.contextmenu_items !== prev_props.contextmenu_items) {
        this.buildContextMenu()
      }
      // needed to ensure correct cursor position after initialization
      this.cy.resize()
    }
  }

  async buildCy() {
    const stylesheet = [
      {
        selector: 'node',
        style: {
          width: 200,
          height: 50,
          shape: 'round-rectangle',
          borderColor: 'black',
          borderWidth: '2',
          backgroundColor: 'white',
          textValign: 'center',
          textHalign: 'center',
          textWrap: 'wrap'
        }
      },
      {
        selector: 'node[label]',
        style: {
          content: 'data(label)'
        }
      },
      {
        selector: 'node:selected',
        style: {
          backgroundColor: 'lightgray',
          borderWidth: '4'
        }
      },
      {
        selector: '.internals, .components',
        style: {
          borderColor: '#356cc4'
        }
      },
      {
        selector: '.component',
        style: {
          borderColor: '#356cc4',
          borderWidth: '3'
        }
      },
      {
        selector: 'edge',
        style: {
          width: 2,
          lineColor: 'gray',
          curveStyle: 'bezier',
          targetArrowShape: 'triangle',
          targetArrowColor: 'gray'
        }
      },
      {
        selector: 'edge:selected',
        style: {
          width: 3,
          lineColor: 'black',
          targetArrowColor: 'black'
        }
      },
      {
        selector: '.eh-ghost-edge.eh-preview-active',
        style: {
          'opacity': 0
        }
      }
    ]
    this.cy.style(stylesheet)



    let edgehandle_defaults = {
      canConnect: (source_node, target_node) => {
        // whether an edge can be created between source and target
        const no_loop = !source_node.same(target_node)
        const no_duplicates = source_node.edgesTo(target_node).length === 0
        const source_is_output = source_node.hasClass('outputs')
        const target_is_input = target_node.hasClass('inputs')
        const same_parent = source_node.parent() === target_node.parent()
        return no_loop && no_duplicates && (this.props.graph_type === 'component' || (source_is_output && target_is_input && !same_parent))
      },
      edgeParams: (source_node, target_node) => {
        // for edges between the specified source and target
        // return element object to be passed to cy.add() for edge
        return {}
      },
      hoverDelay: 10, // time spent hovering over a target node before it is considered selected
      snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
      snapThreshold: 10, // the target node must be less than or equal to this many pixels away from the cursor/finger
      snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
      noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
      disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    }

    let edgehandle_instance = this.cy.edgehandles(edgehandle_defaults)
    this.setState({ edgehandle_instance })

    this.cy.on('ehstart', (event, source_node) => {
      if (this.props.graph_type === 'view' && !source_node.hasClass('outputs')
          || this.props.graph_type === 'component' & source_node.hasClass('outputs')) {
        this.state.edgehandle_instance.stop()
      }
    })

    this.cy.on('ehcomplete', (event, source_node, target_node, added_edge) => {
      const source_node_id = source_node.data('id')
      const origin_item_type = source_node_id.split('-')[0]
      const origin_id = Number(source_node_id.split('-')[1])

      const target_node_id = target_node.data('id')
      const dest_item_type = target_node_id.split('-')[0]
      const dest_id = Number(target_node_id.split('-')[1])

      if (this.props.graph_type === 'component') {
        this.props.createConnection(origin_item_type, origin_id, dest_item_type, dest_id)
      } else if (this.props.graph_type === 'view') {
        this.props.createConnection(origin_id, dest_id)
      }
      this.setState({ new_element: true })
      added_edge.remove()
    })

    /*this.cy.on('tapdragover', 'node', (event) => {
      console.log(event.target.position())
    })*/

    this.cy.on('tap', '.components, .inputs, .outputs, .internals', (event) => {
      if (event.target.selected() && this.cy.$(':selected').length === 1) {
        let cy_id = event.target.data('id')
        let item_type = cy_id.split('-')[0] // input, output or internal
        this.startRename(cy_id, item_type, {})
      }
    })

    this.cy.on('cxttap', 'node, edge', (event) => {
      if (!event.target.selected() && event.target.selectable()) {
        this.cy.$(':selected').unselect()
        event.target.select()
      }
    })

    this.cy.on('dbltap', 'node', (event) => {
      if (this.props.graph_type === 'component') {
        const cy_id = event.target.data('id')
        const item_type = cy_id.split('-')[0]
        const io_id = Number(cy_id.split('-')[1])
        this.props.scrollToSubcard(item_type, io_id)
      } else if (event.target.hasClass('components')) {
        const link = event.target.data('href')
        window.open(link, '_self')
      }
      if (this.state.edit) {
        this.endRename(false)
      }
    })

    this.cy.on('select', '.compound-parents', (event) => {
      event.target.children().select()
    })

    this.cy.on('tap', '.compound-parents', (event) => {
      if (event.target.selected()) {
        event.target.unselect()
      }
    })

    this.cy.on('select', 'node, edge', (event) => {
      this.setState({ selected: this.cy.$(':selected') })
    })

    this.cy.on('unselect', 'node, edge', (event) => {
      this.setState({ selected: this.cy.$(':selected') })
    })

    if (this.props.use_layout) {
      this.cy.layout({name: 'dagre', rankDir: 'LR', nodeSep: 20}).run()
    }

    this.buildContextMenu()

    await this.cy.center()
    this.cy.fit(10)
    if (this.cy.zoom() > 2) {
      this.cy.zoom({level: 1, })
      this.cy.center()
    }
  }

  buildContextMenu() {
    const contextmenu_options = {
      evtType: 'cxttap',
      menuItems: this.props.contextmenu_items,
      menuItemClasses: ['cy-contextmenu-item'],
      contextMenuClasses: ['cy-contextmenu'],
      submenuIndicator: { src: Angle_SVG, width: 15, height: 15 }
    }

    const contextmenu_instance = this.cy.contextMenus(contextmenu_options)
  }

  deleteSelectedElements() {
    let deleted = this.props.deleteGraphElements(this.cy.$(':selected'))
    this.setState({ selected: [] })
    if (deleted.length > 0) {
      this.saveUndoAction({ action: 'delete', items: deleted })
    }
  }

  createNewNode (position, item_type, io_type, parent_id) {
    this.cy.add(this.props.createNewNode(this.cy, position, item_type, io_type, parent_id))
    this.startRename('new', item_type, io_type)
  }

  startRename(cy_id, item_type, io_type) {
    this.cy.$(':selected').unselect()
    this.cy.$('#' + cy_id).select()
    let label = this.cy.$('#' + cy_id).data('label')
    let label_top = label.split('\n')[0]
    let label_bottom = label.split('\n')[1]
    this.setState({ edit: {
      cy_id: cy_id,
      old_label: label,
      label_top: label_top,
      label_bottom: label_bottom,
      item_type: item_type,
      io_type: io_type,
      cursor_offset: 0,
      cursor_visible: true }})
    this.cy.$('#' + cy_id).data('label', label_top + '\n' + label_bottom + '|')
    document.getElementById('dummy-input').value = label_bottom
    document.addEventListener('keydown', this.onRenameKeydown)
    document.getElementById('dummy-input').focus()
    this.blinkCursor()
  }

  endRename = (confirm) => {
    // if the dummy input is still focused, blur it. blurring it then calls endRename again
    if (document.activeElement.id === 'dummy-input') {
      document.getElementById('dummy-input').blur()
    } else {
      let edit = this.state.edit
      const old_label = edit.old_label
      const new_label = edit.label_top + '\n' + edit.label_bottom
      clearInterval(edit.blinking_interval)
      document.removeEventListener('keydown', this.onRenameKeydown)
      document.getElementById('dummy-input').value = ''
      this.setState({ edit: null })
      this.cy.$('#' + edit.cy_id).unselect()
      if (confirm && edit.cy_id === 'new') {
        if (edit.label_bottom && edit.label_bottom !== '') {
          let position = this.cy.$('#new').position()
          if (edit.item_type === 'component') {
            this.props.createComponent({name: edit['label_bottom'].trim(), position: position})
          } else {
            let component_id = this.cy.$('#new').parent().length > 0 ? this.cy.$('#new').parent().data('id').split('-')[1] : ''
            let attributes = { name: edit['label_bottom'].trim(), component_id: component_id, position: position }
            this.props.addIO(edit.item_type, edit.io_type, attributes)
          }
          this.setState({ new_element: true })
        }
        this.cy.remove('.new')
      } else if (confirm && edit.label_bottom && edit.label_bottom !== '' && old_label !== new_label) {
        let id = Number(edit.cy_id.split('-')[1])
        // TODO: move fetchEdit to ComponentP.js / View.js?
        fetchEdit(edit.item_type + 's', undefined, id, {name: edit['label_bottom'].trim()})
        .then(item => {
          if (Array.isArray(item)) {
            this.cy.$('#' + edit.cy_id).data('label', new_label)
            this.saveUndoAction({ action: 'rename', item_type: edit.item_type, id: id, old_label: edit.old_label, new_label: new_label })
            this.props.updateState(edit.item_type, item[0])
            this.cy.$('#' + edit.cy_id).select()
          }
        })
        .catch(err => console.log(err))
      } else {
        this.cy.$('#' + edit.cy_id).data('label', edit.old_label)
        this.autoNodeWidth(edit.cy_id)
        this.cy.remove('.new')
        this.cy.$('#' + edit.cy_id).select()
      }
    }
  }

  onRename = (e) => {
    let edit = this.state.edit
    edit.label_bottom = e.target.value
    this.setState({ edit })
    this.makeNodeLabel()
    this.autoNodeWidth(edit.cy_id)
  }

  onRenameKeydown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.endRename(true)
    } else if (e.key === 'Escape') {
      e.preventDefault()
      this.endRename(false)
    } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete') {
      let edit = this.state.edit
      const new_offset = e.key === 'ArrowLeft' ? Math.min(edit.label_bottom.length, edit.cursor_offset+1) : Math.max(0, edit.cursor_offset-1)
      edit.cursor_offset = new_offset
      this.setState({ edit })
      this.makeNodeLabel()
    }
  }

  makeNodeLabel() {
    let edit = this.state.edit
    let cursor = edit.cursor_visible ? '|': '\u00B7'
    const label_bottom = edit.cursor_offset === 0 ? 
      edit.label_bottom + cursor : edit.label_bottom.slice(0, -edit.cursor_offset) + cursor + edit.label_bottom.slice(-edit.cursor_offset)
    this.cy.$('#' + edit.cy_id).data('label', edit.label_top + '\n' + label_bottom)
  }

  blinkCursor() {
    let edit = this.state.edit
    edit.blinking_interval = setInterval(() => {
      edit.cursor_visible = !edit.cursor_visible
      this.setState({ edit })
      this.makeNodeLabel()
    }, 800)
    this.setState({ edit })
  }

  saveUndoAction(action) {
    let undo_actions = this.state.undo_actions
    undo_actions.push(action)
    this.setState({ undo_actions, redo_actions: [] })
  }

  async undoRedo(direction) {
    const other_direction = direction === 'undo' ? 'redo' : 'undo'
    let actions = this.state[direction + '_actions']
    const action = actions.at(-1)
    if (action) {
      const other_actions = this.state[other_direction + '_actions'].concat([action])
      this.setState({ [direction + '_actions']: actions.slice(0, -1), [other_direction + '_actions']: other_actions })
      if (action.action === 'rename') {
        const label = direction === 'undo' ? action.old_label : action.new_label
        // TODO: move fetchEdit to ComponentP.js / View.js?
        fetchEdit(action.item_type + 's', undefined, action.id, {name: label.split('\n')[1]})
        .then(item => {
          if (Array.isArray(item)) {
            const cy_id = action.item_type + '-' + action.id
            this.cy.$('#' + cy_id).data('label', label)
            this.autoNodeWidth(cy_id)
            this.props.updateState(action.item_type, item[0])
          }
        })
      } else if (action.action === 'delete') {
        if (direction === 'undo') {
          this.props.restoreItems(action.items)
        } else if (direction === 'redo') {
          // await and setstate is necessary to properly update the info bar
          await this.props.deleteItems(action.items, true)
          this.setState({ selected: this.cy.$(':selected') })
        }
      } else if (action.action === 'create') {
        if (direction === 'undo') {
          // await and setstate is necessary to properly update the info bar
          await this.props.deleteItems([{item_type: action.item_type, id: action.id}], true)
          this.setState({ selected: this.cy.$(':selected') })
        } else if (direction === 'redo') {
          this.props.restoreItems([{item_type: action.item_type, id: action.id}])
        }
      }
    }
  }

  autoNodeWidth(cy_id) {
    const label = this.cy.$('#' + cy_id).data('label')
    const label_top = label.split('\n')[0]
    const label_bottom = label.split('\n')[1]
    this.cy.$('#' + cy_id).style('width', Math.max(label_top.length, label_bottom.length, 6)*9)
  }

  zoomGraph(amount, position) {
    const center = {x: this.cy.width()/2, y: this.cy.height()/2}
    let new_level = Math.min(Math.max(this.cy.zoom()+amount, 0.2), 2)
    this.cy.zoom({level: new_level, renderedPosition: position ? position : center})
    this.setState({ zoom_level: new_level })
  }

  selectElements = (ids) => {
    this.cy.$(':selected').unselect()
    ids.forEach(id => {
      this.cy.$('#' + id).select()
    })
    // move the selected elements inside the viewport
    const bounding_box = this.cy.$(':selected').renderedBoundingBox()
    let pan_x = 0
    let pan_y = 0
    let zoom = 0
    const info_bar_width = document.getElementById('info-bar').getBoundingClientRect().width
    if (bounding_box.x1 < 0 && bounding_box.x2 > this.cy.width()-info_bar_width) {
      zoom = -0.2
    } else if (bounding_box.x1 < 0) {
      pan_x = -bounding_box.x1+20
    } else if (bounding_box.x2 > this.cy.width()-info_bar_width) {
      pan_x = -bounding_box.x2+this.cy.width()-info_bar_width-20
    }
    if (bounding_box.y1 < 0 && bounding_box.y2 > this.cy.height()) {
      zoom = -0.2
    } else if (bounding_box.y1 < 0) {
      pan_y = -bounding_box.y1+20
    } else if (bounding_box.y2 > this.cy.height()) {
      pan_y = -bounding_box.y2+this.cy.height()-20
    }
    this.cy.panBy({x: pan_x, y: pan_y})
    this.cy.zoom({level: this.cy.zoom()-zoom})
  }

  async saveGraph() {
    try {
      const item = await fetchEdit(this.props.graph_type + 's', undefined, this.props.graph_id, {graph: this.cy.json()})
      this.setState({ graph_saved: true })
      this.props.updateParent(item[0])
      setTimeout(() => this.setState({ graph_saved: false }), 1000)
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const info_btn = <Link to="/admin/documentation#component_doc" target="_blank"><button className='btn-minimal btn-edit'><FontAwesomeIcon icon={faQuestion} /></button></Link>
    const save_btn = <button onClick={() => {this.saveGraph()}} className='btn-minimal btn-edit' data-toggle="tooltip" data-placement="top" title="Graph Layout speichern" hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faSave} /></button>
    const saved_icon =
      <button
        className='btn-minimal btn-edit'
        data-toggle="tooltip"
        data-placement="top"
        title="Gespeichert"
        style={{ color: '#fb6340'}}
      ><FontAwesomeIcon icon={faCheck} /></button>
    const auto_layout_btn =
      <button
        onClick={() => {this.cy.layout({name: 'dagre', rankDir: 'LR', nodeSep: 20}).run()}}
        className='btn-minimal btn-edit'
        data-toggle="tooltip"
        data-placement="top"
        title="Graph Layout zurücksetzen"
        hidden={this.props.user_type_id === 0}
      ><FontAwesomeIcon icon={faCircleNodes} /></button>
    const undo_btn =
      <button
        onClick={() => {this.undoRedo('undo')}}
        className='btn-minimal btn-edit'
        data-toggle='tooltip' data-placement='top'
        title={this.state.undo_actions.length > 0 ? 'Rückgängig: ' + translate(this.state.undo_actions.at(-1).action) : ''}
        disabled={this.state.undo_actions.length === 0}
        hidden={this.props.user_type_id === 0}
      ><FontAwesomeIcon icon={faUndo} /></button>
    const redo_btn =
      <button
        onClick={() => {this.undoRedo('redo')}}
        className='btn-minimal btn-edit'
        data-toggle='tooltip' data-placement='top'
        title={this.state.redo_actions.length > 0 ? 'Wiederholen: ' + translate(this.state.redo_actions.at(-1).action) : ''}
        disabled={this.state.redo_actions.length === 0}
        hidden={this.props.user_type_id === 0}
      ><FontAwesomeIcon icon={faRedo} /></button>
    const zoom_in_btn =
      <button className='btn-minimal btn-edit'
        onClick={() => {this.zoomGraph(0.2, null)}}
        data-toggle='tooltip' data-placement='top' title={this.state.zoom_level > 1.9 ? '' : 'Vergrößern'}
        disabled={this.state.zoom_level > 1.9}
        hidden={this.props.user_type_id === 0}
      ><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></button>
    const zoom_out_btn =
      <button className='btn-minimal btn-edit'
        onClick={() => {this.zoomGraph(-0.2, null)}}
        data-toggle='tooltip' data-placement='top' title={this.state.zoom_level < 0.3 ? '' : 'Verkleinern'}
        disabled={this.state.zoom_level < 0.3}
        hidden={this.props.user_type_id === 0}
      ><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
      const fullscreen_btn =
        <button className='btn-minimal btn-edit'
          onClick={() => {this.state.fullscreen.enabled ? document.exitFullscreen() : document.getElementById('cy-card').requestFullscreen()/*document.getElementById('cy-div').style.height = String(this.state.max_height) + 'px'; document.getElementById('cy-card').scrollIntoView()*/}}
          data-toggle='tooltip' data-placement='top' title={'Maximieren'}
        ><FontAwesomeIcon icon={this.state.fullscreen.enabled ? faMinimize : faMaximize} /></button>
    const dummy_input = <input type='text' id='dummy-input' onChange={this.onRename} onBlur={() => this.endRename(true)}></input>

    return (
      <Card id='cy-card'>
        <CardHeader>
          <CardTitle tag="h4">Graph
          {dummy_input} {this.state.graph_saved ? saved_icon : save_btn} {auto_layout_btn} {info_btn} {undo_btn}{redo_btn} {zoom_in_btn}{zoom_out_btn} {fullscreen_btn}
          </CardTitle>
        </CardHeader>
        <CardBody style={{paddingBottom: 0}}>
          {this.props.initialized ? null : <div className='spinner-wrapper'><Spinner/></div>}
          <div style={this.props.initialized ? {} : {height: 0}}>
            <CytoscapeComponent
              id='cy-div'
              cy={(cy) => { this.cy = cy }}
              elements={this.props.elements}
              style={{width: '100%', height: Math.min(Math.max(this.props.default_height, this.state.min_height), this.state.max_height)}}
              zoomingEnabled={true}
              userZoomingEnabled={false}
            />
            <Info_Bar
            attributes={this.props.attributes}
              components={this.props.components}
              graph_type={this.props.graph_type}
              inputs={this.props.inputs}
              inputs_attribute_sum={this.props.inputs_attribute_sum}
              inputs_output={this.props.inputs_output}
              internals={this.props.internals}
              internals_attribute_sum={this.props.internals_attribute_sum}
              outputs={this.props.outputs}
              outputs_attribute_sum={this.props.outputs_attribute_sum}
              selected={this.state.selected}
              selectElements={this.selectElements}
            />
          </div>
          <button id='cy-resize' className='btn-minimal' hidden={!this.props.initialized}>=</button>
        </CardBody>
      </Card>
    )
  }
}
export default Cytoscape_Card