import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { Card, CardHeader, CardBody, CardTitle} from "reactstrap"
import api_url from "../../helpers/config.js"
import translate from 'helpers/translations.js'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'

class IO_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, this.props.item_type + 's')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let items = []
    this.state.items.forEach(item => {
      if (this.props.component_ids.includes(item.component_id)) {
        const component = this.props.components.find(comp => comp.component_id === item.component_id)
        item.component_name = component ? component.name : ''
        items.push(item)
      }
    })
    return (
      <Card>
        <CardHeader>
            <CardTitle tag="h4">{translate(this.props.item_type + 's')}</CardTitle>
        </CardHeader>
        <CardBody>
          <DataTableDeleteAndEdit
            items={items}
            item_type={this.props.item_type}
            columns={['Komponente', translate(this.props.item_type)]}
            columns_DB={['component_name', 'name']}
            links={{'component_name': {'id': 'component_id', 'type': 'component'}}}
            withDelete={false}
            withEdit={false}
          />
        </CardBody>
      </Card>
    )
  }
}

export default IO_Card
