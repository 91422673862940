import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class User_Dashboard_Doc extends Component {

  render() {
    return (
      <Card id='user_dashboard_doc'>
        <CardHeader>
          <CardTitle tag='h4'>User Dashboard</CardTitle>
        </CardHeader>
        <CardBody>
          Das User Dashboard bietet einen Überblick über die eigenen Komponenten sowie die Möglichkeit persönliche Daten zu ändern. <br />
          Die nutzerbezogenen Daten lassen sich mit einem Klick auf den Stift ändern.
          Hier ist es außerdem möglich ein neues Passwort zu setzen. <br />
          In der Tabelle "Meine Komponenten" werden alle Komponenten aufgelistet, die mit dem eigenen Nutzeraccount verknüpft sind.
          Dies ist automatisch der Fall wenn man eine Komponente erstellt, kann aber auch manuell auf der Seite der jeweiligen
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Komponente</a> geändert werden.
        </CardBody>
      </Card>
    )
  }
}

export default User_Dashboard_Doc