import React from "react"
import Devices_Card from "./Devices_Card"
import Graph_Card from "./Graph_Card"
import { DeviceContext } from 'context/DeviceContext'

class ITS_View extends React.Component { 
  static contextType = DeviceContext

  render() {
    return (
      <>
        <div className="content">
          <Devices_Card refreshRoutes={this.props.refreshRoutes} user_type_id={this.props.user_type_id}/>
          <Graph_Card />
        </div>
      </>
    )
  }
}

export default ITS_View
