import React, { Component } from 'react'
import { Card, CardHeader, CardBody, CardTitle, Button, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp, faPlus } from "@fortawesome/free-solid-svg-icons"
import IO_types_Sub_Card from './IO_types_Sub_Card'
import api_url from "../../helpers/config.js"
import { DeviceContext } from 'context/DeviceContext'
import translate from 'helpers/translations'

class IO_type_Head_Card extends Component {
  static contextType = DeviceContext

  state = {
    new: null
  }

  async getExportedCode() {
    const response = await fetch(api_url + 'code_generator', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        component_id: this.props.component_id,
      })
    })
  }

  toggleSubcard = (type_id) => {
    let subcards = this.props.subcards
    if (type_id in this.props.subcards) {
      subcards[type_id] = !subcards[type_id]
      if (this.state.new && this.state.new[this.props.item_type + '_type_id'] === type_id) {
        this.setState({ new: null })
      }
    } else {
      subcards[type_id] = true
    }
    this.props.setSubcards(this.props.item_type, subcards)
  }

  addNew = (io_type) => {
    this.setState({ new: io_type })
  }

  addIO = (new_name) => {
    if (new_name) {
      this.props.addIO(this.props.item_type, this.state.new, {name: new_name})
    }
    this.setState({ new: null })
  }

  render() {
    const { item_type, view_id, subcards } = this.props
    const io_types_attribute_type_sum = this.props[item_type + '_types_attribute_type_sum']

    let io_types
    if (view_id) {
      io_types = this.props['views_' + item_type + '_type_sum'].filter(io_type => io_type.view_id === view_id)
    } else {
      // if the component is a template render all types
      io_types = this.props.io_types
    }

    if(io_types.length === 0) {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{'Für diese Sicht sind keine ' + translate(item_type) + 'typen festgelegt'}</CardTitle>
          </CardHeader>
        </Card>
      )
    }

    return io_types.map((io_type_to_render, index) => {
      const io_type_name = view_id ? io_type_to_render[item_type + '_type_name'] : io_type_to_render.name
      const showSubcard = subcards[io_type_to_render[item_type + '_type_id']] || false
      const toggle_icon = showSubcard ? faAngleUp : faAngleDown
      const current_io_type_attribute_type_sum = io_types_attribute_type_sum.filter(item => item[item_type + '_type_id'] === io_type_to_render[item_type + '_type_id'])

      return (
        <Card key={index}>
          <CardHeader>
            <div style={{display : 'inline-block'}}>
              <CardTitle tag="h6">{translate(item_type) + 'typ'}</CardTitle>
              <h4>
                <button onClick={() => this.addNew(io_type_to_render)} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                  <FontAwesomeIcon icon={faPlus}/>
                </button>
                {io_type_name}
                <button onClick={() => this.toggleSubcard(io_type_to_render[item_type + '_type_id'])} className="btn-minimal btn-angle" data-toggle="tooltip" title={showSubcard ? "Alle einklappen" : "Alle ausklappen"}>
                  <FontAwesomeIcon icon={toggle_icon} />
                </button>
              </h4>
            </div>
            {item_type === 'output' && io_type_name === 'Dienstgarantie' ?
            <Button style={{float: "right"}} onClick={() => this.getExportedCode()}>Alle exportieren</Button> : null}
          </CardHeader>
          <CardBody>
            <IO_types_Sub_Card
              addAttribute={this.props.addAttribute}
              addConnection={this.props.addConnection}
              addInternalConnection={this.props.addInternalConnection}
              addIO={this.addIO}
              component_id={this.props.component_id}
              components={this.props.components}
              deleteAttribute={this.props.deleteAttribute}
              deleteConnection={this.props.deleteConnection}
              deleteInternalConnection={this.props.deleteInternalConnection}
              deleteIOs={this.props.deleteIOs}
              inputs={this.props.inputs}
              inputs_attribute_sum={this.props.inputs_attribute_sum}
              inputs_output_sum={this.props.inputs_output_sum}
              internal_connections_sum_with_types={this.props.internal_connections_sum_with_types}
              internals={this.props.internals}
              internals_attribute_sum={this.props.internals_attribute_sum}
              io_type_id={io_type_to_render[item_type + '_type_id']}
              io_type_name={io_type_name}
              item_type={item_type}
              new_io={this.state.new ? this.state.new[item_type + '_type_id'] === io_type_to_render[item_type + '_type_id'] : false}
              outputs={this.props.outputs}
              outputs_attribute_sum={this.props.outputs_attribute_sum}
              scrollToSubcard={this.props.scrollToSubcard}
              showSubcard={showSubcard}
              updateAttributes={this.props.updateAttributes}
              updateIO={this.props.updateIO}
              user_type_id={this.props.user_type_id}
              views_input_type_sum={this.props.views_input_type_sum}
              views_internal_type_sum={this.props.views_internal_type_sum}
              views_output_type_sum={this.props.views_output_type_sum}
            />
          </CardBody>
        </Card>
      )
    })
  }
}

export default IO_type_Head_Card