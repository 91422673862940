import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faBars } from '@fortawesome/free-solid-svg-icons'
import translate from 'helpers/translations'

import './Cytoscape_Card.css'

class Info_Bar extends Component {
  state = {
    minimized: false,
    resize: false,
    width: '400px',
    event_listener_controller: undefined,
    show_attribute_content: {}
  }

  componentDidMount() {
    const controller = new AbortController()
  
    document.addEventListener('mousedown', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('mouseup', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('mousemove', this.resizeHandler, {signal: controller.signal})
    document.addEventListener('dblclick', this.resizeHandler, {signal: controller.signal})

    this.setState({ event_listener_controller: controller })
  }

  componentWillUnmount() {
    this.state.event_listener_controller.abort()
  }

  resizeHandler = (event) => {
    if (event.type === 'mousedown' && event.target.matches('#info-bar-resize') && event.button === 0) {
      event.preventDefault()
      this.setState({ resize: true })
      document.body.style.cursor = 'col-resize'
    } else if (this.state.resize && event.type === 'mouseup') {
      this.setState({ resize: false })
      document.body.style.cursor = 'default'
    } else if (this.state.resize && event.type === 'mousemove') {
      const info_bar = document.getElementById('info-bar')
      const new_width = Math.min(Math.max(info_bar.getBoundingClientRect().right - event.clientX, 300), document.getElementById('cy-div').getBoundingClientRect().width/2)
      this.setState({ width: String(new_width) + 'px' })
    } else if (event.type === 'dblclick' && event.target.matches('#info-bar-resize')) {
      this.setState({ width: '400px' })
    }
  }

  graphLinkButton(id, label) {
    return <button onClick={() => this.props.selectElements([id])} className='btn-minimal graph-link'>{label}</button>
  }

  render() {
    const selected = this.props.selected.filter(element => !element.hasClass('new'))

    // nothing is selected
    if (selected.length === 0) {
      return <></>
    }

    // info bar is minimized
    if (this.state.minimized) {
      return (
        <div id='info-bar' className='minimized'>
          <button
            onClick={() => {this.setState({ minimized: false })}}
            id='btn-expand' className='btn-minimal btn-edit'
          ><h6><FontAwesomeIcon icon={faBars}/> Details</h6><FontAwesomeIcon className='icon-expand' icon={faAngleLeft} /></button>
        </div>
      )
    }

    let header = <></>
    let content = <></>

    // multiple elements are selected
    if (selected.length > 1) {
      header = <h3>Ausgewählt</h3>

      let parents = selected.parent()
     
      if (parents.length > 0) {
        content = 
          <>
            {parents.map((parent, parent_index) => {
              let component = parent.children('.components')[0]
              let component_id = component.data('id')
              let component_label = component.data('label')
              let children = parent.children('.inputs:selected, .outputs:selected')
              return (
                <div key={parent_index}>
                  <ul>
                    <li>
                      {this.graphLinkButton(component_id, component_label)}
                      <ul className='graph-link-list'>
                        {children.map((child, child_index) => {
                          let child_id = child.data('id')
                          let type = translate(child_id.split('-')[0])
                          let child_label = child.data('label')
                          return <li key={child_index}>{this.graphLinkButton(child_id, type + ': ' + child_label)}</li>
                        })}
                      </ul>
                    </li>
                  </ul>
                  {parent_index === parents.length-1 ? <></> : <hr/>}
                </div>
              )
            })}
          </>
     } else {
      content =
        <>
          <ul className='graph-link-list'>
            {selected.map((element, index) => {
              let id = element.data('id')
              let type = translate(id.split('-')[0])
              let label = element.group() === 'nodes' ? element.data('label') : ' von ' + element.source().data('label') + ' nach ' + element.target().data('label')
              return <li key={index}>{this.graphLinkButton(id, type + ': ' + label)}</li>
            })}
          </ul>
        </>
     }

    // one node is selected
    } else if (selected.length === 1 && selected[0].group() === 'nodes' && !selected[0].isParent()) {
      const node_id = selected[0].data('id')
      const item_type = node_id.split('-')[0]
      const id = Number(node_id.split('-')[1])
      const item = this.props[item_type + 's'].find(item => item[item_type + '_id'] === id)
      const name = item ? item.name : ''

      // the selected node is a component
      if (item_type === 'component') {
        let parameters = this.props.attributes.filter(attribute => attribute.component_id === id)
        let parameters_list = parameters.map((parameter, index) => {
          let parameter_content = <li>Inhalt: {parameter.content}</li>
          if (typeof parameter.content === 'object' && parameter.content !== null) {
            parameter_content = Object.entries(parameter.content).map(([key, value]) => {
              if (key !== 'Name') {
                return <li key={key}>{key + ': ' + value}</li>
              }
            })
          }
          const show_content = this.state.show_attribute_content[parameter.attribute_id]
          return (
            <li key={index}>
              {parameter.content.Name}
              <button className='btn-minimal btn-edit'
                onClick={() => {let show_attribute_content = this.state.show_attribute_content; show_attribute_content[parameter.attribute_id] = !show_attribute_content[parameter.attribute_id]; this.setState({ show_attribute_content })}}
                hidden={this.props.user_type_id === 0}
              ><FontAwesomeIcon icon={show_content ? faAngleUp : faAngleDown} /></button>
              <ul hidden={!show_content}>{parameter_content}</ul>
            </li>
          )
        })

        let io_list = ['inputs', 'outputs'].map(type => {
          let nodes = selected[0].siblings('.' + type)
          return nodes.map((node, index) => {
            let id = node.data('id')
            let type = translate(id.split('-')[0])
            let label = node.data('label')
            return <li key={index}>{this.graphLinkButton(id, type + ': ' + label)}</li>
          })
        })

        header =
          <>
            <h5>{selected[0].data('label').split('\n')[0]}</h5>
            <h3>{name}</h3>
          </>

        content =
          <>
            {parameters_list.length > 0 ?
              <><h5>Parameter:</h5>
              <ul>{parameters_list}</ul></>
              : <h5>Keine Parameter definiert</h5>}

            <hr />

            {io_list[0].length > 0 ?
              <><h5>Inputs:</h5>
              <ul className='graph-link-list'>{io_list[0]}</ul></>
              : <h5>Diese Komponente hat keine Inputs</h5>}

            <hr />

            {io_list[1].length > 0 ?
              <><h5>Outputs:</h5>
              <ul className='graph-link-list'>{io_list[1]}</ul></>
              : <h5>Diese Komponente hat keine Outputs</h5>}
          </>

      // the selected node is an input, output or internal
      } else if (item_type === 'input' || item_type === 'output' || item_type === 'internal') {
        let component_node = selected[0].siblings('.components')[0]
        if (this.props.graph_type === 'view' && !component_node) {
          return null
        }

        let attributes = this.props[item_type + 's_attribute_sum'].filter(attr => attr[item_type + '_id'] === id)

        let attributes_list = attributes.map((attribute, index) => {
          let attribute_content = <li>Inhalt: {attribute.content}</li>
          if (typeof attribute.content === 'object' && attribute.content !== null) {
            attribute_content = Object.entries(attribute.content).map(([key, value]) => {
              if (key !== 'Name') {
                return <li key={key}>{key + ': ' + value}</li>
              }
            })
          }
          const show_content = this.state.show_attribute_content[attribute.attribute_id]
          return (
            <li key={index}>
              {attribute.attribute_type_name}
              <button className='btn-minimal btn-edit'
                onClick={() => {let show_attribute_content = this.state.show_attribute_content; show_attribute_content[attribute.attribute_id] = !show_attribute_content[attribute.attribute_id]; this.setState({ show_attribute_content })}}
                hidden={this.props.user_type_id === 0}
              ><FontAwesomeIcon icon={show_content ? faAngleUp : faAngleDown} /></button>
              <ul hidden={!show_content}>{attribute_content}</ul>
            </li>
          )
        })

        let connections_list = []
        if (item_type === 'input' || item_type === 'output') {
          const other_type = item_type === 'input' ? 'output' : 'input'

          let connections = this.props.inputs_output ? this.props.inputs_output.filter(item => item[item_type + '_id'] === id) : []

          connections_list = connections.map((connection, index) => {
            let connected_io_id = connection[other_type + '_id']
            let component_id = this.props[other_type + 's'].find(item => item[other_type + '_id'] === connected_io_id).component_id
            let component = this.props.components.find(comp => comp.component_id === component_id)
            let component_name = component ? component.name : ''
            let io_name = this.props[other_type + 's'].find(item => item[other_type + '_id'] === connected_io_id).name
            if (this.props.graph_type === 'view') {
              return (
                <li key={index}>
                  {this.graphLinkButton('component-' + component_id, component_name)}{' '}
                  {this.graphLinkButton(other_type + '-' + connected_io_id, ' (' + translate(other_type) + ' ' + io_name + ')')}
                </li>
              )
            } else {
              return <li key={index}><Link to={'/admin/component_' + component_id}>{component_name}</Link>{' (' + translate(other_type) + ' ' + connection[other_type + '_name'] + ')'}</li>
            }
          })
        }

        let internal_connections_list = ['incomers', 'outgoers'].map(func => {
          return selected[0][func]('.inputs, .outputs, .internals').map((node, index) => {
            let id = node.data('id')
            let type = translate(id.split('-')[0])
            let label = node.data('label')
            return <li key={index}>{this.graphLinkButton(id, type + ': ' + label)}</li>
          })
        })

        header =
          <>
            <h5>{selected[0].data('label').split('\n')[0]}</h5>
            <h3>{name}</h3>
            {this.props.graph_type === 'view' ? this.graphLinkButton(component_node.data('id'), '(' + component_node.data('label').split('\n')[0] + ' ' + component_node.data('label').split('\n')[1] + ')') : ''}
          </>

        content =
          <>
            {attributes_list.length > 0 ?
              <><h5>Attribute:</h5>
              <ul>{attributes_list}</ul></>
              : <h5>Keine Attribute definiert</h5>}

            <hr />
            
            {connections_list.length > 0 ?
              <><h5>Verknüpfte Komponenten:</h5>
              <ul className='graph-link-list'>{connections_list}</ul></>
              : <h5>Keine verknüpften Komponenten</h5>}

            <hr />

            {internal_connections_list[0].length > 0 ?
              <><h5>Interne Verbindungen von:</h5>
              <ul className='graph-link-list'>{internal_connections_list[0]}</ul></>
              : <></>}

            {internal_connections_list[1].length > 0 ?
              <><h5>Interne Verbindungen nach:</h5>
              <ul className='graph-link-list'>{internal_connections_list[1]}</ul></>
              : <></>}

            {internal_connections_list.flat().length === 0 ?
              <h5>Keine internen Verbindungen</h5> : <></>}
          </>
      }

    // one single edge is selected
    } else if (selected.length > 0 && selected[0].group() === 'edges') {
      let source = selected[0].source()
      let target = selected[0].target()

      let source_btn = this.graphLinkButton(source.data('id'), source.data('label'))
      let target_btn = this.graphLinkButton(target.data('id'), target.data('label'))

      let source_component = source.siblings('.components')[0]
      let target_component = target.siblings('.components')[0]

      let source_component_btn = this.props.graph_type === 'view' ? this.graphLinkButton(source_component.data('id'), '(' + source_component.data('label') + ')') : <></>
      let target_component_btn = this.props.graph_type === 'view' ? this.graphLinkButton(target_component.data('id'), '(' + target_component.data('label') + ')') : <></>
      
      header = <h4>{this.props.graph_type === 'view' ? 'Verbindung' : 'Interne Verbindung'}</h4>

      content = 
        <>
          von <br />
          {source_btn} {source_component_btn}<br /><br />
          nach <br />
          {target_btn} {target_component_btn}
        </>
    }

    return (
      <div id='info-bar' style={{width: this.state.width}}>
        <div id='info-bar-resize'></div>
        <div id='info-bar-content-wrapper'>
          <div id='info-bar-content'>
            <button
              onClick={() => {this.setState({ minimized: !this.state.minimized })}}
              id='btn-minimize' className='btn-minimal btn-edit'
            ><h6><FontAwesomeIcon icon={faBars}/> Details</h6><FontAwesomeIcon icon={faAngleRight} /></button>
            {header}
            <hr/>
            {content}
          </div>
        </div>
      </div>
    )
  }
}

export default Info_Bar