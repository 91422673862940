/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import { fetchGetData, fetchGetUserData, fetchRemoveFrom, fetchAddNew, fetchAddTo, fetchRemove, fetchEdit } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'
import { checkTopicNaming } from "components/Extensions/Forms/helpers/CheckTopicNaming"
import { createComponentFromTemplate } from '../../helpers/CreateComponentFromTemplate.js'

// reactstrap components
import Header_Card from "./Header_Card"
import Graph_Card from "./Graph_Card"
import IO_types_Card from "./IO_types_Card"
import Components_Card from "./Components_Card"
import Inconsistent_connections_Card from "./Inconsistent_connections_Card"
import Datastream_Card from "./Datastream_Card"


class View extends React.Component {
  static contextType = DeviceContext

  state = {
    attributes: [],
    components: [],
    data_loaded: false,
    inputs: [],
    inputs_attribute_sum: [],
    input_types: [],
    inputs_output: [],
    internal_connections: [],
    internals: [],
    internal_types: [],
    outputs: [],
    outputs_attribute_sum: [],
    output_types: [],
    user: {},
    view: this.props.view,
    views: [],
    views_input_type_sum: [],
    views_internal_type_sum: [],
    views_output_type_sum: []
  }

  componentDidMount() {
    this.getItems()
  }

  async getItems() {
    const requestList = [
      { table: 'attributes', device: this.context.device},
      { table: 'components', device: {} },
      { table: 'inputs', device: this.context.device },
      { table: 'inputs_attribute_sum', device: this.context.device },
      { table: 'input_types', device: this.context.device },
      { table: 'inputs_output', device: this.context.device },
      { table: 'internal_connections', device: this.context.device },
      { table: 'internals', device: this.context.device },
      { table: 'internal_types', device: this.context.device },
      { table: 'outputs', device: this.context.device },
      { table: 'outputs_attribute_sum', device: this.context.device },
      { table: 'output_types', device: this.context.device },
      { table: 'views', device: this.context.device },
      { table: 'views_input_type_sum', device: this.context.device },
      { table: 'views_internal_type_sum', device: this.context.device },
      { table: 'views_output_type_sum', device: this.context.device }
    ]

    try {
      await Promise.all(requestList.map(async request => {
        const data = await fetchGetData(request.device, request.table)
        if (data.dataExists !== 'false') {
          this.setState({ [request.table]: data })
        }
      }))
      this.setState({ data_loaded: true })

      const user = await fetchGetUserData()
      this.setState({ user })
    } catch (err) {
      console.log(err)
    }
  }

  // creates an input/output/internal and adds it to the state
  addIO = async (item_type, io_type, attributes) => {
    let attributes_db = {
      name: attributes.name,
      [item_type + '_type_id']: io_type[item_type + '_type_id'],
      component_id: Number(attributes.component_id),
    }

    const item = await fetchAddNew(this.context.device, item_type + 's', attributes_db)
    if (item.dataExists !== 'false') {

      // save position of the new node
      let view = this.state.view
      if (attributes.position) {
        let graph_json = view.graph ? view.graph : {elements: {edges: [], nodes: []}}
        graph_json.elements.nodes.push({
          classes: item_type + 's',
          data: {id: item_type + '-' + item[0][item_type + '_id']},
          position: attributes.position
        })
        await fetchEdit('views', undefined, this.props.view.view_id, {graph: graph_json})
        view.graph = graph_json
      }

      this.setState({ view, [item_type + 's']: this.state[item_type + 's'].concat(item) })
    }
  }

  // adds a view_input_type/view_output_type/view_internal_type to the state
  addViewIOTypeToState = (item_type, view_io_type) => {
    let io_type_to_add = this.state[item_type + 's'].find(io_type => io_type[item_type + '_id'] === view_io_type[item_type + '_id'])
    view_io_type[item_type + '_name'] = io_type_to_add['name']
    this.setState({ ['views_' + item_type + '_sum']: this.state['views_' + item_type + '_sum'].concat([view_io_type]) })
  }

  // creates a component and adds it to the state
  createComponent = async (attributes) => {
    let attributes_db = {
      name: attributes.name,
      view_id: this.props.view.view_id
    }
    const item = await fetchAddNew(this.context.device, 'components', attributes_db)
    if (item.dataExists !== 'false') {
      let component = {...item[0]}
      component.devices = [this.context.device]
      // add component to current user
      await fetchAddTo(this.context.device, component.component_id, 'component', this.state.user.id, 'user', 'components_user')

      // save position of the new node
      let view = this.state.view
      if (attributes.position) {
        let graph_json = view.graph ? view.graph : {elements: {edges: [], nodes: []}}
        graph_json.elements.nodes.push({
          classes: 'components',
          data: {id: 'component-' + component.component_id},
          position: attributes.position
        })
        await fetchEdit('views', undefined, this.props.view.view_id, {graph: graph_json})
        view.graph = graph_json
      }

      // copy internal structure from template
      if (attributes.template_id && attributes.template_id > 0) {
        await createComponentFromTemplate(this.context.device, attributes.template_id, component.component_id, this.props.view.view_id)
        const inputs = await fetchGetData(this.context.device, 'inputs')
        const outputs = await fetchGetData(this.context.device, 'outputs')
        this.setState({ components: this.state.components.concat([component]), inputs, outputs })
      } else {
        this.setState({ view, components: this.state.components.concat([component]) })
      }

      this.props.addRoute('component', component)
    }
  }

  createConnection = async (output_id, input_id) => {
    // currently not needed because ros_views are disabled, if reactivated, check if everything works
    /*const output_component_id = this.state.outputs.find(output => output.output_id === output_id)
    const input_component_id = this.state.inputs.find(input => input.input_id === input_id)
    const output_view_id = this.state.components.find(comp => comp.component_id === output_component_id)
    const input_view_id = this.state.components.find(comp => comp.component_id === input_component_id)
    const output_is_ros = this.state.views.find(view => view.view_id === output_view_id).ros2_view
    const input_is_ros = this.state.views.find(view => view.view_id === input_view_id).ros2_view*/
    let confirm = true
    /*if (input_is_ros && output_is_ros) {
      const inputs_attribute_sum = await fetchGetData('inputs_attribute_sum', this.context.device)
      const outputs_attribute_sum = await fetchGetData('outputs_attribute_sum', this.context.device)
      confirm = checkTopicNaming(input_id, output_id, this.state.inputs, inputs_attribute_sum, this.state.outputs, outputs_attribute_sum)
    }*/
    if (confirm) {
      fetchAddTo(this.context.device, input_id, 'input', output_id, 'output', 'inputs_output')
      .then(item => {
        this.setState({ inputs_output: this.state.inputs_output.concat(item) })
      })
      .catch(err => console.log(err))
    }
  }

  // removes a component from the state
  deleteComponentFromState = (component_id) => {
    this.setState({ components: this.state.components.filter(item => item.component_id !== component_id) })
    this.props.removeRoute('component', component_id)
  }

   // removes one or multiple items (components, inputs/outputs/internals, inputs_output) from the DB and the state
   deleteItems = (items, forced = false) => {
    let state = this.state
    if (forced || window.confirm('Löschen?')) {
      items.forEach(item => {
        const { item_type, id } = item
        if (item_type === 'component') {
          fetchRemove(item_type + 's', id)
          .catch(err => console.log(err))
          this.props.removeRoute('component', id)
          state['components'] = state['components'].filter(item => item['component_id'] !== id)
        } else if (item_type === 'inputs_output') {
          fetchRemoveFrom(item_type, id)
          .catch(err => console.log(err))
          state['inputs_output'] = state['inputs_output'].filter(item => item['inputs_output_id'] !== id)
        } else if (item_type === 'input' || item_type === 'output' || item_type === 'internal') {
          fetchRemove(item_type + 's', id)
          .catch(err => console.log(err))
    
          // also delete the internal connections from and to the input/internal/output
          let internal_connections = this.state.internal_connections.filter(item => item['origin_' + item_type + '_id'] === id || item['destination_' + item_type + '_id'] === id)
          internal_connections.forEach(internal_connection => {
            fetchRemoveFrom('internal_connections', internal_connection['internal_connections_id'])
            .catch(err => console.log(err))
          })
    
          // also delete the connections from and to the input/output
          let connections = this.state.inputs_output.filter(item => item[item_type + '_id'] === id)
          connections.forEach(connection => {
            fetchRemoveFrom('inputs_output', connection['inputs_output_id'])
            .catch(err => console.log(err))
          })
  
          state[item_type + 's'] = state[item_type + 's'].filter(item => item[item_type + '_id'] !== id)
          state.internal_connections = state.internal_connections.filter(item => item['origin_' + item_type + '_id'] !== id && item['destination_' + item_type + '_id'] !== id)
          state.inputs_output = state.inputs_output.filter(item => item[item_type + '_id'] !== id)  
        }
      })
    }

    this.setState(state)
    this.props.refreshRoutes()
  }

  deleteViewIOTypeFromState = (item_type, id) => {
    const updated_items = this.state['views_' + item_type + '_sum'].filter(item => item['views_' + this.props.item_type + '_id'] !== id)
    this.setState({ ['views_' + item_type + '_sum']: updated_items })
  }

  // restores one or multiple items (components, input, output or inputs_output)
  restoreItems = async (items) => {
    try {
      let state = this.state
      let new_routes = []
      await Promise.all(items.map(async item => {
        const { item_type, id } = item
        let table_name = item_type + 's'
        let id_string = item_type + '_id'
        if (item_type === 'inputs_output') {
          table_name = 'inputs_output'
        } else if (item_type === 'internal_connection') {
          id_string = 'internal_connections_id'
        }
        let edited_item = await fetchEdit(table_name, id_string, id, {deleted: false})
        if (edited_item.dataExists !== 'false') {
          if (item_type === 'component') {
            // ensure components have the current device
            edited_item[0].devices = [this.context.device]
            new_routes.push(edited_item[0])
          }
          state[table_name] = state[table_name].concat(edited_item)
        }
      }))
      this.setState(state)
      new_routes.forEach(item => {
        this.props.addRoute('component', item)
      })
      this.props.refreshRoutes()
    } catch (err) {
      console.log(err)
    }
  }

  // updates an item in the state
  updateState = (item_type, updated_item) => {
    let items = this.state[item_type + 's']
    const index = items.findIndex(item => item[item_type + '_id'] === updated_item[item_type + '_id'])
    items[index].name = updated_item.name
    items[index].description = updated_item.description
    this.setState({ [item_type + 's']: items })
    if (item_type === 'component') {
      this.props.updateRoute('component', updated_item.component_id, updated_item.name)
    } else {
      this.props.refreshRoutes()
    }
  }

  updateView = (updated_item) => {
    this.setState({ view: updated_item })
    this.props.updateRoute('view', updated_item.view_id, updated_item.name)
  }

  render() {
    return (
      <div className="content">
        <Header_Card view={this.state.view} updateState={this.updateView} user_type_id={this.props.user_type_id}/>
        <Graph_Card
          addIO={this.addIO}
          all_components={this.state.components}
          attributes={this.state.attributes}
          context={this.context}
          createComponent={this.createComponent}
          createConnection={this.createConnection}
          components={this.state.components}
          data_loaded={this.state.data_loaded}
          deleteItems={this.deleteItems}
          input_types={this.state.input_types}
          inputs={this.state.inputs}
          inputs_attribute_sum={this.state.inputs_attribute_sum}
          inputs_output={this.state.inputs_output}
          internal_connections={this.state.internal_connections}
          internals={this.state.internals}
          output_types={this.state.output_types}
          outputs={this.state.outputs}
          outputs_attribute_sum={this.state.outputs_attribute_sum}
          restoreItems={this.restoreItems}
          updateState={this.updateState}
          updateView={this.updateView}
          user_type_id={this.props.user_type_id}
          view={this.state.view}
          views_input_type_sum={this.state.views_input_type_sum}
          views_output_type_sum={this.state.views_output_type_sum}
          view_components={this.state.components.filter(component => component.view_id === this.props.view.view_id)}
        />
        <Inconsistent_connections_Card view_id={this.props.view.view_id}/>
        {['internal_type', 'input_type', 'output_type'].map((item_type, index) => {
          return  <IO_types_Card
                    key={index}
                    addViewIOTypeToState={(item) => this.addViewIOTypeToState(item_type, item)}
                    deleteViewIOTypeFromState={(id) => this.deleteViewIOTypeFromState(item_type, id)}
                    item_type={item_type}
                    io_types={this.state[item_type + 's']}
                    views_io_type={this.state['views_' + item_type + '_sum']}
                    view_id={this.props.view.view_id}
                    user_type_id={this.props.user_type_id}
                  />
        })}
        <Components_Card
          createComponent={this.createComponent}
          components={this.state.components}
          deleteComponent={this.deleteComponentFromState}
          updateState={(item) => this.updateState('component', item)}
          user_type_id={this.props.user_type_id}
          view_id={this.props.view.view_id}
        />
      </div>
    )
  }
}

export default View
