import React, { Component } from 'react'
import { CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faAngleDown, faAngleUp, faPlus, faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { fetchRemoveFrom, fetchAddInternalConnection } from "helpers/api"
import { DeviceContext } from 'context/DeviceContext'
import MultiColumnSelectInput from './Inputs/MultiColumnSelectInput'

class DataTableInternalConnections extends Component {
  static contextType = DeviceContext
  
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      possible_connections: [],
      sort: {column: '', reverse: false},
      edit: {}
    }
    this.headerRef = React.createRef()
  }

  sort = (column) => {
    let sortedItems = this.state.items? this.state.items : this.props.internal_connections
    // sort items by given column
    sortedItems.sort((row1, row2) => {
      if (!row1[column]) {
        return 1
      } else if (!row2[column]) {
        return -1
      } else {
        return row1[column].toString().localeCompare(row2[column].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
      }
    })
    // if function is called on the same column as last time, reverse the array
    let reverse = false
    if (column === this.state.sort.column) {
      reverse = !this.state.sort.reverse
    }
    if (reverse) {
      sortedItems.reverse()
    }
    this.setState({ sort: {column: column, reverse: reverse}, items: sortedItems })
  }


  onChange = (id) => {
    let item = this.state.possible_connections.find(item => item['connection_id'] === id)
    this.setState({ edit: {new: true, item: item}})
  }

  submitRow = () => {
    let confirm = true
    if (this.props.parent_type === 'input') {
      if (this.props.internal_connections.find(item => item['origin_input_id'] === this.props.parent_id)) {
        confirm = window.confirm("Dieser Input hat bereits einen Callback. Fortfahren?")
      }
    }
    if (confirm) {
      const new_item = this.state.edit.item
      fetchAddInternalConnection(this.context.device, this.props.parent_type, this.props.parent_id, new_item.type, new_item[new_item.type + '_id'], this.props.component_id)
      .then(item => {
        this.setState({ edit: {} })
        this.props.addItemToState(item[0])
      })
      .catch(err => console.log(err))
    } else {
      this.setState({ edit: {} })
    }
  }

  createTable() {
    // create item list that is content of the table
    let items = this.props.internal_connections.map(item => {
      // search for the entry which defines the id
      let id = 0
      Object.entries(item).map(entry => {
        if (entry[0] === 'internal_connections_id') {
          id = entry[1]
        }
      })
      // create item that contains all necessary information
      if (this.props.parent_id == item['origin_' + this.props.parent_type + '_id']) {
        return {'id': id,
                'Richtung': "Verbindung nach",
                'Typ': item['destination_' + item['destination_type'] + '_type_name'],
                'Name': item['destination_' + item['destination_type'] + '_name']}
      } else if (this.props.parent_id == item['destination_' + this.props.parent_type + '_id']) {
        return {'id': id,
                'Richtung': "Verbindung von",
                'Typ': item['origin_' + item['origin_type'] + '_type_name'],
                'Name': item['origin_' + item['origin_type'] + '_name']}
      } else {
        return {'id': id, 'Richtung': "Fehler!", 'Typ': "Fehler!", 'Name': "Fehler!"}
      }
    })
    this.setState({ items })
    // create list with possible connections
    let possible_connections = []
    this.props.possible_internal_connections.forEach((item, index) => {
      const item_type = Object.keys(item)[0].split('_')[0]
      const io_type = this.props['views_' + item_type + '_type_sum'].find(io_type => io_type[item_type + '_type_id'] === item[item_type + '_type_id'])
      const io_type_name = io_type ? io_type[item_type + '_type_name'] : '-'
      const destination_ids = this.props.internal_connections.map(item => item['destination_' + item_type + '_id'])
      if (!destination_ids.includes(item[item_type + '_id'])) {
        possible_connections.push({
          'connection_id': index,
          'type': item_type,
          [item_type + '_id']: item[item_type + '_id'],
          'Richtung': 'Verbindung nach',
          'Typ': io_type_name,
          'Name': item.name
        })
      }
    })
    this.setState({ possible_connections })
  }

  componentDidMount() {
    this.createTable()
    window.addEventListener('resize', this.rerender)
  }

  componentDidUpdate(prevProps) {
    if (this.props.internal_connections !== prevProps.internal_connections) {
      this.createTable()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.rerender)
  }

  // used to update the widths of the MultiColumnSelectInput
  rerender = () => {
    this.setState(this.state)
  }

  render() {
    const columns = ['Richtung', 'Typ', 'Name']
    // populate the table
    let rows = this.state.items.map(item => {
      let entry = columns.map((column, index) => {
        return <td scope="row" key={index}>{item[column]}</td>
      })
      if (this.props.user_type_id !== 0) {
        entry.push(<td key={'delete_btn'} style={{width: '92px', textAlign: 'center'}}><button onClick={() => this.props.deleteItem(item.id)} className="btn-minimal btn-trash"><FontAwesomeIcon icon={faTrash} /></button></td>)
      }
      return <tr key={item.id}>{entry}</tr>
    })

    if (this.state.edit.new) {
      // get column widths
      let col_widths = []
      Object.entries(this.headerRef.current.children).forEach(([key, entry]) => {
        if (key < columns.length) {
          col_widths.push(entry.clientWidth)
        }
      })
      // decrease width of last column because of padding
      col_widths[col_widths.length-1] -= 17
      const error_style = {borderColor: 'red', boxShadow: '0 0 5px red'} // error style of input field
      rows.unshift(
        <tr key={'new_row'}>
          <td scope="row" colSpan={columns.length} style={{paddingLeft: 4, paddingRight: 4}}>
            <MultiColumnSelectInput
              name={'internal_connection'}
              items={this.state.possible_connections}
              columns={columns}
              col_widths={col_widths}
              item_type={'connection'}
              autoFocus={true}
              onChange={this.onChange}
              style={this.state.edit.error ? error_style : {}}
            />
          </td>
          <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
            <button onClick={() => this.submitRow()} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
            <button onClick={() => this.setState({ edit: {} })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
          </td>
        </tr>
      )
    }

    // create table header
    let header = columns.map((column, index) => {
      if (columns[index] === this.state.sort.column) {
        let symbol = this.state.sort.reverse? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />
        return (
          <th key={index}><button style={{all: 'inherit', cursor: 'pointer'}} onClick={() => this.sort(column)}>{column} {symbol}</button></th>
        )
      } else {
        return (
          <th key={index}><button style={{all: 'inherit', cursor: 'pointer'}} onClick={() => this.sort(column)}>{column}</button></th>
        )
      }
    })
    if (this.props.user_type_id !== 0) {
      header.push(<th key={'delete'}>Löschen</th>)
    }

    return (
      <>
        <CardTitle tag='h6'>
          {this.props.parent_type !== 'output' ? 
            <button onClick={() => this.setState({ edit: {new: true, item: {}} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
              <FontAwesomeIcon icon={faPlus}/>
            </button> : null
          }
          Interne Verbindungen
        </CardTitle>
        {/* height = 0 is a workaround to ensure the headerRef is set without showing the table even when it is empty */}
        <div style={{ maxHeight: this.props.maxHeight, overflowY: 'auto', height: rows.length === 0 ? '0px' : '' }}>
          <Table className="dataTableDeleteAndEdit">
            <thead className="text-primary"><tr ref={this.headerRef}>{header}</tr></thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      </>
    )
  }
}
export default DataTableInternalConnections