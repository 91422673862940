import React, { Component } from 'react'
import { Input, CardTitle } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPlus, faPen, faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import DataTableInternalConnections from 'components/Extensions/Tables/DataTableInternalConnections'
import DataTableAttributes from 'components/Extensions/Tables/DataTableAttributes'
import { DeviceContext } from 'context/DeviceContext'
import { fetchEdit } from 'helpers/api.js'
import translate from 'helpers/translations'
import trafficLight from '../../assets/img/traffic-light.svg'

class IO_types_Sub_Card extends Component {
  static contextType = DeviceContext

  state = {
    edit: {name: ''}
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ edit: {id: Number(name), name: value} })
  }

  submitChange = () => {
    // show an error if no name is set
    if (!this.state.edit['name']) {
      let edit_state = this.state.edit
      edit_state.error = true
      this.setState({ edit: edit_state })
    // case for adding a new IO
    } else if (!this.state.edit['id']) {
      this.props.addIO(this.state.edit['name'])
      this.setState({ edit: {name: ''} })
    // case for editing
    } else {
      let edit_item = {name: this.state.edit['name'].trim()}
      fetchEdit(this.props.item_type + 's', undefined, this.state.edit['id'], edit_item)
      .then(item => {
        this.setState({ edit: {} })
        if (Array.isArray(item)) this.props.updateIO(this.props.item_type, item[0])
      })
      .catch(err => console.log(err))
    }
  }

  abortChange = (id) => {
    if (id === 'new') {
      this.props.addIO(null)
    }
    this.setState({ edit: {name: ''} })
  }

  render() {
    const { item_type, component_id, components, inputs, internals, outputs,
            inputs_output_sum, inputs_attribute_sum, outputs_attribute_sum, internal_connections_sum_with_types, new_io, showSubcard } = this.props
    
    let ios_to_render = this.props[item_type + 's'].filter(io =>
      io[item_type + '_type_id'] === this.props.io_type_id && io.component_id === component_id
    )
    if (new_io) ios_to_render = [{[item_type + '_id']: 'new'}].concat(ios_to_render)

    if (showSubcard && ios_to_render.length === 0) {
      // set a default text if no entries in DB
      return (
        <div style={{padding: 20, border: '1px solid black' }}>
          <p>{'Für diesen ' + translate(item_type) + 'typ ist kein ' + translate(item_type) + ' definiert'}</p>
          <div style={{color: 'dimgray'}}>Zum Hinzufügen auf + klicken</div>
        </div>
      )
    }

    return ios_to_render.map((io_to_render, index) => {
      // get all possible destinations for internal connections
      let possible_internal_connections = []
      if (item_type === 'input') {
        possible_internal_connections = internals.filter(item => item.component_id === component_id)
                                          .concat(outputs.filter(item => item.component_id === component_id))
      } else if (item_type === 'internal') {
        possible_internal_connections = internals.filter(item => item.component_id === component_id)
                                          .concat(outputs.filter(item => item.component_id === component_id))
                                          .concat(inputs.filter(item => item.component_id === component_id))
      }

      const connection_type = item_type === 'input' ? 'output' : 'input'
      const current_component = components.find(component => component.component_id === component_id)

      const attributes_to_render = this.props[item_type + 's_attribute_sum'].filter(io => io[item_type + '_id'] === io_to_render[item_type + '_id'])

      // get connected ios and components and those that can be connected
      let connections = inputs_output_sum.filter(connection => connection[item_type + '_id'] === io_to_render[item_type + '_id'])
      let connected_ios = []
      let ios_to_connect = []
      this.props[connection_type + 's'].forEach(item => {
        const component = components.find(comp => comp.component_id === item.component_id)
        if (component) {
          item.component_name = component.name
        }
        let connection = connections.find(connection => connection[connection_type + '_id'] === item[connection_type + '_id'])
        if (connection) {
          item['inputs_output_id'] = connection['inputs_output_id']
          connected_ios.push(item)
        } else if (!item.is_template && item['component_id'] !== component_id) {
          ios_to_connect.push(item)
        }
      })

      // get internal_connections
      const internal_connections_to_render = internal_connections_sum_with_types.filter(internal_connection =>
        internal_connection['origin_' + item_type + '_id'] === io_to_render[item_type + '_id'] || internal_connection['destination_' + item_type + '_id'] === io_to_render[item_type + '_id'])

      const error_style = this.state.edit.error ? {borderColor: 'red', boxShadow: '0 0 5px red'} : {} // style of the input field when it is empty
      return (
        <div id={this.props.item_type + '-' + io_to_render[item_type + '_id']} style={{ margin: '5px' }} key={index} >
          <div style={{padding: 20, border: '1px solid black' }} key={index}>
            {/* subcard header */}
            <div className='d-flex' style={{ display: 'inline-block' }}>
              {this.state.edit['id'] === io_to_render[item_type + '_id'] || io_to_render[item_type + '_id'] === 'new' ?
                <>
                  <Input
                    type="text"
                    name={io_to_render[item_type + '_id']}
                    autoFocus={true}
                    onChange={this.onChange}
                    value={this.state.edit['name'] || ''}
                    placeholder='Name'
                    style={{...{width: Math.max(70, this.state.edit['name'].length * 10), padding: '5px'}, ...error_style}}
                  />
                  <button onClick={() => this.submitChange()} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
                  <button onClick={() => this.abortChange(io_to_render[item_type + '_id'])} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
                </> : 
                <> {<button key={io_to_render[item_type + '_id']} onClick={() => this.props.scrollToSubcard(item_type, io_to_render[item_type + '_id'])} style={{padding: 0}} className="btn-minimal" data-toggle="tooltip" title={showSubcard ? "" : "Zeige Details"}>{io_to_render.name}</button>}
                  <button onClick={() => this.setState({ edit: {id: io_to_render[item_type + '_id'], name: io_to_render['name']} })} className='btn-minimal btn-edit' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPen} /></button>
                  <button onClick={() => this.props.deleteIOs([{item_type: item_type, id: io_to_render[item_type + '_id']}])} className="btn-minimal btn-trash" hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faTrash} /></button>
                </>
              }
            </div>
            {showSubcard && io_to_render[item_type + '_id'] !== 'new' ?
            <>
              {/* attributes */}
              <br />
              {this.props.io_type_name === 'Service Assessment' ? <div style={{paddingRight: 20, float: 'left'}}><img src={trafficLight} style={{height: 180}}/></div> : null}
              {attributes_to_render.length > 0 || this.state.new_attribute ?
                <DataTableAttributes
                  items={attributes_to_render}
                  addItemToState={(new_attribute) => this.props.addAttribute(item_type, io_to_render, new_attribute)}
                  deleteItemFromState={(id) => this.props.deleteAttribute(item_type, id)}
                  updateState={(item) => this.props.updateAttributes(item_type, item)}
                  parent_type={item_type}
                  io_type_id={this.props.io_type_id}
                  io_id={io_to_render[item_type + '_id']}
                  withEdit={!(this.props.user_type_id === 0)}
                  user_type_id={this.props.user_type_id}
                /> : <CardTitle><button onClick={() => this.setState({ new_attribute: true })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPlus} /></button>Attribute<br /></CardTitle>
              }
              {/* connections */}
              {item_type !== 'internal' && current_component && !current_component.is_template ?
                <>
                  <br />
                  <DataTableDeleteAndEdit
                    title={'Verknüpfte Komponenten'}
                    title_size={'h6'}
                    items={connected_ios}
                    addItemToState={this.props.addConnection}
                    deleteChildItemFromState={this.props.deleteConnection}
                    columns={[translate(connection_type), 'Komponente']}
                    columns_DB={['name', 'component_name']}
                    select={{['name']: {items: ios_to_connect, col_width: 2, columns: ['name', 'component_name']}}}
                    item_type={'inputs_output'}
                    parent_type={item_type}
                    parent_id={io_to_render[item_type + '_id']}
                    child_type={connection_type}
                    links={{['component_name']: {'id': ['component_id'], 'type': 'component'}}}
                    inverted_table_name={item_type === 'output'}
                    inputs_attribute_sum={inputs_attribute_sum}
                    inputs={this.props.inputs}
                    outputs_attribute_sum={outputs_attribute_sum}
                    outputs={this.props.outputs}
                    withDelete={!(this.props.user_type_id === 0)}
                    addTo={true}
                    deleteFrom={true}
                  />
                </> : null
              }
              {/* internal connections */}
              <br />
              <DataTableInternalConnections
                internal_connections={internal_connections_to_render}
                possible_internal_connections={possible_internal_connections}
                addItemToState={this.props.addInternalConnection}
                deleteItem={this.props.deleteInternalConnection}
                parent_type={item_type}
                parent_id={io_to_render[item_type + '_id']}
                component_id={component_id}
                user_type_id={this.props.user_type_id}
                views_input_type_sum={this.props.views_input_type_sum}
                views_internal_type_sum={this.props.views_internal_type_sum}
                views_output_type_sum={this.props.views_output_type_sum}
              />
            </> : null}
          </div>
        </div>
      )
    })
  }
}
export default IO_types_Sub_Card