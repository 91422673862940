import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input, CardBody, Card, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faX, faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData, fetchEdit } from 'helpers/api.js'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    components: [],
    attributes: [],
    edit: {}
  }

  getItems() {
    fetchGetData({}, 'components')
    .then(components => {
      if (components.dataExists !== 'false') {
        this.setState({ components })
      }
    })
    .catch(err => console.log(err))

    fetchGetData({}, 'attributes')
    .then(attributes => {
      if (attributes.dataExists !== 'false') {
        this.setState({ attributes })
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  onChange = (e) => {
    const { type, name, value } = e.target
    let edit_state = this.state.edit
    if (type === 'text') {
      edit_state.content[name] = value
    } else if (type === 'select-one') {
      edit_state[name] = Number(value)
    }

    if (edit_state['component_id']) edit_state.error = false
    this.setState({ edit: edit_state })
  }

  addComponent = () => {
    let edit_state = this.state.edit
    if (!edit_state['component_id']) {
      edit_state.error = true
      this.setState({ edit: edit_state })
    } else {
      let attribute_values = {
        components: {connect: {component_id: edit_state['component_id']}}
      }
      fetchEdit('degradation_rules', undefined, this.props.degradation_rule_id, attribute_values)
      .then(item => {
        let components = this.state.components
        if (item.dataExists !== 'false') {
          let index = components.findIndex(component => component['component_id'] === edit_state['component_id'])
          components[index]['degradation_rules'].push(item[0])
        }
        this.setState({ components, edit: {} })
      })
      .catch(err => console.log(err))
    }
  }

  deleteComponent = (id) => {
    let confirm_delete = window.confirm('Komponente entfernen?')
      if (confirm_delete) {
        let attribute_values = {
          components: {disconnect: {component_id: id}}
        }
        fetchEdit('degradation_rules', undefined, this.props.degradation_rule_id, attribute_values)
        .then(item => {
          let components = this.state.components
          let index = components.findIndex(component => component['component_id'] === id)
          components[index]['degradation_rules'] = components[index]['degradation_rules'].filter(rule => rule['degradation_rule_id'] !== this.props.degradation_rule_id)
          this.setState({ components })
        })
        .catch(err => console.log(err))
      }
  }

  submitEditRow = () => {
    let edit_state = this.state.edit
    fetchEdit('attributes', undefined, edit_state.id, {content: edit_state.content})
    let attributes = this.state.attributes
    attributes.find(attribute => attribute['attribute_id'] === edit_state.id).content = edit_state.content
    this.setState({ edit: {}, attributes })
  }

  render() {
    const columns = ['Komponente', 'Parameter', 'Beschreibung', 'Wertebereich', 'Normalwert', 'Degradierter Wert']

    let components_to_render = []
    let components_to_connect = []
    this.state.components.forEach(component => {
      const rule_ids = component.degradation_rules.map(rule => rule['degradation_rule_id'])
      if (rule_ids.includes(this.props.degradation_rule_id)) components_to_render.push(component)
      else components_to_connect.push(component)
    })

    let rows = []

    // new row
    const error_style = {borderColor: 'red', boxShadow: '0 0 5px red'} // error style of input field
    if (this.state.edit.new) {
      let options = components_to_connect.map((component, index) => {
        return (
          <option value={component['component_id']} key={index}>
            {component['name']}
          </option>
        )
      })
      rows.push(
        <tr key={'new'}>
          <td key={'component_select'}>
            <Input
              type={'select'}
              name={'component_id'}
              onChange={this.onChange}
              style={this.state.edit.error ? error_style : {}}
            >
              {options.length > 0 ? <option>Komponente auswählen</option> : <option>Keine Komponenten verfügbar</option>}
              {options}
            </Input>
          </td>
          <td key={1}></td><td key={2}></td><td key={3}></td><td key={4}></td><td key={5}></td>
          <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
            <button onClick={() => this.addComponent()} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
            <button onClick={() => this.setState({ edit: {} })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
          </td>
        </tr>
      )
    }

    // content
    components_to_render.forEach((component, index) => {
      let delete_btn = 
        <button onClick={() => this.deleteComponent(component['component_id'])} className='btn-minimal btn-trash' hidden={this.props.user_type_id === 0}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      const link = "/admin/component_" + component['component_id']
      rows.push(
        <tr key={index}>
          <th key={'Komponente'}><Link style={{display:'block', fontWeight: 'bold', fontSize: '1.1em'}} to={link}>{component['name']}</Link></th>
          <td key={1}></td><td key={2}></td><td key={3}></td><td key={4}></td><td key={5}></td>
          <td key={'edit_btn'} style={{width: '92px', textAlign: 'center'}}>{delete_btn}</td>
        </tr>
      )

      let parameters = this.state.attributes.filter(attribute => attribute['component_id'] === component['component_id'])
      if (parameters.length === 0) {
        rows.push(
          <tr key={index + 'empty'}>
            <td colSpan={columns.length} style={{textAlign: 'center'}}>Diese Komponente hat keine Parameter</td>
          </tr>
        )
      } else {
        parameters.forEach(parameter => {
          parameter.content['Parameter'] = parameter.content['Name']
          let entry = columns.map(column => {
            if (this.state.edit.id === parameter['attribute_id'] && column === 'Degradierter Wert') {
              return (
                <td key={column}>
                  <Input
                    type="text"
                    name={'rule_' + this.props.degradation_rule_id + '_degraded'}
                    autoFocus={true}
                    onChange={this.onChange}
                    value={this.state.edit.content['rule_' + this.props.degradation_rule_id + '_degraded'] || ''}
                    placeholder={column}
                    style={{padding: '5px'}}
                  />
                </td>
              )
            } else if (column === 'Degradierter Wert') {
              return <td key={column}>{parameter.content['rule_' + this.props.degradation_rule_id + '_degraded']}</td>
            } else {
              return <td key={column}>{parameter.content[column]}</td>
            }
          })
          let edit_btn = 
            <button onClick={() => this.setState({ edit: {id: parameter['attribute_id'], content: parameter.content} })} className="btn-minimal btn-trash" hidden={this.props.user_type_id === 0}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          if (this.state.edit.id === parameter['attribute_id'] && this.props.user_type_id !== 0) {
            entry.push(
              <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
                <button onClick={() => this.submitEditRow()} className='btn-minimal'><FontAwesomeIcon icon={faCheck} /></button>
                <button onClick={() => this.setState({ edit: {} })} className='btn-minimal'><FontAwesomeIcon icon={faX} /></button>
              </td>
            )
          } else if (this.props.user_type_id !== 0) {
            entry.push(<td key={'edit_btn'} style={{width: '92px', textAlign: 'center'}}>{edit_btn}</td>)
          }
          rows.push(<tr key={index + parameter['attribute_id']}>{entry}</tr>)
        })
      }
      rows.push(<tr key={'empty_row' + index}><td colSpan={columns.length+1} style={{padding: 1}}></td></tr>)
    })

    // table header
    let header = []
    header = columns.map((column, index) => <th key={index}>{column}</th>)
    if (this.props.user_type_id !== 0) {
      header.push(<th key={'edit'} style={{width: '80px'}}>Bearbeiten</th>)
    }

    return (
      <Card>
        <CardBody>
          <CardTitle tag='h4'>
            <button onClick={() => this.setState({ edit:  {new: true, item: {}} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
              <FontAwesomeIcon icon={faPlus}/>
            </button>
            Degradierte Komponenten
          </CardTitle>
          <Table className="dataTableDeleteAndEdit" style={{fontSize: '0.8em'}}>
            <thead className="text-primary"><tr>{header}</tr></thead>
            <tbody>{rows}</tbody>
          </Table>
        </CardBody>
      </Card>
    )
  }
}

export default Components_Card