import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetData } from 'helpers/api'

class Devices_Card extends Component {
  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, "devices")
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (new_item) => {
    this.setState({ items: this.state.items.concat([new_item]) })
    this.props.refreshRoutes()
  }

  updateState = (updated_item) => {
    let items = this.state.items
    const item_index = items.findIndex(item => item['device_id'] === updated_item['device_id'])
    items[item_index] = updated_item
    this.setState({ items })
    this.props.refreshRoutes()
  }

  deleteItemFromState = (id_to_delete) => {
    this.setState({ items: this.state.items.filter(item => item['device_id'] !== id_to_delete) })
    this.props.refreshRoutes()
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Geräte'}
            refreshRoutes={this.props.refreshRoutes}
            items={this.state.items}
            addItemToState={this.addItemToState}
            updateState={this.updateState}
            deleteItemFromState={this.deleteItemFromState}
            item_type='device'
            columns={['Name', 'Beschreibung']}
            columns_DB={['name', 'description']}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}

export default Devices_Card