/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import { fetchGetData, fetchRemove, fetchRemoveFrom, fetchAddNew, fetchAddInternalConnection, fetchEdit } from "helpers/api"

// reactstrap components
import Header_Card from "./Header_Card"
import IO_type_Head_Card from "./IO_types_Head_Card"
import Graph_Card from './Graph_Card'
import Parameters_Card from "./Parameters_Card"
import { DeviceContext } from 'context/DeviceContext'

class ComponentP extends React.Component {
  static contextType = DeviceContext

  state = {
    attribute_types_content: [],
    component: this.props.component,
    components: [],
    data_loaded: false,
    input_types_attribute_type_sum: [],
    input_types: [],
    inputs: [],
    inputs_attribute_sum: [],
    inputs_output_sum: [],
    internal_connections_sum_with_types: [],
    internal_types_attribute_type_sum: [],
    internal_types: [],
    internals: [],
    internals_attribute_sum: [],
    output_types_attribute_type_sum: [],
    output_types: [],
    outputs: [],
    outputs_attribute_sum: [],
    views: [],
    views_input_type_sum: [],
    views_internal_type_sum: [],
    views_output_type_sum: [],
    subcards: {internal: {}, input: {}, output: {}}
  }


  async getItems() {
    const requestList = [
      { table: 'attribute_types_content', device: this.context.device },
      { table: 'components', device: {} },
      { table: 'input_types_attribute_type_sum', device: this.context.device },
      { table: 'input_types', device: this.context.device },
      { table: 'inputs', device: this.context.device },
      { table: 'inputs_attribute_sum', device: this.context.device },
      { table: 'inputs_output_sum', device: this.context.device },
      { table: 'internal_connections_sum_with_types', device: this.context.device },
      { table: 'internal_types_attribute_type_sum', device: this.context.device },
      { table: 'internal_types', device: this.context.device },
      { table: 'internals', device: this.context.device },
      { table: 'internals_attribute_sum', device: this.context.device },
      { table: 'output_types_attribute_type_sum', device: this.context.device },
      { table: 'output_types', device: this.context.device },
      { table: 'outputs', device: this.context.device },
      { table: 'outputs_attribute_sum', device: this.context.device },
      { table: 'views', device: this.context.device },
      { table: 'views_input_type_sum', device: this.context.device },
      { table: 'views_internal_type_sum', device: this.context.device },
      { table: 'views_output_type_sum', device: this.context.device }
    ]

    try {
      await Promise.all(requestList.map(async request => {
        const data = await fetchGetData(request.device, request.table)
        if (data.dataExists !== 'false') {
          this.setState({ [request.table]: data })
        }
      }))
      this.setState({ data_loaded: true })
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount() {
    this.getItems()
  }

  // creates an input/output/internal and adds it to the state
  addIO = async (item_type, io_type, attributes) => {
    const component_id = this.props.component.component_id
    let attributes_db = {
      name: attributes.name,
      [item_type + '_type_id']: io_type[item_type + '_type_id'],
      component_id: Number(component_id)
    }

    // if the output-type is a server check if it already exists
    let confirm = true
    if (io_type[item_type + '_type_name'] === 'Service-Server' || io_type[item_type + '_type_name'] === 'Action-Server') {
      if (this.props.outputs.filter(output => output['output_type_id'] === io_type[item_type + '_type_id'] && output['name'] === attributes.name).length > 0) {
        confirm = window.confirm("Ein " + io_type[item_type + '_type_name']  + " mit dem gleichen Namen existiert bereits. Fortfahren?")
      }
    }
    if (confirm) {
      try {
        const item = await fetchAddNew(this.context.device, item_type + 's', attributes_db)
        if (item.dataExists !== 'false') {
          // save position of the new node
          let component = this.state.component
          if (attributes.position) {
            let graph_json = component.graph ? component.graph : {elements: {edges: [], nodes: []}}
            graph_json.elements.nodes.push({
              classes: item_type + 's',
              data: {id: item_type + '-' + item[0][item_type + '_id']},
              position: attributes.position
            })
            await fetchEdit('components', undefined, this.props.component.component_id, {graph: graph_json})
            component.graph = graph_json
          }

          let items = this.state[item_type + 's'].concat(item)

          let io_attribute_sum = await fetchGetData(this.context.device, [item_type + 's_attribute_sum'])
          if (io_attribute_sum.dataExists === 'false') io_attribute_sum = this.state[item_type + 's_attribute_sum']

          this.setState({ component, [item_type + 's']: items, [item_type + 's_attribute_sum']: io_attribute_sum })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  addAttribute = (item_type, io_item, new_attribute) => {
    new_attribute[item_type + '_id'] = io_item[item_type + '_id']
    new_attribute[item_type + '_name'] = io_item['name']
    new_attribute[item_type + '_type_id'] = io_item[item_type + '_type_id']
    new_attribute[item_type + '_component_id'] = this.props.component.component_id
    let io_attribute_sum = this.state[item_type + 's_attribute_sum']
    io_attribute_sum.push(new_attribute)
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  // adds an internal connection to the state
  addInternalConnection = async (new_item) => {
    let internal_connections_sum_with_types = await fetchGetData(this.context.device, 'internal_connections_sum_with_types')
    this.setState({ internal_connections_sum_with_types })
  }

  // adds a connection to the state
  addConnection = async (new_item) => {
    let inputs_output_sum = await fetchGetData(this.context.device, 'inputs_output_sum')
    this.setState({ inputs_output_sum })
  }

  // creates a new internal connection
  createInternalConnection = (origin_item_type, origin_id, dest_item_type, dest_id) => {
    fetchAddInternalConnection(this.context.device, origin_item_type, origin_id, dest_item_type, dest_id, this.props.component.component_id)
    .then(item => {
      this.addInternalConnection(item[0])
    })
    .catch(err => console.log(err))
  }

  // removes one or multiple input/internal/output or internal_connection from the DB and the state
  deleteItems = (items, forced = false) => {
    try {
      let state = this.state
      if (forced || window.confirm('Löschen?')) {
        items.forEach(async item => {
          const { item_type, id } = item
          if (item_type === 'internal_connection') {
            fetchRemoveFrom('internal_connections', id)
            state.internal_connections_sum_with_types = state.internal_connections_sum_with_types.filter(item => item.internal_connections_id !== id)
          } else if (item_type === 'input' || item_type === 'output' || item_type === 'internal') {
            fetchRemove(item_type + 's', id)
      
            // also delete the internal connections from and to the input/internal/output
            let internal_connections = state.internal_connections_sum_with_types.filter(item => item['origin_' + item_type + '_id'] === id || item['destination_' + item_type + '_id'] === id)
            internal_connections.forEach(internal_connections => {
              fetchRemoveFrom('internal_connections', internal_connections.internal_connections_id)
              state.internal_connections_sum_with_types = state.internal_connections_sum_with_types.filter(item => item.internal_connections_id !== internal_connections.internal_connections_id)
            })
      
            // also delete the connections from and to the input/output
            if (item_type !== 'internal') {
              let connections = this.state.inputs_output_sum.filter(item => item[item_type + '_id'] === id)
              connections.forEach(connection => {
                fetchRemoveFrom('inputs_output', connection.inputs_output_id)
                state.inputs_output_sum = state.inputs_output_sum.filter(item => item[item_type + '_id'] !== connection.inputs_output_id)
              })
              state.inputs_output_sum = state.inputs_output_sum.filter(item => item[item_type + '_id'] !== id)
            }

            state[item_type + 's'] = state[item_type + 's'].filter(item => item[item_type + '_id'] !== id)
            state.internal_connections_sum_with_types = state.internal_connections_sum_with_types.filter(item => item['origin_' + item_type + '_id'] !== id && item['destination_' + item_type + '_id'] !== id)
          }
        })
      }
      this.setState(state)
      this.props.refreshRoutes()
    } catch (err) {
      console.log(err)
    }
  }

  deleteAttribute = (item_type, id) => {
    let io_attribute_sum = this.state[item_type + 's_attribute_sum'].filter(item => item['attribute_id'] !== id)
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  // removes an internal connection from the DB and the state
  deleteInternalConnection = (id, forced = false) => {
    if (forced || window.confirm('Delete internal connection?')) {
      fetchRemoveFrom('internal_connections', id)
      .then(item => {
        let items = this.state.internal_connections_sum_with_types.filter(item => item.internal_connections_id !== id)
        this.setState({ internal_connections_sum_with_types: items })
      })
      .catch(err => console.log(err))
    }
  }

  // removes a connection from the state
  deleteConnection = (id) => {
    let items = this.state.inputs_output_sum.filter(item => item.inputs_output_id !== id)
    this.setState({ inputs_output_sum: items })
  }

  // restores one or multiple items (input, output, internal, inputs_output or internal_connection)
  restoreItems = async (items) => {
    try {
      let internal_connection_restored = false
      let inputs_output_restored = false
      let state = this.state
      await Promise.all(items.map(async item => {
        const { item_type, id } = item
        let table = item_type + 's'
        let id_string = item_type + '_id'
        if (item_type === 'internal_connection') {
          internal_connection_restored = true
          id_string = item_type + 's_id'
        } else if (item_type === 'inputs_output') {
          inputs_output_restored = true
          table = 'inputs_output'
        }
        
        const edited_item = await fetchEdit(table, id_string, id, {deleted: false})
        if (edited_item.dataExists !== 'false' && item_type !== 'internal_connection' && item_type !== 'inputs_output') {
          state[table] = state[table].concat(edited_item)
        }

      }))
      if (internal_connection_restored) {
        state.internal_connections_sum_with_types = await fetchGetData(this.context.device, 'internal_connections_sum_with_types')
      }
      if (inputs_output_restored) {
        state.inputs_output_sum = await fetchGetData(this.context.device, 'inputs_output_sum')
      }
      this.setState(state)
      this.props.refreshRoutes()
    } catch (err) {
      console.log(err)
    }
  }

  updateComponent = (updated_item) => {
    this.setState({ component: updated_item })
    this.props.updateRoute('component', updated_item.component_id, updated_item.name)
  }

  // updates an input/output/internal in the state
  updateIO = (item_type, updated_item) => {
    const item_index = this.state[item_type + 's'].findIndex(item => item[item_type + '_id'] === updated_item[item_type + '_id'])
    const items = [...this.state[item_type + 's'].slice(0, item_index),  updated_item, ...this.state[item_type + 's'].slice(item_index + 1)]

    this.setState({ [item_type + 's']: items })
    this.props.refreshRoutes()
  }

  // updates an attribute in the state
  updateAttributes = (item_type, updated_item) => {
    let io_attribute_sum = this.state[item_type + 's_attribute_sum']
    const io_attribute_index = io_attribute_sum.findIndex(item => item.attribute_id === updated_item.attribute_id)
    io_attribute_sum[io_attribute_index].content = updated_item.content
    this.setState({ [item_type + 's_attribute_sum']: io_attribute_sum })
  }

  setSubcards = (item_type, type_subcards) => {
    let subcards = this.state.subcards
    subcards[item_type] = type_subcards
    this.setState({ subcards })
  }

  scrollToSubcard = async (item_type, io_id) => {
    if (document.fullscreenElement) {
      await document.exitFullscreen()
      await new Promise(resolve => setTimeout(resolve, 100))
    }
    const io = this.state[item_type + 's'].find(item => item[item_type + '_id'] === io_id)
    const io_type_id = io[item_type + '_type_id']
    let subcards = this.state.subcards
    subcards[item_type][io_type_id] = true
    await (async (state) => {this.setState(state)})({ subcards })
    document.getElementById(item_type + '-' + io_id).scrollIntoView()
  }

  render() {
    const view_id = this.props.component.view_id
    const view = this.state.views.find(item => item.view_id == view_id)
    const component_id = this.props.component.component_id

    // workaround to filter the io_types by the view using views_io_type_sum
    // TODO: DB schema should be altered such that the views_input_type/views_internal_type/view_output_type tables are not needed anymore
    // then the io_types can be directly filtered by the view
    let view_input_type_ids = this.state.views_input_type_sum.filter(input_type => input_type.view_id === view_id).map(input_type => input_type.input_type_id)
    let view_internal_type_ids = this.state.views_internal_type_sum.filter(internal_type => internal_type.view_id === view_id).map(internal_type => internal_type.internal_type_id)
    let view_output_type_ids = this.state.views_output_type_sum.filter(output_type => output_type.view_id === view_id).map(output_type => output_type.output_type_id) 
    return (
      <>
        <div className="content">
          <Header_Card
            component={this.state.component}
            components={this.state.components}
            updateState={this.updateComponent}
            user_type_id={this.props.user_type_id}
            view={view}
          />
          <Graph_Card
            addIO={this.addIO}
            all_inputs={this.state.inputs}
            all_outputs={this.state.outputs}
            createInternalConnection={this.createInternalConnection}
            data_loaded={this.state.data_loaded}
            deleteItems={this.deleteItems}
            deleteInternalConnection={this.deleteInternalConnection}
            restoreItems={this.restoreItems}
            scrollToSubcard={this.scrollToSubcard}
            updateComponent={this.updateComponent}
            updateIO={this.updateIO}
            attribute_types_content={this.state.attribute_types_content}
            component={this.state.component}
            components={this.state.components}
            input_types_attribute_type_sum={this.state.input_types_attribute_type_sum}
            input_types={view_id ? this.state.input_types.filter(input_type => view_input_type_ids.includes(input_type.input_type_id)) : this.state.input_types}
            inputs={this.state.inputs.filter(item => item.component_id === component_id)}
            inputs_attribute_sum={this.state.inputs_attribute_sum}
            inputs_output_sum={this.state.inputs_output_sum}
            internal_connections={this.state.internal_connections_sum_with_types.filter(item => item.component_id === component_id)}
            internal_types_attribute_type_sum={this.state.internal_types_attribute_type_sum}
            internal_types={view_id ? this.state.internal_types.filter(internal_type => view_internal_type_ids.includes(internal_type.internal_type_id)) : this.state.internal_types}
            internals={this.state.internals.filter(item => item.component_id === component_id)}
            internals_attribute_sum={this.state.internals_attribute_sum}
            output_types_attribute_type_sum={this.state.output_types_attribute_type_sum}
            output_types={view_id ? this.state.output_types.filter(output_type => view_output_type_ids.includes(output_type.output_type_id)) : this.state.output_types}
            outputs={this.state.outputs.filter(item => item.component_id === component_id)}
            outputs_attribute_sum={this.state.outputs_attribute_sum}
            user_type_id={this.props.user_type_id}
          />
          <Parameters_Card
            component_id={component_id}
            components={this.state.components}
            user_type_id={this.props.user_type_id}
          />
          {/*The io cards are split into two rendering parts, a head and a sub part. the head also renders the sub part*/}
          {['internal', 'input', 'output'].map((item_type, index) => {
            return  <IO_type_Head_Card
                      key={index}
                      item_type={item_type}
                      addAttribute={this.addAttribute}
                      addIO={this.addIO}
                      addInternalConnection={this.addInternalConnection}
                      addConnection={this.addConnection}
                      deleteAttribute={this.deleteAttribute}
                      deleteItems={this.deleteItems}
                      deleteInternalConnection={this.deleteInternalConnection}
                      deleteConnection={this.deleteConnection}
                      updateIO={this.updateIO}
                      scrollToSubcard={this.scrollToSubcard}
                      setSubcards={this.setSubcards}
                      updateAttributes={this.updateAttributes}
                      attribute_types_content={this.state.attribute_types_content}
                      component_id={component_id}
                      view_id={view_id}
                      components={this.state.components}
                      input_types_attribute_type_sum={this.state.input_types_attribute_type_sum}
                      inputs={this.state.inputs}
                      inputs_attribute_sum={this.state.inputs_attribute_sum}
                      inputs_output_sum={this.state.inputs_output_sum}
                      internal_connections_sum_with_types={this.state.internal_connections_sum_with_types}
                      internals={this.state.internals}
                      internals_attribute_sum={this.state.internals_attribute_sum}
                      internal_types_attribute_type_sum={this.state.internal_types_attribute_type_sum}
                      output_types_attribute_type_sum={this.state.output_types_attribute_type_sum}
                      io_types={this.state[item_type + '_types']}
                      outputs={this.state.outputs}
                      outputs_attribute_sum={this.state.outputs_attribute_sum}
                      subcards={this.state.subcards[item_type]}
                      user_type_id={this.props.user_type_id}
                      views_input_type_sum={this.state.views_input_type_sum}
                      views_internal_type_sum={this.state.views_internal_type_sum}
                      views_output_type_sum={this.state.views_output_type_sum}
                    />
            })}
        </div>
      </>
    )
  }
}

export default ComponentP
