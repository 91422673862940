import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Module_Doc extends Component {

  render() {
    return (
      <Card id='module_doc'>
        <CardHeader>
          <CardTitle tag='h4'>Module und Testarchitekturen</CardTitle>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    )
  }
}

export default Module_Doc