/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"
import api_url from "../../../helpers/config.js"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'

// reactstrap components
import Rev_Header_Card from "./Rev_Header_Card"
import Rev_Owner_Card from "./Rev_Owner_Card"
import Rev_Output_Card from "./Rev_Output_Card"
import Rev_Input_Card from "./Rev_Input_Card"

class Rev_ComponentP extends React.Component {
  static contextType = DeviceContext

  render() {
    const component = this.props.component
    return (
      <>
        <div className="content">
          <Rev_Header_Card component_id={component.component_id} component_view_id={component.view_id} />
          <Rev_Owner_Card component_id={component.component_id} />
          <Rev_Input_Card component_id={component.component_id} />
          <Rev_Output_Card component_id={component.component_id} />
        </div>
      </>
    )
  }
}

export default Rev_ComponentP
