import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Navigation_Doc extends Component {

  render() {
    return (
      <Card id='navigation_doc'>
        <CardHeader>
          <CardTitle tag='h4'>Navigation</CardTitle>
        </CardHeader>
        <CardBody>
          <CardTitle id='sidebar_doc' tag='h5'>Navigationsleiste</CardTitle>
          Die Navigationsleiste am linken Seitenrand, dient als primäre Möglichkeit um die verschiedenen Sichten, Komponente und anderen
          Teile des Tool abzurufen.
          Sie ist aufgeteilt in mehrere Bereiche: <br />

          Die zuoberst liegende, blau hinterlegte, Schaltfläche dient der Auswahl des Gerätes, dessen Komponenten im restlichen Tool
          angezeigt werden.
          Währen die Schaltfläche immer das aktuell gewählte Gerät anzeigt, erscheinen die üblichen Geräte durch Hovern über der Fläche
          und ermöglichen so einen schnellen Wechsel zwischen verschiedenen Geräten.
          Ein Klick auf die Schaltfläche öffnet außerdem die 
          Durch hovern über der Schaltfläche erscheint die
          <a onClick={() => document.getElementById('devices_doc').scrollIntoView()}> Geräte-Seite</a>, die genaure Details über die
          verfügbaren Geräte und die Möglichkeit zur Erstellung und Bearbeitung von Geräten liefert. <br />

          Die beiden darunter liegenden Flächen bieten Zugriff auf das
          <a onClick={() => document.getElementById('user_dashboard_doc').scrollIntoView()}> User Dashboard</a>, sowie das
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Architektur Management</a>. <br />

          Desweiteren bietet die Navigationsleiste Bereiche für die verfügbaren Sichten, Module und Testarchitekturen.
          Ein Klick auf einen der Einträge führt zu der jeweiligen Seite der
          <a onClick={() => document.getElementById('view_doc').scrollIntoView()}> Sicht</a>, des
          <a onClick={() => document.getElementById('module_doc').scrollIntoView()}> Moduls</a> bzw. der
          <a onClick={() => document.getElementById('module_doc').scrollIntoView()}> Testarchitektur</a>, während
          Darüberhovern eine Liste mit allen dem jeweiligen Element zugeordneten Komponenten öffnet. <br />

          Zuletzt folgt ein Bereich der die verfügbaren
          <a onClick={() => document.getElementById('degradation_rule_doc').scrollIntoView()}> Degradationsregeln</a> auflistet.
          <br /><br />
          <CardTitle id='searchbar_doc' tag='h5'>Suchleiste</CardTitle>
          Die Suchleiste am oberen Rand kann genutzt werden um Sichten, Module, Komponenten, Inputs, Outputs, Internals und Nutzer zu finden.
          Dabei sind Komponenten auch über ihre Sicht auffindbar und Inputs, Outputs und Internals auch über ihre Komponente.
          Das heißt, dass man etwa mit dem Suchbegriff "Dienste" sowohl die Dienste Architektur, als auch sämtliche Komponenten der Dienste
          Architektur finden kann.
          Darüber hinaus lassen sich mehrere Suchbegriffe oder Teile von Begriffen kombinieren um die Suche stärker einzugrenzen.
        </CardBody>
      </Card>
    )
  }
}

export default Navigation_Doc