import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Degradation_Rule_Doc extends Component {

  render() {
    return (
      <Card id='degradation_rule_doc'>
        <CardHeader>
          <CardTitle tag='h4'>Degradationsregeln</CardTitle>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    )
  }
}

export default Degradation_Rule_Doc