import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { fetchGetUserData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    user: {}
  }

  componentDidMount() {
    this.getItems()
  }

  getItems() {    
    fetchGetUserData()
    .then(user => {
      this.setState({ user })
    })
    .catch(err => console.log(err))
  }

  render() {
    let templates = this.props.components.filter(component => component.is_template)
    templates.forEach(template => template['template_name'] = template['name'])

    let components = []
    this.props.components.forEach(component => {
      if (component.devices.map(device => device['device_id']).includes(this.context.device['device_id']) && component['view_id'] === this.props.view_id) {
        if (component['template_id']) {
          component['template_name'] = templates.find(template => template['component_id'] === component['template_id'])['template_name']
        }
        components.push(component)
      }
    })

    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Komponenten'}
            items={components}
            createComponent={this.props.createComponent}
            updateState={this.props.updateState}
            deleteItemFromState={this.props.deleteComponent}
            item_type={'component'}
            parent_type='view'
            parent_id={this.props.view_id}
            child_type={'component'}
            user={this.state.user}
            columns={['Name', 'Template (optional)', 'Beschreibung']}
            columns_DB={['name', 'template_name', 'description']}
            select={{'template_name': {items: templates, col_width: 1, columns: ['template_name']}}}
            no_edit={['template_name']}
            links={{'name': {'id': 'component_id', 'type': 'component'}, 'template_name': {'id': 'template_id', 'type': 'component'}}}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Components_Card