import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { fetchGetData } from 'helpers/api'
import { DeviceContext } from 'context/DeviceContext'

class Templates_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData({}, 'components')
    .then(components => {
      if (components.dataExists != 'false') {
        let templates = components.filter(item => item.is_template)
        this.setState({ items: templates })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (new_item) => {
    this.setState({ items: this.state.items.concat([new_item]) })
    this.props.addRoute('component', new_item)
  }

  updateState = (updated_item) => {
    let items = this.state.items
    const item_index = items.findIndex(item => item['component_id'] === updated_item['component_id'])
    items[item_index] = updated_item
    this.setState({ items })
    this.props.updateRoute('component', updated_item['component_id'], updated_item['name'])
  }

  deleteItemFromState = (id_to_delete) => {
    this.setState({ items: this.state.items.filter(item => item['component_id'] !== id_to_delete) })
    this.props.removeRoute('component', id_to_delete)
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Templates'}
            items={this.state.items}
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type='component'
            columns={['Name', 'Beschreibung']}
            columns_DB={['name', 'description']}
            links={{'name': {'id': 'component_id', 'type': 'component'}}}
            flags={['is_template']}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Templates_Card