import React, { Component } from 'react'
import 'react-medium-image-zoom/dist/styles.css'
import Trash_SVG from 'assets/fontawesome_svgs/trash.svg'
import Pen_SVG from 'assets/fontawesome_svgs/pen.svg'
import Plus_SVG from 'assets/fontawesome_svgs/plus.svg'
import Info_SVG from 'assets/fontawesome_svgs/info.svg'
import { DeviceContext } from 'context/DeviceContext'
import translate from 'helpers/translations'

import Cytoscape_Card from 'cytoscape/Cytoscape_Card'


class Graph_Card extends Component {
  static contextType = DeviceContext

  state = {
    elements: [],
    contextmenu_items: [],
    cy_initialized: false
  }

  componentDidMount() {
    this.cardRef = React.createRef()
  }

  componentDidUpdate(prev_props) {
    if (this.props.data_loaded && !this.state.cy_initialized) {
      this.buildCytoscapeGraph()
    } else if (this.state.cy_initialized && prev_props !== this.props) {
      let elements = this.state.elements.filter(elem => elem.data.id !== 'component' && elem.data.source !== 'component' && elem.data.target !== 'component');
      ['input', 'output', 'internal', 'internal_connection'].map(item_type => {
        const prop_items = this.props[item_type + 's']
        const prev_items = prev_props[item_type + 's']
        if (prop_items !== prev_items) {
          const id_string = item_type === 'internal_connection' ? item_type + 's_id' : item_type + '_id'
          const prop_ids = prop_items.map(item => item[id_string])
          const prev_ids = prev_items.map(item => item[id_string])
          const new_items = prop_items.filter(item => !prev_ids.includes(item[id_string]))
          const deleted_item_ids = prev_ids.filter(id => !prop_ids.includes(id))
          if (new_items.length > 0) {
            elements = elements.concat(this.createGraphElements(item_type, new_items, elements))
          }
          if (deleted_item_ids.length > 0) {
            deleted_item_ids.forEach(id => {
              elements = elements.filter(elem => elem.data.id !== item_type + '-' + id)
            })
          }
        }
      })
      if (this.props.internals.length === 0 && this.props.internal_connections.length === 0) {
        // if no internals and internal connections exist, create vertex for component
        elements = elements.concat(this.createComponentNode(elements))
      }
      this.setState({ elements, contextmenu_items: this.createContextMenu() })
    }
  }

  buildCytoscapeGraph() {
    let new_elements = ['input', 'output', 'internal'].map(item_type => {
      return this.createGraphElements(item_type, this.props[item_type + 's'], [])
    }).flat()
    new_elements = new_elements.concat(this.createGraphElements('internal_connection', this.props.internal_connections, new_elements))
    if (this.props.internals.length === 0 && this.props.internal_connections.length === 0) {
      new_elements = new_elements.concat(this.createComponentNode(new_elements))
    }

    const contextmenu_items = this.createContextMenu()
    this.setState({ cy_initialized: true, elements: new_elements, contextmenu_items })
  }

  createContextMenu() {
    const cytoscape_card = this.cardRef.current
    let contextmenu_items = [
        {
          id: 'details',
          content: 'Details',
          image: {src: Info_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'node[id != "component"]',
          onClickFunction: (event) => {
            const cy_id = event.target.data('id')
            const item_type = cy_id.split('-')[0] // input, output or internal
            const io_id = Number(cy_id.split('-')[1])
            this.props.scrollToSubcard(item_type, io_id)
          }
        },
        {
          id: 'delete',
          content: 'Löschen',
          image: {src: Trash_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'edge[source != "component"][target != "component"], node[id != "component"]',
          onClickFunction: (event) => {
            cytoscape_card.deleteSelectedElements()
          }
        },
        {
          id: 'rename',
          content: 'Umbenennen',
          image: {src: Pen_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'node[id != "component"]',
          onClickFunction: (event) => {
            let cy_id = event.target.data('id')
            let item_type = cy_id.split('-')[0] // input, output or internal
            cytoscape_card.startRename(cy_id, item_type, {})
          }
        },
        ['internal', 'input', 'output'].map(item_type => {
          const submenu_items = this.props[item_type + '_types'].map(io_type => {
            return {
              id: 'new_' + item_type + '_' + io_type[item_type + '_type_id'],
              content: io_type.name,
              onClickFunction: (event) => {
                cytoscape_card.createNewNode(event.position, item_type, io_type)
              }
            }
          })
          return {
            id: 'new_' + item_type,
            content: 'Neuer ' + translate(item_type),
            image: submenu_items.length > 0 ? {src: Plus_SVG, width: 14, height: 14, x: 5, y: 4} : {},
            tooltipText: submenu_items.length > 0 ? '' : 'Kein ' + translate(item_type) + 'typ verfügbar',
            selector: '',
            coreAsWell: true,
            disabled: submenu_items.length === 0,
            submenu: submenu_items.length > 0 ? submenu_items : null
          }
        }),
        ['input', 'internal'].map(item_type => {
          return this.props[item_type + 's'].map(item => {
            const id =  item[item_type + '_id']
            const node_id = item_type + '-' + id
            const submenu_items = this.props.internals.map(internal => {
              internal['item_type'] = 'internal'
              return internal
            }).concat(this.props.outputs.map(output => {
              output['item_type'] = 'output'
              return output
            })).concat(this.props.inputs.map(input => {
              input['item_type'] = 'input'
              return input
            })).filter(io => {
              const origin_item_type = node_id.split('-')[0]
              const origin_id = Number(node_id.split('-')[1])
              const dest_item_type = io['item_type']
              const dest_id = io[dest_item_type + '_id']
              const connection = this.props.internal_connections.find(connection => 
                connection['origin_' + origin_item_type + '_id'] === origin_id && connection['destination_' + dest_item_type + '_id'] === dest_id)
              const both_input = origin_item_type === 'input' && dest_item_type === 'input'
              const is_loop = origin_item_type === dest_item_type && origin_id === dest_id
              return !both_input && !is_loop && !connection
            }).map(io => {
              const origin_item_type = node_id.split('-')[0]
              const origin_id = Number(node_id.split('-')[1])
              const dest_item_type = io['item_type']
              const dest_id = io[dest_item_type + '_id']
              const dest_io_type = this.props[dest_item_type + '_types'].find(io_type => io_type[dest_item_type + '_type_id'] === io[dest_item_type + '_type_id'])
              const dest_io_type_name = dest_io_type ? dest_io_type.name : ''
              return {
                id: dest_item_type + '_' + io[dest_item_type + '_id'],
                content: dest_item_type.charAt(0).toUpperCase() + dest_item_type.slice(1) + ': ' + dest_io_type_name + ': ' + io['name'],
                onClickFunction: (event) => {
                  this.props.createInternalConnection(origin_item_type, origin_id, dest_item_type, dest_id)
                }
              }
            })
            return {
              id: 'internal_connect_' + node_id,
              content: 'Neue Interne Verbindung',
              image: submenu_items.length > 0 ? {src: Plus_SVG, width: 14, height: 14, x: 5, y: 4} : {},
              tooltipText: submenu_items.length > 0 ? 'Stellt eine Verbindung zwischen diesem und einem anderen Element innerhalb dieser Komponente her'
                                                    : 'Keine geeigneten Elemente verfügbar',
              selector: '#' + node_id,
              disabled: submenu_items.length === 0,
              submenu: submenu_items.length > 0 ? submenu_items : null
            }
          })
        })
      ].flat(2)

    return contextmenu_items
  }

  createGraphElements(item_type, new_items, elements) {
    let saved_nodes = this.props.component.graph ? this.props.component.graph.elements.nodes : []

    let new_elements = []

    const default_pos = (item_type) => {
      // returns a random position for inputs in the left third of the container, for outputs in the right third and for internals in the middle
      let third
      if (item_type === 'input') {
        third = 0
      } else if (item_type === 'output') {
        third = 2
      } else if (item_type === 'internal') {
        third = 1
      } else {
        return { x: this.cardRef.current.cy.width()/2, y: (this.cardRef.current.cy.height()/2)-50}
      }
      return { x: Math.round((Math.random()+third)*(this.cardRef.current.cy.width()/3)), y: Math.round((Math.random()*(this.cardRef.current.cy.height()-50))+25) }
    }

    if (item_type === 'internal_connection') {
      new_items.forEach(connection => {
        const source_id = connection.origin_type + '-' + connection['origin_' + connection.origin_type + '_id']
        const target_id = connection.destination_type + '-' + connection['destination_' + connection.destination_type + '_id']
        if (elements.find(el => el.data['id'] === source_id) && elements.find(el => el.data['id'] === target_id)) {
          new_elements.push({ data: { id: 'internal_connection-' + connection.internal_connections_id, source: source_id, target: target_id } })
        }
      })
    } else {
      let io_type_ids = this.props[item_type + '_types'].map(item => item[item_type + '_type_id'])
      new_items.forEach((item, index) => {
        let saved_node = saved_nodes.find(node => node.data.id === item_type + '-' + item[item_type + '_id'])
        if (io_type_ids.includes(item[item_type + '_type_id'])) {
          const io_type = this.props[item_type + '_types'].find(io_type => io_type[item_type + '_type_id'] === item[item_type + '_type_id'])
          const io_type_name = io_type ? io_type.name : ''
          new_elements.push({ classes: item_type + 's',
                              data: { id: item_type + '-' + item[item_type + '_id'], label: io_type_name + ':\n' + item['name'] },
                              style: { width: Math.max(io_type_name.length, item['name'].length, 6)*9 },
                              position: saved_node ? { x: Number(saved_node.position.x), y: Number(saved_node.position.y) } : default_pos(item_type) })
        }
      })
    }

    return new_elements
  }

  createComponentNode(elements) {
    let new_elements = []
    const component_name = this.props.component ? this.props.component.name : ''

    new_elements.push({ classes: 'component',
                    data: { id: 'component', label: 'Komponente:\n' + component_name},
                    style: { width: Math.max('Komponente'.length, component_name.length, 6)*9 },
                    position: { x: this.cardRef.current.cy.width()/2, y: (this.cardRef.current.cy.height()/2)-50}, selectable: false })
    this.props.inputs.forEach(input => {
      if (elements.find(el => el.data['id'] === 'input-' + input.input_id)) {
        new_elements.push({ data: { source: 'input-' + input.input_id, target: 'component' }, selectable: false })
      }
    })
    this.props.outputs.forEach(output => {
      new_elements.push({ data: { source: 'component', target: 'output-' + output.output_id }, selectable: false })
    })

    return new_elements
  }

  createNewNode(cy, position, item_type, io_type) {
    const new_node = { 
      classes: item_type + 's new',
      data: { id: 'new', label: io_type.name + ':\n' },
      style: { width: io_type.name.length*10 },
      position: position
    }
    return new_node
  }

  deleteGraphElements = (elements) => {
    let items_to_delete = []
    let items_to_restore = [] // items that will automatically be deleted, but have to be saved to restore them when necessary

    elements.forEach(element => {
      const cy_id = element.data('id')
      const type = cy_id.split('-')[0]
      const id = Number(cy_id.split('-')[1])
      
      if (type === 'internal_connection') {
        items_to_delete.push({item_type: type, id: id})
      } else if (type === 'input' || type === 'output' || type === 'internal') {
        // save internal connections and connections between inputs and outputs to later be able to restore them
        let internal_connections = this.props.internal_connections.filter(item => item['origin_' + type + '_id'] === id || item['destination_' + type + '_id'] === id)
        internal_connections.forEach(item => {
          items_to_restore.push({item_type: 'internal_connection', id: item.internal_connections_id})
        })
        if (type !== 'internal') {
          let connections = this.props.inputs_output_sum.filter(item => item[type + '_id'] === id)
          connections.forEach(item => {
            items_to_restore.push({item_type: 'inputs_output', id: item.inputs_output_id})
          })
        }

        items_to_delete.push({item_type: type, id: id})
      }
    })

    if (items_to_delete.length > 0) {
      this.props.deleteItems(items_to_delete, true)
    }

    return items_to_delete.concat(items_to_restore)
  }

  render() {
    return (
      <Cytoscape_Card
        addIO={this.props.addIO}
        components={this.props.components}
        contextmenu_items={this.state.contextmenu_items}
        createConnection={this.props.createInternalConnection}
        createNewNode={this.createNewNode}
        default_height={Math.max(this.props.inputs.length, this.props.outputs.length)*100}
        deleteGraphElements={this.deleteGraphElements}
        deleteItems={this.props.deleteItems}
        elements={this.state.elements}
        graph_id={this.props.component.component_id}
        graph_type={'component'}
        initialized={this.state.cy_initialized}
        inputs={this.props.all_inputs}
        inputs_attribute_sum={this.props.inputs_attribute_sum}
        inputs_output={this.props.inputs_output_sum}
        internals={this.props.internals}
        internals_attribute_sum={this.props.internals_attribute_sum}
        outputs={this.props.all_outputs}
        outputs_attribute_sum={this.props.outputs_attribute_sum}
        ref={this.cardRef}
        restoreItems={this.props.restoreItems}
        scrollToSubcard={this.props.scrollToSubcard}
        updateParent={this.props.updateComponent}
        updateState={this.props.updateIO}
      />
    )
  }
}
export default Graph_Card