import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class DegradationRules_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, 'degradation_rules')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.setState({ items: this.state.items.concat([item]) })
    this.props.addRoute('degradation_rule', item)
  }

  updateState = (updated_item) => {
    let items = this.state.items
    const item_index = items.findIndex(item => item['degradation_rule_id'] === updated_item['degradation_rule_id'])
    items[item_index] = updated_item
    this.setState({ items })
    this.props.updateRoute('degradation_rule', updated_item['degradation_rule_id'], updated_item['name'])
  }

  deleteItemFromState = (id_to_delete) => {
    this.setState({ items: this.state.items.filter(item => item['degradation_rule_id'] !== id_to_delete) })
    this.props.removeRoute('degradation_rule', id_to_delete)
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Degradationsregeln'}
            items={this.state.items} 
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type={'degradation_rule'}
            columns={['Name', 'Beschreibung', 'Auslösebedingungen']}
            columns_DB={['name', 'description', 'trigger_conditions']}
            textarea={true}
            links={{'name': {'id': 'degradation_rule_id', 'type': 'degradation_rule'}}}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default DegradationRules_Card