import React, { Component } from 'react'
import { Input, CardBody, Card, CardHeader, CardTitle, Label } from 'reactstrap'
import { Link } from "react-router-dom"
import Owner_Modal from '../../components/Extensions/Modals/Owner_Modal.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGit } from "@fortawesome/free-brands-svg-icons"
import { faPen, faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext.js'
import { fetchGetData, fetchGetGitNamespace, fetchEdit } from 'helpers/api.js'

class Header_Card extends Component {
  static contextType = DeviceContext

  state = {
    template: undefined,
    copies: [],
    git_namespace: '',
    devices: [],
    edit: null
  }

  getItems() {
    fetchGetData({}, "devices")
    .then(devices => {
      if (devices.dataExists !== 'false') {
        this.setState({ devices })
      }
    })
    .catch(err => console.log(err))
    
    fetchGetGitNamespace()
    .then(git_namespace => {
      this.setState({ git_namespace })
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getItems()
  }

  componentDidUpdate(prevProps) {
    if (this.props.components !== prevProps.components) {
      const component = this.props.component
      if (component.template_id) {
        this.setState({ template: this.props.components.find(item => item.component_id === component.template_id) })
      } else if (component.is_template) {
        this.setState({ copies: this.props.components.filter(item => item.template_id === component.component_id) })
      }
    }
  }

  onChange = (e) => {
    const { name, id, value, checked } = e.target
    let edit_item = this.state.edit
    if (name === 'device') {
      if (checked) edit_item['device_ids'].push(Number(id))
      else edit_item['device_ids'] =  edit_item.device_ids.filter(i => i !== Number(id))
    } else {
      edit_item[name] = value
    }
    this.setState({ edit: edit_item })
  }

  submitChange = (edit_entries) => {
    if (this.state.edit['name']) {
      let edit_item = {}
      edit_entries.forEach(entry => {
        if (typeof this.state.edit[entry] === 'string') {
          edit_item[entry] = this.state.edit[entry].trim()
        } else {
          edit_item[entry] = this.state.edit[entry]
        }
      })
      fetchEdit('components', undefined, this.props.component.component_id, edit_item)
      .then(item => {
        this.setState({ edit: null })
        this.props.updateState(item[0])
      })
      .catch(err => console.log(err))
    }
  }

  render() {
    const component = this.props.component
    let component_view = "Diese Komponente ist keiner Sicht zugehörig"
    let component_devices
    let component_template

    // build link to corresponding view
    if (component.is_template) {
      component_view = "Template"
    } else if (this.props.view) {
      component_view = <Link to={'/admin/view_' + this.props.view.view_id}>{this.props.view.name}</Link>
    }

    // build list with corresponding devices
    if (this.state.edit && !component.is_template) {
      let sorted_devices = this.state.devices.sort((row1, row2) => {
        return row1['name'].toString().localeCompare(row2['name'].toString(), undefined, {sensitivity: 'base'})
      })
      component_devices = <>: {sorted_devices.map((device, index) => {
        let checked = this.state.edit.device_ids.includes(device.device_id)
        return (
          <div key={device.device_id} style={{ display: 'inline-block', marginRight: '27px' }}>
            <Label for={String(device.device_id)} style={{fontSize: '18px', fontWeight: 'normal', color: '#1d253b'}}>{(index !== 0 ? ', ' : '') + device.name}</Label>
            <Input
              type="checkbox"
              name={'device'}
              id={device.device_id}
              onChange={this.onChange}
              defaultChecked={checked}
              style={{marginLeft: '5px', marginTop: '0px', backgroundColor: checked ? 'rgb(133, 209, 204)' : ''}}
            />
          </div>
        )
      })}</>
    } else if (!component.is_template && component.devices && component.devices.length > 0) {
      component_devices = ': ' + component.devices.map(device => device.name).join(', ')
    }

    // build template information
    if (this.state.template) {
      component_template =
      <h5 className="card-category" style={{paddingBottom: '10px'}}>{"Abgeleitet von Template "}
        <Link to={'/admin/component_' + component.template_id}>{this.state.template.name}</Link>
      </h5>
    } else if (this.state.copies.length > 0) {
      let copies = this.state.copies.sort((c1, c2) => c1['name'].toString().localeCompare(c2['name'].toString(), undefined, {sensitivity: 'base'}))
      copies = copies.map(copy => {
        return <li key={copy.component_id}><Link to={'/admin/component_' + copy.component_id}>{copy.name}</Link></li>
      })
      component_template =
        <h5 className="card-category" style={{paddingBottom: '10px'}}>Von diesem Template abgeleitete Komponenten:
          <ul>{copies}</ul>
        </h5>
    }

    // build card title
    let card_title
    if (this.state.edit) {
      const input_style = this.state.edit['name'] ? {} : {borderColor: 'red', boxShadow: '0 0 5px red'} // style of the input field when it is empty
      card_title = 
        <>
          <Input
            type="text"
            name={'name'}
            autoFocus={true}
            onChange={this.onChange}
            value={this.state.edit['name'] || ''}
            placeholder='Name'
            style={{...{width: Math.max(140, this.state.edit['name'].length * 17), padding: '5px', marginLeft: '10px', fontSize: '30px'}, ...input_style}}
          />
          <button onClick={() => this.submitChange(['name', 'description', 'device_ids'])} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
          <button onClick={() => this.setState({ edit: null })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
        </>
    } else {
      card_title =
        <>
          {component['name']}
          <button onClick={() => this.setState({ edit: {name: component['name'], description: component['description'], device_ids: component['devices'].map(device => device['device_id'])} })} className='btn-minimal btn-edit' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPen} /></button>
          <Owner_Modal parent_type={'component'} parent_id={this.props.component.component_id} user_type_id={this.props.user_type_id} />
          <Link to={{ pathname: "https://" + this.state.git_namespace + "/component_" + this.props.component.component_id}} target="_blank">
            <button className='btn-minimal btn-git' data-toggle='tooltip' title="Open component in Git" hidden={this.props.user_type_id === 0}>
              <FontAwesomeIcon icon={faGit} />
            </button>
          </Link>
        </>
    }
    
    // build card body
    let card_body = <></>
    if (this.state.edit) {
      card_body =
        <CardBody>
          <h4>Beschreibung</h4>
          <Input
            type="textarea"
            name={'description'}
            onChange={this.onChange}
            value={this.state.edit['description'] || ''}
            style={{padding: '5px'}}
          />
        </CardBody>
    } else if (component['description']) {
      card_body = 
        <CardBody style={{whiteSpace: "pre-line"}}>
          <h4>Beschreibung</h4>
          {component['description']}
        </CardBody>
    }

    return (
      <Card>
        <CardHeader>
          <h5 className="card-category" style={{fontSize: '18px'}}>{component_view}{component_devices}</h5>
          <CardTitle tag="h2">
            <div className='d-flex' style={{ display: 'inline-block' }}>
              Komponente: {card_title}
            </div>
          </CardTitle>
          {component_template}
        </CardHeader>
        {card_body}
      </Card>
    )
  }
}

export default Header_Card