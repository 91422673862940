import React, { Component } from 'react'
import { CardBody, Card, CardHeader, CardTitle } from 'reactstrap'
import Rev_ConnectionTableWithSubRev_DataTable from 'components/Extensions/Tables/Revisions/Rev_ConnectionTableWithSubRev_DataTable.js'
import { fetchGetData, fetchGetDataWithDeleted } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Rev_Input_Card extends Component {
  static contextType = DeviceContext

  state = {
    inputs: [],
    attributes: [],
    rev_attributes: [],
    inputs_attribute: []
  }

  getItems() {
    fetchGetDataWithDeleted(this.context.device, 'inputs')
    .then(inputs => {
      if (inputs.dataExists != 'false') {
        this.setState({ inputs })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'attributes_sum')
    .then(attributes => {
      if (attributes.dataExists != 'false') {
        this.setState({ attributes })
      }
    })
    .catch(err => console.log(err))

    fetchGetDataWithDeleted(this.context.device, 'attributes_revisions')
    .then(rev_attributes => {
      if (rev_attributes.dataExists != 'false') {
        this.setState({ rev_attributes })
      }
    })
    .catch(err => console.log(err))

    fetchGetData(this.context.device, 'inputs_attribute_sum')
    .then(inputs_attribute => {
      if (inputs_attribute.dataExists != 'false') {
        this.setState({ inputs_attribute })
      }
    })
    .catch(err => console.log(err))

  }

  componentDidMount() {
    this.getItems()
  }

  refresh = () => {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Input Revisions</CardTitle>
        </CardHeader>
        <CardBody>
          <Rev_ConnectionTableWithSubRev_DataTable
            item_type={'component'}
            child_items={this.state.inputs}
            child_type={'input'}
            parent_child_items={this.state.inputs.filter(item => item.component_id == this.props.component_id)}
            columns={['Name']}
            columns_DB={['name']}
            refresh={this.refresh}
            sub_items={this.state.attributes}
            sub_rev_items={this.state.rev_attributes}
            sub_parent_child_items={this.state.inputs_attribute}
            sub_item_type='attribute'
            sub_columns={['Attribute Type', 'Content']}
            sub_columns_DB={['attribute_type_name', 'content']}
            revision_columns={['content']}
          />
        </CardBody>
      </Card>
    )
  }
}

export default Rev_Input_Card