import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

import './Documentation.css'

class Header extends Component {

  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag='h2'>Dokumentation</CardTitle>
        </CardHeader>
        <CardBody>
          <b style={{color: 'red'}}>Work-in-Progress</b>
          <br /><br />
          Diese Seite gibt einen Überblick und detaillierte Erklärungen über die Funktionen des Architekturtools.
          <br /><br />
          <CardTitle tag='h5'>Inhaltsverzeichnis</CardTitle>
          <ul id='index'>
            <li onClick={() => document.getElementById('navigation_doc').scrollIntoView()}>Navigation</li>
            <ul>
              <li onClick={() => document.getElementById('sidebar_doc').scrollIntoView()}>Sidebar</li>
              <li onClick={() => document.getElementById('searchbar_doc').scrollIntoView()}>Suchleiste</li>
            </ul>
            <li onClick={() => document.getElementById('devices_doc').scrollIntoView()}>Geräte</li>
            <li onClick={() => document.getElementById('user_dashboard_doc').scrollIntoView()}>User Dashboard</li>
            <li onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}>Architektur Management</li>
            <li onClick={() => document.getElementById('view_doc').scrollIntoView()}>Sichten</li>
            <li onClick={() => document.getElementById('component_doc').scrollIntoView()}>Komponenten</li>
            <ul>
              <li onClick={() => document.getElementById('component_doc').scrollIntoView()}>Graph</li>
              <li onClick={() => document.getElementById('component_doc').scrollIntoView()}>Parameter</li>
              <li onClick={() => document.getElementById('component_doc').scrollIntoView()}>Attribute</li>
            </ul>
            <li onClick={() => document.getElementById('module_doc').scrollIntoView()}>Module und Testarchitekturen</li>
            <li onClick={() => document.getElementById('degradation_rule_doc').scrollIntoView()}>Degradationsregeln</li>
          </ul>
        </CardBody>
      </Card>
    )
  }
}

export default Header