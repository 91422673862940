/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* Adapted by Marius Völkel

*/

import React from "react"

//  Submodules
import Views_Card from "./Views_Card"
import IO_types_Card from "./IO_types_Card"
import Modules_Card from "./Modules_Card"
import Templates_Card from "./Templates_Card"
import DegradationRules_Card from "./DegradationRules_Card"

import { DeviceContext } from 'context/DeviceContext'



class View_Management extends React.Component { 
  static contextType = DeviceContext

  render() {
    return (
      <>
        <div className="content">
          <Views_Card addRoute={this.props.addRoute} removeRoute={this.props.removeRoute} updateRoute={this.props.updateRoute} user_type_id={this.props.user_type_id}/>
          <Modules_Card  addRoute={this.props.addRoute} removeRoute={this.props.removeRoute} updateRoute={this.props.updateRoute} test_architecture={false} user_type_id={this.props.user_type_id}/>
          <Modules_Card  addRoute={this.props.addRoute} removeRoute={this.props.removeRoute} updateRoute={this.props.updateRoute} test_architecture={true} user_type_id={this.props.user_type_id}/>
          <IO_types_Card item_type={'internal_type'} user_type_id={this.props.user_type_id}/>
          <IO_types_Card item_type={'input_type'} user_type_id={this.props.user_type_id}/>
          <IO_types_Card item_type={'output_type'} user_type_id={this.props.user_type_id}/>
          <Templates_Card addRoute={this.props.addRoute} removeRoute={this.props.removeRoute} updateRoute={this.props.updateRoute} user_type_id={this.props.user_type_id}/>
          <DegradationRules_Card addRoute={this.props.addRoute} removeRoute={this.props.removeRoute} updateRoute={this.props.updateRoute} user_type_id={this.props.user_type_id}/>
        </div>
      </>
    )
  }
}

export default View_Management
