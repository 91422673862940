import React, { Component } from 'react'
import { Table, Row, Col, Input, CardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faAngleDown, faAngleUp, faPen, faCheck, faX, faPlus } from "@fortawesome/free-solid-svg-icons"
import { DeviceContext } from 'context/DeviceContext'
import { fetchRemove, fetchRemoveFrom, fetchEdit, fetchAddNew, fetchAddTo } from 'helpers/api.js'
import { handleRosTypes } from './../Forms/helpers/HandleRosTypes.js'
import { checkTopicNaming } from '../Forms/helpers/CheckTopicNaming.js'
import translate from 'helpers/translations.js'
import MultiColumnSelectInput from './Inputs/MultiColumnSelectInput.js'

class DataTableDeleteAndEdit extends Component {
  static contextType = DeviceContext
  
  constructor(props) {
    super(props)
    this.search = this.search.bind(this)
    this.state = {
      items: this.props.items,
      sort: {column: '', reverse: false},
      edit: {}
    }
    this.headerRef = React.createRef()
  }

  updateState = (updated_item) => {
    let items = this.state.items
    const index = items.findIndex(item => item[this.props.item_type + '_id'] === updated_item[this.props.item_type + '_id'])
    this.props.columns_DB.forEach(column => {
      if (column === 'full_name') {
        items[index][column] = updated_item['name'] + ', ' + updated_item['first_name']
      } else {
        items[index][column] = updated_item[column]
      }
    })
    this.setState({ items, edit: {} })
    this.props.updateState(updated_item)
  }

  deleteItem = (id) => {
    const item_to_delete = this.state.items.find(item => item[this.props.item_type + '_id'] === id)
    if (this.props.deleteFrom) {
      let confirm_delete = window.confirm(item_to_delete[this.props.columns_DB[0]] + ' entfernen?')
      const table_string = this.props.item_type
      if (confirm_delete) {
        fetchRemoveFrom(table_string, id)
        .then(item => {
          this.props.deleteChildItemFromState(id)
          this.setState({ items: this.state.items.filter(item => item[this.props.item_type + '_id'] !== id) })
        })
        .catch(err => console.log(err))
      }
    } else {
      let confirm_text = translate(this.props.item_type) + ' "' + item_to_delete[this.props.columns_DB[0]] + '" löschen?'
      if (this.props.item_type.split('_')[1] && this.props.item_type.split('_')[1] === 'type') {
        confirm_text = confirm_text + '\n\nAchtung! Dies löscht auch alle ' + translate(this.props.item_type.split('_')[0] + 's') + ' dieses Typs!'
      }
      let confirm_delete = window.confirm(confirm_text)
      const type_table = this.props.item_type + 's'
      if (confirm_delete) {
        fetchRemove(type_table, id)
        .then(item => {
          this.props.deleteItemFromState(id)
          this.setState({ items: this.state.items.filter(item => item[this.props.item_type + '_id'] !== id) })
        })
        .catch(err => console.log(err))
      }
    }
  }

  onChange = (e) => {
    const { name, type, value, checked } = e.target
    let edit_state = this.state.edit
    if (type === 'checkbox') edit_state.item[name] = checked
    else if (type === 'select-one') edit_state.item[name] = Number(value)
    else edit_state.item[name] = value

    if (edit_state.item['name']) edit_state.error = []
    this.setState({ edit: edit_state })
  }

  submitRow = () => {
    let mandatory_fields = ['email', 'full_name', 'name']
    mandatory_fields = mandatory_fields.filter(field => this.props.columns_DB.includes(field))
    let empty_fields = []
    mandatory_fields.forEach(field => {
      if (!this.state.edit.item[field] || this.state.edit.item[field] === '') {
        empty_fields.push(field)
      }
    })
    // if a mandatory field is not set show an error
    if (empty_fields.length > 0) {
      let edit_state = this.state.edit
      edit_state.error = empty_fields
      this.setState({ edit: edit_state })
    // submit
    } else {
      let attributes = {}
      this.props.columns_DB.forEach(column => {
        let edit_entry = this.state.edit.item[column]
        if (column === 'full_name' && typeof edit_entry === 'string') {
          attributes['name'] = edit_entry.split(',')[0]
          attributes['first_name'] = edit_entry.split(',')[1] ? edit_entry.split(',')[1].trim() : ''
        } else if (typeof edit_entry === 'string') {
          attributes[column] = edit_entry.trim()
        } else if (column !== this.props.item_type + '_id') {
          attributes[column] = edit_entry
        }
      })
      // use the correct fetch function
      // special case for new components
      if (this.state.edit.new && this.props.createComponent) {
        // TODO: template_id should be used instead of template_name
        if (attributes['template_name'] !== 0) {
          attributes['template_id'] = attributes['template_name']
        }
        delete attributes['template_name']
        this.props.createComponent(attributes)
        this.setState({ edit: {} })
      // fetchAddTo
      } else if (this.state.edit.new && this.props.addTo) {
        const child_id = attributes[Object.keys(this.props.select)[0]]
        // get the right table name (needed for inputs_output)
        let table_name
        if (this.props.inverted_table_name) {
          table_name = this.props.child_type + 's_' + this.props.parent_type
        } else {
          table_name = this.props.parent_type + 's_' + this.props.child_type
        }
        // check if in a ros-view input and output have the same name
        let confirm = true
        // currently not needed because ros_views are disabled, if reactivated, check if everything works (currently props.components and props.views do not exist)
        /*if (table_name === 'inputs_output') {
          const input_id = this.props.inverted_table_name ? child_id : this.props.parent_id
          const output_id = this.props.inverted_table_name ? this.props.parent_id : child_id

          const output_component_id = this.props.outputs.find(output => output.output_id === output_id)
          const input_component_id = this.props.inputs.find(input => input.input_id === input_id)
          const output_view_id = this.props.components.find(comp => comp.component_id === output_component_id)
          const input_view_id = this.props.components.find(comp => comp.component_id === input_component_id)
          const output_is_ros = this.props.views.find(view => view.view_id === output_view_id).ros2_view
          const input_is_ros = this.props.views.find(view => view.view_id === input_view_id).ros2_view
          if (input_is_ros && output_is_ros) {
            confirm = checkTopicNaming(input_id, output_id, this.props.inputs, this.props.inputs_attribute_sum, this.props.outputs, this.props.outputs_attribute_sum)
          }
        }*/
        if (confirm) {
          fetchAddTo(this.context.device, this.props.parent_id, this.props.parent_type, child_id, this.props.child_type, table_name)
          .then(item => {
            if (Array.isArray(item)) {
              this.props.addItemToState(item[0])
              if (this.props.toggle_form) {
                this.props.toggle()
              }
            } else {
              console.log('failure')
            }
          })
          .catch(err => console.log(err))
        }
        this.setState({ edit: {} })
      // fetchAddNew
      } else if (this.state.edit.new) {
        if (this.props.flags) {
          this.props.flags.forEach((flag) => {
            attributes[flag] = true
          })
        }
        fetchAddNew(attributes['is_template'] ?  {} : this.context.device, this.props.item_type + 's', attributes)
        .then((item) => {
          let items = this.state.items
          this.setState({ edit: {} })
          this.props.addItemToState(item[0])
          // if a ros-view is added handle pub/sub/etc.-types
          if (this.props.item_type === 'view' && attributes.ros2_view) {
            console.log('handle ros-specific types')
            handleRosTypes(this.context.device, item[0].view_id, items, this.props.addItemToState)
          }
        })
        .catch((err) => console.log(err))
      // fetchEdit
      } else {
        fetchEdit(this.props.item_type + 's', undefined, this.state.edit.item[this.props.item_type + '_id'], attributes)
        .then(item => {
          this.updateState(item[0])
        })
        .catch(err => console.log(err))
      }
    }
  }

  sort = (column) => {
    let sorted_items = this.state.items
    // sort items by given column
    sorted_items.sort((row1, row2) => {
      if (!row1[column]) {
        return 1
      } else if (!row2[column]) {
        return -1
      } else {
        return row1[column].toString().localeCompare(row2[column].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
      }
    })
    // if function is called on the same column as last time, reverse the array
    let reverse = false
    if (column === this.state.sort.column) {
      reverse = !this.state.sort.reverse
    }
    if (reverse) {
      sorted_items.reverse()
    }
    this.setState({ sort: {column: column, reverse: reverse}, items: sorted_items })
  }

  search = (string) => {
    let searched_items = this.state.items.filter(item => {
      let included = false
      Object.entries(item).forEach(entry => {
        if (this.props.columns_DB.includes(entry[0])) {
          if (typeof entry[1] === 'string') {
            entry[1].split(' ').forEach(sub_string => {
              if (sub_string.toLowerCase().startsWith(string.toLowerCase())) included = true
            })
            return
          } else if (typeof entry[1] === 'number' && entry[1].toString().startsWith(string)) {
            included = true
            return
          }
        }
      })
      return included
    })
    // reapply the sorting order
    if (this.state.sort.column !== '') {
      searched_items.sort((row1, row2) => {
        let column = this.state.sort.column
        return row1[column].toString().localeCompare(row2[column].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
      })
    }
    if (this.state.sort.reverse) {
      searched_items.reverse()
    }
    this.setState({ items: searched_items})
  }

  componentDidMount() {
    if (this.props.searchTable) {
      this.props.searchTable(this.search)
    }

    let items = this.props.items
    if (items.length > 0) {
      if (items[0]['name']) {
        items = items.sort((row1, row2) => {
          return row1['name'].toString().localeCompare(row2['name'].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
        })
      } else if (items[0][this.props.item_type + '_name']) {
        items = items.sort((row1, row2) => {
          return row1[this.props.item_type + '_name'].toString().localeCompare(row2[this.props.item_type + '_name'].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
        })
      }
      this.setState({ items })
    }

    window.addEventListener('resize', this.rerender)
  }

  componentDidUpdate(prevProps) {
    let items = this.props.items
    if (items !== prevProps.items) {
      if (items.length > 0 && items[0]['name']) {
        items = items.sort((row1, row2) => {
          return row1['name'].toString().localeCompare(row2['name'].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
        })
      } else if (items.length > 0 && items[0][this.props.item_type + '_name']) {
        items = items.sort((row1, row2) => {
          return row1[this.props.item_type + '_name'].toString().localeCompare(row2[this.props.item_type + '_name'].toString(), undefined, {sensitivity: 'base', numeric: 'true'})
        })
      }
      this.setState({ items })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.rerender)
  }

  // used to update the widths of the MultiColumnSelectInput
  rerender = () => {
    this.setState(this.state)
  }

  render() {
    const edit_state = this.state.edit
    let items = this.state.items
    if (edit_state.new) items = [{}].concat(items)
    let rows = []
    const error_style = edit_state.error ? {borderColor: 'red', boxShadow: '0 0 5px red'} : {} // error style of input field
    rows = items.map((item, row_index) => {
      const new_row = edit_state.new && row_index === 0
      const edit_row = edit_state.row === row_index
      // populate table
      let entry = []
      let counter = 0
      entry = this.props.columns_DB.map((column, col_index) => {
        // special case for user management
        if (column === 'user_type_id') {
          let role
          switch (item[column]) {
            case 0:
              role = 'Gast'
              break
            case 1:
              role = 'Entwickler'
              break
            case 2:
              role = 'Administrator'
              break
            default:
              role = 'Undefiniert'
              break
          }
          if (new_row || edit_row) {
            return (
              <td scope="row" key={col_index}>
                <Input
                  type="select"
                  name={'user_type_id'}
                  onChange={this.onChange}
                  defaultValue={item[column]}
                >
                  <option value='0'>Gast</option>
                  <option value='1'>Entwickler</option>
                  <option value='2'>Administrator</option>
                </Input>
              </td>
            )
          } else {
            return <td scope="row" key={col_index}>{role}</td>
          }
        } else if (edit_row && this.props.no_edit && this.props.no_edit.includes(column)) {
          return <td scope="row" key={col_index}>{item[column]}</td>
        // case for select
        } else if ((new_row || edit_row) && this.props.select && Object.keys(this.props.select).includes(column)) {
          // get column widths
          let col_widths = []
          Object.entries(this.headerRef.current.children).forEach(([key, entry]) => {
            if (key >= col_index && key < col_index + this.props.select[column].col_width) {
              col_widths.push(entry.clientWidth)
            }
          })
          // decrease width of last column because of padding
          col_widths[col_widths.length-1] -= 17
          return(
            <td scope="row" key={col_index} colSpan={this.props.select[column].col_width} style={{paddingLeft: 4, paddingRight: 4}}>
              <MultiColumnSelectInput
                name={column}
                items={this.props.select[column].items}
                columns={this.props.select[column].columns}
                col_widths={col_widths}
                item_type={this.props.child_type}
                autoFocus={counter===1}
                onChange={(id) => this.onChange({target: {name: column, value: id}})}
                style={edit_state.error && edit_state.error.includes(column) ? error_style : {}}
              />
            </td>
          )
        // case for booleans
        } else if (this.props.bools && this.props.bools.includes(column)) {
          if (new_row || (edit_row && column !== 'ros2_view')) {
            return (
              <td scope="row" key={col_index}>
                <Input
                  type="checkbox"
                  name={column}
                  onChange={this.onChange}
                  defaultChecked={item[column]}
                  style={{ marginLeft: '17px', height: '25px', width: '25px', position: 'relative', verticalAlign: 'middle', backgroundColor: edit_state.item[column] ? 'rgb(133, 209, 204)' : '' }}
                />
              </td>
            )
          } else {
            return (
              <td scope="row" key={col_index} style={{paddingLeft: '30px'}}>
                {item[column] ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}
              </td>
            )
          }
        // case for adding a new row or editing a row
        } else if ((new_row || edit_row) && column !== this.props.item_type + '_id' && (!this.props.select || !Object.values(this.props.select).some(value => value.columns.includes(column)))) {
          counter++
          return (
            <td scope="row" key={col_index}>
              <Input
                type={this.props.textarea ? 'textarea' : 'text'}
                name={column}
                autoFocus={counter===1}
                onChange={this.onChange}
                value={edit_state.item[column] || ''}
                placeholder={this.props.columns[col_index]}
                style={edit_state.error && edit_state.error.includes(column) ? error_style : {}}
              />
            </td>
          )
        } else if (new_row || edit_row) {
        // case for devices
        } else if (this.props.item_type === 'device' && column === 'name') {
          return <td scope="row" key={col_index}><a style={{cursor: 'pointer' }} onClick={async () => {await this.context.updateDevice(item); this.props.refreshRoutes()}}>{item[column]}</a></td>
        // case for entries with links
        } else if (this.props.links && Object.keys(this.props.links).includes(column)) {
          // props.links should be of the form {<entry of columns_DB that should have link>: {id: <DB column that holds id of link destination>, name: <type of the linked item>}, ...}
          const link = "/admin/" + this.props.links[column].type + "_" + item[this.props.links[column].id]
          return <td scope="row" key={col_index}><Link style={{display:'block'}} to={link}>{item[column]}</Link></td>
        // case for emails
        } else if (column === 'email') {
          return <td scope="row" key={col_index}><a href={'mailto:' + item[column]}>{item[column]}</a></td>
        // default case
        } else {
          return <td scope="row" key={col_index}>{item[column]}</td>
        }
      })

      let edit_btn = null
      if (new_row || edit_row) {
        entry.push(
          <td key={'submit_btn'} style={{width: '92px', textAlign: 'center'}}>
            <button onClick={() => this.submitRow()} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faCheck} /></button>
            <button onClick={() => this.setState({ edit: {} })} className='btn-minimal' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faX} /></button>
          </td>
        )
      } else if (this.props.withEdit) {
        let edit_item = { ...item } // create copy of item to avoid changing it in the props array
        edit_btn = <button onClick={() => this.setState({ edit: {row: row_index, item: edit_item} })} className='btn-minimal btn-edit' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faPen} /></button>
      }
      
      if ((this.props.withEdit || this.props.withDelete) && !(new_row || edit_row)) {
        let delete_btn = null
        if (this.props.withDelete) delete_btn = <button onClick={() => this.deleteItem(item[this.props.item_type + '_id'])} className='btn-minimal btn-trash' hidden={this.props.user_type_id === 0}><FontAwesomeIcon icon={faTrash} /></button>
        entry.push(
          <td key={'edit_btn'} style={{width: '92px', textAlign: 'center'}}>{edit_btn}{delete_btn}</td>
        )
      }

      return <tr key={row_index}>{entry}</tr>
    })

    // table header
    let header = []
    header = this.props.columns.map((column, index) => {
      if (this.props.columns_DB[index] === this.state.sort.column) {
        let symbol = this.state.sort.reverse? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />
        return (
          <th key={index}><button style={{all: 'inherit', cursor: 'pointer', padding: '0'}} onClick={() => this.sort(this.props.columns_DB[index])}>{column} {symbol}</button></th>
        )
      } else {
        return (
          <th key={index}><button style={{all: 'inherit', cursor: 'pointer', padding: '0'}} onClick={() => this.sort(this.props.columns_DB[index])}>{column}</button></th>
        )
      }
    })

    if (this.props.withEdit || this.props.withDelete) {
      header.push(<th key={'edit'} style={{width: '80px'}}>Bearbeiten</th>)
    }

    return (
      <>
        {this.props.title ?
          <>
            <CardTitle tag={this.props.title_size ? this.props.title_size : 'h4'}>
              <button onClick={() => this.setState({ edit: {new: true, item: {}} })} className='btn-minimal btn-add' hidden={this.props.user_type_id === 0}>
                <FontAwesomeIcon icon={faPlus}/>
              </button>
              {this.props.title}
            </CardTitle>
          </> : null
        }
        {/* height = 0 is a workaround to ensure the headerRef is set without showing the table even when it is empty */}
        <div style={{ maxHeight: this.props.maxHeight, overflowY: 'auto', height: rows.length === 0 ? '0px' : '' }}>
          <Table className="dataTableDeleteAndEdit">
            <thead className="text-primary"><tr ref={this.headerRef}>{header}</tr></thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      </>
    )
  }
}
export default DataTableDeleteAndEdit