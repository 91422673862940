import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Component_Doc extends Component {

  render() {
    return (
      <Card id='component_doc'>
        <CardHeader>
          <CardTitle tag='h4'>Komponenten</CardTitle>
        </CardHeader>
        <CardBody>
          <CardTitle tag='h5'>Graph-Darstellung und graphisches User Interface</CardTitle>
          <CardTitle tag='h5'>Parameter</CardTitle>
          <CardTitle tag='h5'>Inputs, Outputs und Internals</CardTitle>
          <CardTitle tag='h5'>Attribute</CardTitle>
        </CardBody>
      </Card>
    )
  }
}

export default Component_Doc