import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit.js'
import { Card, CardBody } from "reactstrap"
import { fetchGetData, fetchRemove } from 'helpers/api'
import { DeviceContext } from 'context/DeviceContext'
import translate from 'helpers/translations'
class IO_types_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.devie, this.props.item_type + 's')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({items})
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (new_item) => {
    let items = this.state.items
    items.unshift(new_item)
    this.setState({ items })
  }

  updateState = (updated_item) => {
    let items = this.state.items
    items.find(item => item[this.props.item_type + '_id'] === updated_item[this.props.item_type + '_id'])['name'] = updated_item['name']
    this.setState({ items })
  }

  deleteItemFromState = async (deleted_id) => {
    const updatedItems = this.state.items.filter(item => item[this.props.item_type + '_id'] !== deleted_id)
    // also delete all inputs/internals/outputs of that type
    let io = this.props.item_type.split('_')[0] // input/internal/outpu
    let io_items = await fetchGetData(this.context.device, io + 's')
    io_items.forEach(io_item => {
      if (io[this.props.item_type + '_id'] === deleted_id) {
        fetchRemove(io + 's', io_item[io + '_id'])
      }
    })
    this.setState({ items: updatedItems })
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={translate(this.props.item_type + 's')}
            items={this.state.items}
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type={this.props.item_type}
            columns={['Name']}
            columns_DB={['name']}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
            maxHeight={'300px'}
          />
        </CardBody>
      </Card>
    )
  }
}
export default IO_types_Card