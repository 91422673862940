import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { Card, CardBody } from "reactstrap"
import { DeviceContext } from 'context/DeviceContext'
import { fetchGetData } from 'helpers/api'

class Components_Card extends Component {
  static contextType = DeviceContext

  state = {
    components:[],
    views: []
  }

  async getItems() {
    const tables = ['components', 'views']
    const items = await Promise.all(tables.map(table => {
      return fetchGetData({}, table)
    }))
    items.forEach((item, index) => {
      if (item.dataExists !== 'false') {
        this.setState({ [tables[index]]: item })
      }
    })
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let module_components = []
    let components = []
    this.state.components.forEach(component => {
      const module_component = this.props.modules_component.find(item => item['component_id'] === component['component_id'])
      const view = this.state.views.find(view => view['view_id'] === component['view_id'])
      component['modules_component_id'] = module_component ? module_component['modules_component_id'] : 0
      component['view_name'] = view ? view['name'] : ''
      component['device_names'] = component.devices.map(device => device.name).join(', ')
      if (module_component && module_component['module_id'] === this.props.module_id) {
        module_components.push(component)
      } else {
        components.push(component)
      }
    })

    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={'Komponenten'}
            items={module_components}
            addItemToState={this.props.addItemToState}
            deleteChildItemFromState={this.props.deleteItemFromState}
            columns={['Name', 'Sicht', 'Geräte']}
            columns_DB={['name', 'view_name', 'device_names']}
            select={{'name': {items: components, col_width: 3, columns: ['name', 'view_name', 'device_names']}}}
            item_type={'modules_component'}
            parent_type={'module'}
            parent_id={this.props.module_id}
            child_type={'component'}
            links={{'name': {'id': 'component_id', 'type': 'component'}, 'view_name': {'id': 'view_id', 'type': 'view'}}}
            withDelete={!(this.props.user_type_id === 0)}
            addTo={true}
            deleteFrom={true}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Components_Card