import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Architecture_Management_Doc extends Component {

  render() {
    return (
      <Card id='architecture_management_doc'>
        <CardHeader>
          <CardTitle tag="h4">Architektur Management</CardTitle>
        </CardHeader>
        <CardBody>
          Die Architektur Management Seite dient dem Zweck die übergreifenden und wederkehrenden Strukturen der Architektur zu modifizieren.
          Hier ist es möglich
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Sichten</a>,
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Module</a>,
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Testarchitekturen</a>,
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Internal-</a>,
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Input-</a> und
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Outputtypen</a>,
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Attributtypen</a> sowie
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Templates</a> und
          <a onClick={() => document.getElementById('architecture_management_doc').scrollIntoView()}> Degradationsregeln </a> zu verwalten. <br />
          Diese Elemente werden jeweils getrennt voneinander in Tabellen dargestellt.
          Jede Tabelle bietet die Möglichkeit über die Plus-Schaltfläche neue Elemente zu erstellen, sie über die Stift-Schaltfläche zu
          editieren und mit der Papierkorb-Schaltfläche zu löschen.
        </CardBody>
      </Card>
    )
  }
}

export default Architecture_Management_Doc