import React, { Component } from 'react'
import 'react-medium-image-zoom/dist/styles.css'
import Trash_SVG from 'assets/fontawesome_svgs/trash.svg'
import Pen_SVG from 'assets/fontawesome_svgs/pen.svg'
import Plus_SVG from 'assets/fontawesome_svgs/plus.svg'
import Info_SVG from 'assets/fontawesome_svgs/info.svg'
import 'cytoscape-context-menus/cytoscape-context-menus.css'
import translate from 'helpers/translations'

import Cytoscape_Card from 'cytoscape/Cytoscape_Card'

class Graph_Card extends Component {
  state = {
    elements: [],
    contextmenu_items:  [],
    cy_initialized: false,
    default_layout: true
  }

  componentDidMount() {
    this.cardRef = React.createRef()
  }

  componentDidUpdate(prev_props) {
    if (this.props.data_loaded && !this.state.cy_initialized) {
      this.buildCytoscapeGraph()
    } else if (prev_props.context !== this.props.context) {
      // force a rebuild of the graph when the context is changed
      this.setState({ cy_initialized: false })
    } else if (this.state.cy_initialized && prev_props !== this.props) {
      let elements = this.state.elements;
      ['component', 'input', 'output', 'inputs_output'].map(item_type => {
        const prop_items = item_type === 'inputs_output' ? this.props[item_type] : this.props[item_type + 's']
        const prev_items = item_type === 'inputs_output' ? prev_props[item_type] : prev_props[item_type + 's']
        if (prop_items !== prev_items) {
          const prop_ids = prop_items.map(item => item[item_type + '_id'])
          const prev_ids = prev_items.map(item => item[item_type + '_id'])
          const new_items = prop_items.filter(item => !prev_ids.includes(item[item_type + '_id']))
          const deleted_item_ids = prev_ids.filter(id => !prop_ids.includes(id))
          if (new_items.length > 0) {
            elements = elements.filter(elem => elem.data.id !== 'info')
            elements = elements.concat(this.createGraphElements(item_type, new_items, elements))
          }
          if (deleted_item_ids.length > 0) {
            deleted_item_ids.forEach(id => {
              if (item_type === 'component') {
                elements = elements.filter(elem => {
                  let parent = elem.data.id === 'component-' + id + '-parent'
                  let children = elem.data.parent === 'component-' + id + '-parent'
                  let edges = elem.data.source === 'component-' + id || elem.data.target === 'component-' + id
                  return !parent && !children && !edges
                })
              } else {
                elements = elements.filter(elem => elem.data.id !== item_type + '-' + id && elem.data.source !== item_type + '-' + id && elem.data.target !== item_type + '-' + id)
              }
            })
            if (elements.length === 0) {
              elements = [{ data: { id: 'info',
                                    label: 'Diese Sicht hat keine Komponenten.\nRechtsklick um eine neue Komponente zu erstellen.' },
                            style: { width: 400 },
                            selectable: false }]
            }
          }
        }
      })

      this.setState({ elements, contextmenu_items: this.createContextMenu() })
    }
  }

  // builds the initial graph by creating all nodes and edges as well as the contextmenu
  buildCytoscapeGraph() {
    let current_components = this.props.view_components.filter(comp => comp.devices.map(device => device.device_id).includes(this.props.context.device.device_id))

    let component_nodes = this.createGraphElements('component', current_components, [])

    let input_nodes = this.createGraphElements('input', this.props.inputs, component_nodes)
    let output_nodes = this.createGraphElements('output', this.props.outputs, component_nodes)

    let edges = this.createGraphElements('inputs_output', this.props.inputs_output, input_nodes.concat(output_nodes))

    let elements = []
    if (component_nodes.length === 0) {
      elements = [{ data: { id: 'info',
                            label: 'Diese Sicht hat keine Komponenten.\nRechtsklick um eine neue Komponente zu erstellen.' },
                    style: { width: 400 },
                    selectable: false }]
    } else {
      elements = component_nodes.concat(input_nodes).concat(output_nodes).concat(edges)
    }

    const contextmenu_items = this.createContextMenu()
    this.setState({ cy_initialized: true, elements, contextmenu_items })
  }

  createContextMenu() {
    const component_ids = this.props.view_components.map(comp => comp.component_id)
    const cytoscape_card = this.cardRef.current
    let contextmenu_items = [
        {
          id: 'details',
          content: 'Details',
          image: {src: Info_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'node[id != "info"]',
          onClickFunction: (event) => {
            const cy_id = event.target.data('id')
            const item_type = cy_id.split('-')[0] // input, output or internal
            const io_id = Number(cy_id.split('-')[1])
            this.props.scrollToSubcard(item_type, io_id)
          }
        },
        {
          id: 'delete',
          content: 'Löschen',
          image: {src: Trash_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: '.connections, node[id != "info"]',
          onClickFunction: (event) => {
            cytoscape_card.deleteSelectedElements()
          }
        },
        {
          id: 'rename',
          content: 'Umbenennen',
          image: {src: Pen_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'node.components, node.inputs, node.outputs',
          onClickFunction: (event) => {
            let cy_id = event.target.data('id')
            let item_type = cy_id.split('-')[0] // input, output or internal
            cytoscape_card.startRename(cy_id, item_type, {})
          }
        },
        {
          id: 'new_component',
          content: 'Neue Komponente',
          image: {src: Plus_SVG, width: 14, height: 14, x: 5, y: 4},
          selector: 'node#info',
          coreAsWell: true,
          onClickFunction: (event) => {
            cytoscape_card.createNewNode(event.position, 'component', null, null)
          }
        },
        ['input', 'output'].map(item_type => {
          const submenu_items = this.props['views_' + item_type + '_type_sum'].filter(item => item.view_id === this.props.view.view_id).map(io_type => {
            return {
              id: 'new_' + item_type + '_' + io_type[item_type + '_type_id'],
              content: io_type[item_type + '_type_name'],
              onClickFunction: (event) => {
                let parent_id = event.target.data('id').split('-')[1]
                cytoscape_card.createNewNode(event.position, item_type, io_type, parent_id)
              }
            }
          })
          return {
            id: 'new_' + item_type,
            content: 'Neuer ' + translate(item_type),
            image: submenu_items.length > 0 ? {src: Plus_SVG, width: 14, height: 14, x: 5, y: 4} : {},
            tooltipText: submenu_items.length > 0 ? '' : 'Kein ' + translate(item_type) + 'typ verfügbar',
            selector: 'node.components, node.compound-parents',
            disabled: submenu_items.length === 0,
            submenu: submenu_items.length > 0 ? submenu_items : null
          }
        }),
        ['input', 'output'].map(item_type => {
          return this.props[item_type + 's'].filter(item => component_ids.includes(item.component_id)).map(item => {
            const id =  item[item_type + '_id']
            const node_id = item_type + '-' + id
            const other_type = item_type === 'input' ? 'output' : 'input'
            const submenu_items = this.props[other_type + 's'].filter(other_item => {
              const connection = this.props.inputs_output.find(connection =>
                connection[other_type + '_id'] === other_item[other_type + '_id'] && connection[item_type + '_id'] === id)
              return !connection
            }).map(item => {
              const component = this.props.all_components.find(comp => comp.component_id === item.component_id)
              const component_name = component ? component.name : ''
              return {
                id: 'connect_' + node_id + '_' + other_type + '-' + item[other_type + '_id'],
                content: component_name + ': ' + item.name,
                onClickFunction: (event) => {
                  const output_id = item_type === 'output' ? id : item['output_id']
                  const input_id = item_type === 'output' ? item['input_id'] : id
                  this.props.createConnection(output_id, input_id)
                }
              }
            })
            return {
              id: 'connect_' + node_id,
              content: 'Neue Verbindung',
              image: submenu_items.length > 0 ? {src: Plus_SVG, width: 14, height: 14, x: 5, y: 4} : {},
              tooltipText: submenu_items.length > 0 ? 'Stellt eine Verbindung zwischen diesem ' + translate(item_type) + ' und einem ' + translate(other_type) + ' einer anderen Komponente her'
                                                    : 'Keine geeigneten Elemente verfügbar',
              selector: '#' + node_id,
              disabled: submenu_items.length === 0,
              submenu: submenu_items.length > 0 ? submenu_items : null
            }
          })
        })
      ].flat(2)

    return contextmenu_items
  }

  createGraphElements(item_type, new_items, elements) {
    let saved_nodes = this.props.view.graph ? this.props.view.graph.elements.nodes : []
    let new_elements = []

    if (item_type === 'component') {
      new_items.forEach(component => {
        let saved_node = saved_nodes.find(node => node.data.id === 'component-' + component.component_id)
        let position
        if (saved_node) {
          this.setState({ default_layout: false })
          position = { x: Number(saved_node.position.x), y: Number(saved_node.position.y) }
        } else {
          position = { x: Math.round((Math.random()+1)*(this.cardRef.current.cy.width()/3)), y: Math.round((Math.random()+1)*(this.cardRef.current.cy.height()/3)) }
        }

        new_elements.push({ classes: 'compound-parents',
                            data: { id: 'component-' + component.component_id + '-parent' } })
        new_elements.push({ classes: 'components',
                            data: { id: 'component-' + component.component_id,
                                    label: 'Komponente:\n' + component.name, href: '/admin/component_' + component.component_id,
                                    parent: 'component-' + component.component_id + '-parent' },
                            style: { width: Math.max(11, component.name.length)*9 },
                            position: position })
      })
    } else if (item_type === 'inputs_output') {
      new_items.forEach(connection => {
        const source_id = 'output-' + connection.output_id
        const target_id = 'input-' + connection.input_id
        if (elements.find(el => el.data['id'] === source_id) && elements.find(el => el.data['id'] === target_id)) {
          new_elements.push({ classes: 'connections',
                              data: { id: 'inputs_output-' + connection.inputs_output_id, source: source_id, target: target_id } })
        }
      })
    } else {
      // inputs and outputs
      let io_type_ids = this.props[item_type + '_types'].map(item => item[item_type + '_type_id'])
      new_items.forEach(item => {
        if (io_type_ids.includes(item[item_type + '_type_id'])) {
          const cy_component_id = 'component-' + item.component_id
          const component = elements.find(el => el.data['id'] === cy_component_id)
          if (component) {
            const io_type = this.props[item_type + '_types'].find(io_type => io_type[item_type + '_type_id'] === item[item_type + '_type_id'])
            const io_type_name = io_type ? io_type.name : ''
            const width = Math.max(io_type_name.length, item.name.length, 6)*9

            // get or calculate the position of the node
            let saved_node = saved_nodes.find(node => node.data.id === item_type + '-' + item[item_type + '_id'])
            let pos
            if (saved_node) {
              pos = { x: Number(saved_node.position.x), y: Number(saved_node.position.y) }
            } else {
              const offset_x = component.style.width/2 + width/2 + 100
              const component_ios = new_items.filter(io => io.component_id === item.component_id)
              const index = component_ios.findIndex(io => io[item_type + '_id'] === item[item_type + '_id'])
              pos = {...component.position}
              pos.x = item_type === 'input' ? pos.x - offset_x : pos.x + offset_x
              pos.y = pos.y + (index - component_ios.length/2 + 1/2) * 70
            }

            new_elements.push({ classes: item_type + 's',
                                data: { id: item_type + '-' + item[item_type + '_id'],
                                        label: io_type_name + ':\n' + item.name,
                                        parent: 'component-' + item.component_id + '-parent' },
                                style: { width: width },
                                position: pos })

            const source_id = item_type === 'output' ? cy_component_id : 'input-' + item.input_id
            const target_id = item_type === 'output' ? 'output-' + item.output_id : cy_component_id
            if (!elements.find(el => el.data['source'] === source_id && el.data['target'] === target_id)) {
              new_elements.push({ data: { source: source_id, target: target_id }, selectable: false })
            }
          }
        }
      })
    }

    return new_elements
  }

  createNewNode(cy, position, item_type, io_type, parent_id) {
    let new_elements = []
    if (item_type === 'component') {
      new_elements.push({ classes: 'compound-parents new',
                          data: { id: 'new-parent' } })
      new_elements.push({ 
        classes: item_type + 's new',
        data: { id: 'new', label: 'Komponente:\n', parent: 'new-parent' },
        style: { width: 100 },
        position: position
      })
    } else if (item_type === 'input' || item_type === 'output') {
      // calculate the position of the new node
      const component_node = cy.$('#component-' + parent_id)
      const width = io_type[item_type + '_type_name'].length*10
      let pos = {...component_node.position()}
      const offset_x = Number(component_node.style().width.slice(0,-2))/2 + width/2 + 54
      const other_nodes = item_type === 'input' ? component_node.incomers() : component_node.outgoers()
      let max_y
      other_nodes.forEach(node => {
        if (!max_y || node.position().y > max_y) {
          max_y = node.position().y
        }
      })
      pos.x = item_type === 'input' ? pos.x - offset_x : pos.x + offset_x
      pos.y = max_y ? max_y + 80 : pos.y

      new_elements.push({
        classes: item_type + 's new',
        data: { id: 'new', label: io_type[item_type + '_type_name'] + ':\n', parent: 'component-' + parent_id + '-parent' },
        style: { width: width },
        position: pos
      })
      if (item_type === 'input') {
        new_elements.push({ data: { source: 'new', target: 'component-' + parent_id } })
      } else {
        new_elements.push({ data: { source: 'component-' + parent_id, target: 'new' } })
      }
    }
    return new_elements
  }

  deleteGraphElements = (elements) => {
    let items_to_delete = []
    let items_to_restore = [] // items that will automatically be deleted, but have to be saved to restore them when necessary

    const compound_parents = elements.filter('.components').parents()
    const elements_to_delete = elements.union(compound_parents.children())

    elements_to_delete.forEach(element => {
      const cy_id = element.data('id')
      const type = cy_id.split('-')[0]
      const id = Number(cy_id.split('-')[1])

      if (type === 'inputs_output') {
        items_to_delete.push({item_type: type, id: id})
      } else if (type === 'component') {
        items_to_delete.push({item_type: type, id: id})
        // get all inputs, internals and outputs of the component
        let internals = this.props.internals.filter(internal => internal.component_id === id)

        internals.forEach(item => {
          items_to_delete.push({item_type: 'internal', id: item['internal_id']})
          // save internal connections and connections between inputs and outputs to later be able to restore them
          let internal_connections = this.props.internal_connections.filter(item => item['origin_internal_id'] === item['internal_id'] || item['destination_internal_id'] === item['internal_id'])
          internal_connections.forEach(item => {
            items_to_restore.push({item_type: 'internal_connection', id: item.internal_connections_id})
          })
        })
      } else if (type === 'input' || type === 'output') {
        items_to_delete.push({item_type: type, id: id})
        // save internal connections and connections between inputs and outputs to later be able to restore them
        let internal_connections = this.props.internal_connections.filter(item => item['origin_' + type + '_id'] === id || item['destination_' + type + '_id'] === id)
        internal_connections.forEach(item => {
          items_to_restore.push({item_type: 'internal_connection', id: item.internal_connections_id})
        })
        let connections = this.props.inputs_output.filter(item => item[type + '_id'] === id)
        connections.forEach(item => {
          items_to_restore.push({item_type: 'inputs_output', id: item.inputs_output_id})
        })
      }
    })

    if (items_to_delete.length > 0) {
      this.props.deleteItems(items_to_delete, true)
    }
    return items_to_delete.concat(items_to_restore)
  }

  render() {
    let current_components = this.props.view_components.filter(comp => comp.devices.map(device => device.device_id).includes(this.props.context.device.device_id))

    return (
      <Cytoscape_Card
        addIO={this.props.addIO}
        attributes={this.props.attributes}
        components={this.props.components}
        context={this.props.context}
        contextmenu_items={this.state.contextmenu_items}
        createComponent={this.props.createComponent}
        createConnection={this.props.createConnection}
        createNewNode={this.createNewNode}
        default_height={current_components.length*100}
        deleteGraphElements={this.deleteGraphElements}
        deleteItems={this.props.deleteItems}
        elements={this.state.elements}
        graph_id={this.props.view.view_id}
        graph_type={'view'}
        initialized={this.state.cy_initialized}
        inputs={this.props.inputs}
        inputs_output={this.props.inputs_output}
        inputs_attribute_sum={this.props.inputs_attribute_sum}
        internals={this.props.internals}
        outputs={this.props.outputs}
        outputs_attribute_sum={this.props.outputs_attribute_sum}
        ref={this.cardRef}
        restoreItems={this.props.restoreItems}
        updateParent={this.props.updateView}
        updateState={this.props.updateState}
        use_layout={this.state.default_layout}
      />
    )
  }
}
export default Graph_Card