import React, { Component } from 'react'
import { Card, CardBody } from "reactstrap"
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { fetchGetData } from 'helpers/api.js'
import { DeviceContext } from 'context/DeviceContext'

class Modules_Card extends Component {
  static contextType = DeviceContext

  state = {
    items: []
  }

  getItems() {
    fetchGetData(this.context.device, 'modules')
    .then(items => {
      if (items.dataExists !== 'false') {
        this.setState({ items: items.filter(item => item.test_architecture === this.props.test_architecture) })
      }
    })
    .catch(err => console.log(err))
  }

  addItemToState = (new_item) => {
    this.setState({ items: this.state.items.concat([new_item]) })
    this.props.addRoute('module', new_item)
  }

  updateState = (updated_item) => {
    let items = this.state.items
    const item_index = items.findIndex(item => item['module_id'] === updated_item['module_id'])
    items[item_index] = updated_item
    this.setState({ items })
    this.props.updateRoute('module', updated_item['module_id'], updated_item['name'])
  }

  deleteItemFromState = (id_to_delete) => {
    this.setState({ items: this.state.items.filter(item => item['module_id'] !== id_to_delete) })
    this.props.removeRoute('module', id_to_delete)
  }

  componentDidMount() {
    this.getItems()
  }

  render() {
    let columns = ['Name', 'Beschreibung']
    let columns_DB = ['name', 'description']
    if (this.props.test_architecture) {
      columns = ['Name', 'Beschreibung', 'Kategorisierung', 'Bewertungsmetrik']
      columns_DB = ['name', 'description', 'categorization', 'metric']
    }
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={this.props.test_architecture ? 'Testarchitekturen' : 'Module'}
            items={this.state.items} 
            addItemToState={this.addItemToState}
            updateState={this.updateState} 
            deleteItemFromState={this.deleteItemFromState}
            item_type='module'
            columns={columns}
            columns_DB={columns_DB}
            links={{'name': {'id': 'module_id', 'type': 'module'}}}
            flags={this.props.test_architecture ? ['test_architecture'] : []}
            withEdit={!(this.props.user_type_id === 0)}
            withDelete={!(this.props.user_type_id === 0)}
          />
        </CardBody>
      </Card>
    )
  }
}
export default Modules_Card