import React, { Component } from 'react'
import DataTableDeleteAndEdit from 'components/Extensions/Tables/DataTableDeleteAndEdit'
import { Card, CardBody } from "reactstrap"
import translate from 'helpers/translations'

class IO_types_Card extends Component {

  render() {
    let views_io_type = this.props.views_io_type.map(item => {
      item['name'] = item[this.props.item_type + '_name']
      return item
    })
    views_io_type = views_io_type.filter(item => item.view_id === this.props.view_id)
    const view_io_type_ids = views_io_type.map(item => item[this.props.item_type + '_id'])
    let io_types_to_connect = this.props.io_types.filter(item => !view_io_type_ids.includes(item[this.props.item_type + '_id']))
    return (
      <Card>
        <CardBody>
          <DataTableDeleteAndEdit
            title={translate(this.props.item_type + 's')}
            items={views_io_type}
            addItemToState={this.props.addViewIOTypeToState}
            deleteChildItemFromState={this.props.deleteViewIOTypeFromState}
            columns={['Name']}
            columns_DB={['name']}
            select={{'name': {items: io_types_to_connect, col_width: 1, columns: ['name']}}}
            item_type={'views_' + this.props.item_type}
            parent_type={'view'}
            parent_id={this.props.view_id}
            child_type={this.props.item_type}
            withDelete={!(this.props.user_type_id === 0)}
            addTo={true}
            deleteFrom={true}
          />
        </CardBody>
      </Card>
    )
  }
}
export default IO_types_Card