import React, { Component } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

class Devices_Doc extends Component {

  render() {
    return (
      <Card id='devices_doc'>
        <CardHeader>
          <CardTitle tag='h4'>Geräte</CardTitle>
        </CardHeader>
        <CardBody>
          Geräte bilden verschieden Instanzen eines Systems ab.
          Dazu zählen etwa verschiedene Fahrzeuge-Modelle oder Roadside Units. <br /> 
          Die Geräte Seite bietet zum einen eine Tabelle zum Erstellen, Bearbeiten und Löschen von Geräten.
          Ein Klick auf einen Geräte-Namen wählt dieses als aktuelles Gerät aus und es wird nun auch in der Navigationsleiste angezeigt.
          Das aktuell ausgewählte Gerät beeinflusst, welche Komponenten in der Navigationsleiste angezeigt werden und welchem Gerät eine neu
          ersellte Komponente zugeordnet wird.
          Auf der Seite einer
          <a onClick={() => document.getElementById('component_doc').scrollIntoView()}> Komponente</a> ist es jedoch auch möglich Komponenten
          zwischen Geräten zu verschieben oder mehreren Geräten gleichzeitig zuzuordnen. <br />
          Zum anderen bietet die Seite einen graphischen Überblick über das Zusammenspiel zwischen den Geräten.
          Der Graph setzt sich zusammen aus den Graphen einer Sicht aller Geräte.
          Türkis hinterlegt sind die Komponenten, die für mehrere Geräte verwendet werden, weiß hinterlegte Komponenten befinden sich
          lediglich auf einem Gerät.
          Für eine bessere Übersicht ist es möglich die Inputs und Ouputs auszublenden und somit lediglich die Komponenten und ihre
          Verbindungen anzuzeigen.
          Über die Dropdown-Auswahl lässt sich zwischen den verschiedenen Sichten wechseln.
        </CardBody>
      </Card>
    )
  }
}

export default Devices_Doc